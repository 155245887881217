import { ACTIONS } from "../../Constants/Actions";


export const PermitListAction = ( { result, error } ) =>{


    // console.log(result, error)
    return {
        type: (error)? ACTIONS.PERMIT_LIST.PERMIT_LIST_FAILED :ACTIONS.PERMIT_LIST.PERMIT_LIST_SUCCESS,
        payload: { 
            // total: result.total, data:result.data
            result
            , error }
    }
}