import _callApi  from '../../Services/baseService'
import { PLIST } from '../../Constants/ParkingListConst';
import { END_POINT } from '../../Constants/ApiEndPoints';
import ParkingLotsApiResponse from '../actions/ParkingLotsApiResponse';
import FetchingParkList from '../actions/FetchingParkList';
import { ACTIONS } from '../../Constants/Actions';

let headerdata = {
    "Content-Type": "application/x-www-form-urlencoded",
    "Authorization":
      "Basic " +
      btoa(
        "770d6615-87aa-4195-b330-b3cb892a93ef:9IwP7OJZ8yRbMq4PYfI3hk+4EUp7BEbG5whr"
      )
  };


  function getJwtTokenState(state){

    return state.login.details.jwtToken;
  }
  function getLoginState(state){

    return state.login.logging;
  }

export const  ParkingLotsApiCall= ({form})=>{

  
  return (dispatch, getState)=>{
    

    
    // console.log(getState())
    if(!getLoginState(getState())){
      
      let jwtToken = getJwtTokenState(getState())
  
        headerdata["jwtToken"] = jwtToken;

        // form.append("params[authToken]", jwtToken)
        dispatch(FetchingParkList({loading:true,action:ACTIONS.PARKLIST.FETCHING_LIST}))
          _callApi(
            form, 
            END_POINT.PARK_LIST.END_POINT,
            END_POINT.PARK_LIST.POST,
            headerdata
            ).then(res=>{


              
              let { result = null, error=null } = res.data.payload
              
            if(!error){
              
              dispatch(ParkingLotsApiResponse({result, error}))
            }  
            
            else {
              
              dispatch(ParkingLotsApiResponse({result,error}))
            }
            
            dispatch(FetchingParkList({loading:false,action:ACTIONS.PARKLIST.RECEIVED_LIST}))
    
            // if(getFetchingState(getState())){
    
    
            // }  
                    
                  return res
               }, error=>console.error(error))
      }
    }
  }