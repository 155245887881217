import React, { Component } from 'react';
import ReservedPermits from './ReservedPermits';
import AdministratorCard from './Administrator';
import CommonListCard from './ComonListCard';
import { connect } from "react-redux";
import { DashboardReportApi } from '../../store/api-actions/DashboardReportApi';
import { LatestPermitListApi } from '../../store/api-actions/LatestPermitListApi';
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import _ from 'lodash'
import { StripeModal } from './StripeModal';
import axios from 'axios'
import queryString from 'query-string';

function mapDispatchToProps(dispatch){

  return {
      getReport:(form)=>dispatch(DashboardReportApi({form})),
      getLatestReservedPermit:(form)=>dispatch(LatestPermitListApi({form}))
  }
}

function mapStateToProps(state){

    return {
        dashboardReportsuccess:state.dashboardReport.success,
        dashboardReporterror:state.dashboardReport.error,

        latestPermit: {
            lPermitError: state.latestReservedPermits.permitsListError,
            lPermitList: state.latestReservedPermits.permitsList
        }
    }
}

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stripeConnected:false,
            dashboardReportsuccess:{},
            dashboardReporterror:{},
            lPermitError:null,
            lPermitList: [],
// ==============values for stripe account ================//
            accountNumber:'',
            accountNumberError:false,
            email:'',
            bankNumber:'',
            routingNumber:'',

            stripeAccountMessage : {status:true, message:''},
            stripeEmailSuccess : "",
// ======================end=======================//
            promoCodes:{
                data:[],
                total:0,
                pageNo:1,
                totalPages:0,
                error:""
            },
            surgePriceList:{
                data:[],
                total:0,
                pageNo:1,
                totalPages:0,
                error:""
            },
            stripeModal:false
        }
        let code = queryString.parse(this.props.location.search).code;
        if(code){
            let data = new FormData();
            data.append("params[code]", code);
            data.append("params[authToken]", this.getToken());
            let headers = {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
                "Access-Control-Allow-Origin": "*",
                'Accept': 'application/json'
            }
            console.log("CODE FOUND : ", code);
            _callApi(data, END_POINT.ADD_STRIPE.END_POINT, END_POINT.ADD_STRIPE.POST, headers).then(res=>{
                console.log("RESULT : ", res.data.payload.result);
                if(res.data.payload.result){
                    let admin = JSON.parse(localStorage.getItem("adminDetail"));
                    admin.stripeAccountID = 1234;
                    admin = JSON.stringify(admin)
                    localStorage.setItem("adminDetail", admin)
                    this.state.stripeConnected = true;
                    this.props.history.push('main');
                }
            }).catch(function(error){
                console.log("ERROR : ", error);
            })
        }
    }

    stripeConnect = ()=>{
        console.log("stripe Connect", this.state.stripeConnected);
        return (
            this.state.stripeConnected ? <div class="alert alert-success"> Stripe Connected Successfully</div> : ""
        );
    }

    CommonButton = ({ btnText }) => {

        // console.log(btnText)
        return (
            <button>{btnText}</button>
        )
    }

    parentHandle = () => {

        // console.log("parent clicked")
    }
    accountNumberMethod=()=>{


        if(this.state.accountNumber===''){
            console.log("error routing",this.state.accountNumber);
         this.setState({accountNumberError:true}) 

         return 
        }else{
            this.setState({accountNumberError:false})

        }

        let form = new FormData()

          form.append("accountNumber", this.state.accountNumber)

        let baseUrl = "https://ipermit.ns804.com";
        _callApi(form, baseUrl, 1,  )
        .then(res=>{

            let { success, error } = res

            if(error){
                
            }
              
        })
    }
    emailNumber=()=>{

        // console.log("called emailnumber")
        if(this.state.email==='' && this.state.routingNumber===''&& this.state.bankNumber===""){
            this.setState(()=>({emailError:true,routingNumberError:true,bankNumberError:true}))

            return;
           }else{
            this.setState(()=>({routingerror:false,striperror:false,bankaccounterror:false, stripeAccountMessage:{ status:true, message:"" }}))
           }

           let form = new FormData()

          form.append("email", this.state.email)
          form.append("routingNumber", this.state.routingNumber)
          form.append("accountNumber", this.state.bankNumber)

          let admin = JSON.parse(localStorage.getItem("adminDetail"))

          if(admin){

              form.append("name", admin.name)
              form.append("userID", admin.userID)
              form.append("authToken", this.getToken())
              
          }

          var data={
            email:this.state.email,
            routingNumber:this.state.routingNumber,
            accountNumber:this.state.bankNumber,
            name: admin.name,
            userID:admin.userID,
            authToken: this.getToken()
 
        }
        let baseUrl = "https://ipermit.ns804.com";
          fetch(baseUrl+"/createAccount", {
            method: 'POST', // or 'PUT'
            body: "email="+data.email+"&routingNumber="+data.routingNumber+"&accountNumber="+data.accountNumber+"&name="+data.name+"&userID="+data.userID+"&authToken="+data.authToken, // data can be `string` or {object}!
            headers:{
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
              "Access-Control-Allow-Origin": "*",
              'Accept': 'application/json'
            },
            // mode:"no-cors"
          })
          .then(function (result) {
           
     
            return  result.json();
            
     
          }).then((res)=>{
            
            let { message } = res.data
            if(res.status === true){
                
                let {id} = res.data
                
                let admin = JSON.parse( localStorage.getItem("adminDetail"))

                admin.stripeAccountID = id
                
                admin = JSON.stringify(admin)

                localStorage.setItem("adminDetail", admin)

                this.setState((preState)=>{
                    
                    let stripeAccountMessage = preState.stripeAccountMessage
                    
                    stripeAccountMessage.status = true
                    stripeAccountMessage.message = "Successfully connected"

                  return  {stripeAccountMessage : stripeAccountMessage}
                })


            setTimeout(()=>{

                this.toggleStripeModal()
            }, 2000)    


            }else{

                this.setState((preState)=>{
                    
                    let stripeAccountMessage = preState.stripeAccountMessage
                    
                    stripeAccountMessage.status = false
                    stripeAccountMessage.message = message

                  return  {stripeAccountMessage : stripeAccountMessage}
                })
            }
            })
          .catch(error => console.error("Error:", error))
        
        
      }

    componentWillMount(){

        let token = localStorage.getItem("jwtToken");

        let form = new FormData()
        form.append("params[authToken]", token);

        this.props.getReport(form).then(res=>{

            // console.log(res)
        })

        this.props.getLatestReservedPermit(form)

        let  { pathname } = this.props.location;
       
       localStorage.setItem("currentLocation", pathname)


       this.handlePromoPagination({pageNo:1})
       this.handleSurgeListPagination({page:1})
    //    emailNumber, handleChange, accountNumber, email, routingNumber, emailError, accountNumberError, routingNumberError, bankNumberError, bankNumber

    //    this.setState(()=>{

    //      return {
    //          ["emailNumber"] :"",
    //          ["accountNumber"] :"",
    //          ["email"] :"",
    //          ["routingNumber"] :"",
    //          ["emailError"] :"",
    //          ["accountNumberError"] :"",
    //          ["routingNumberError"] :"",
    //          ["bankNumberError"] :"",
    //          ["bankNumber"] :"",
    //      }
    //    })

    }



    componentWillReceiveProps(nextProps){

        let { dashboardReportsuccess, dashboardReporterror }  = nextProps

        let { lPermitError, lPermitList } = nextProps.latestPermit;

        let lPermitArr = []

        if(!lPermitError){

            Object.keys(lPermitList).reverse().map((key,index)=>{
    
                lPermitArr.push(lPermitList[key])
            })
            
        }

        if(!dashboardReporterror){

            // IF DASHBOARD REPORT HAS ANY ERROR FROM BACKEDN
            this.setState({ dashboardReportsuccess: dashboardReportsuccess, dashboardReporterror: dashboardReporterror })

        }else{
            this.setState({ dashboardReportsuccess: dashboardReportsuccess, dashboardReporterror: dashboardReporterror })
        }


        this.setState(()=>{

        //  IF LATEST PERMIT HAS ANY ERROR FROM BACKEND 
          if(lPermitError)
           return { lPermitError: lPermitError.message, lPermitList : lPermitArr }
          else
          return { lPermitError: lPermitError, lPermitList : lPermitArr }
        });

//=========================== //


        // console.log(this.state)
    }

    handlePromoPagination({pageNo}){

        let form = new FormData()

        form.append("params[page]", pageNo)
        form.append("params[authToken]", localStorage.getItem("jwtToken"))

        
            _callApi(form,
                END_POINT.PROMOTION_LIST.END_POINT,
                END_POINT.PROMOTION_LIST.POST,
                {} ).then(res=>{
                    
                let promoCodes = this.state.promoCodes

                   let { result=null, error=null } = res.data.payload

                   if(!error){

                    promoCodes.data = [...[],...promoCodes.data, ...result.data]
                    // promoCodes.data = result.data;
                    promoCodes.total = +result.total

                    promoCodes.totalPages = Math.ceil(+result.total/3)
                    //   console.log(result)
                }
                else{
                    
                    promoCodes.error = error.message
                }
                this.setState({promoCodes:promoCodes})
            })
        
    }


    getToken=()=>{

        return localStorage.getItem("jwtToken")
    }

    handleSurgeListPagination=({page})=>{

        let form = new FormData()

    
        form.append("params[authToken]", this.getToken())
        form.append("params[page]", page)

        _callApi(form , END_POINT.SURGE_LIST.END_POINT, END_POINT.SURGE_LIST.POST, {}).then(res=>{

            let { result=null, error=null } = res.data.payload
            let surgePriceList = this.state.surgePriceList

            if(!error){

                // console.log(result)

                surgePriceList.data = [...[],...surgePriceList.data, ...result.data]
                // promoCodes.data = result.data;
                surgePriceList.total = +result.total

                surgePriceList.totalPages = Math.ceil(+result.total/3)
                
                
            }
            else{
                
                surgePriceList.error = error.message
            }

            this.setState({surgePriceList:surgePriceList})
            // console.log(res)
        })

    }

    loadPromoMore=()=>{

        this.handlePromoPagination({pageNo: ++this.state.promoCodes.pageNo})
    }

    loadSurgeListMore=()=>{

        this.handleSurgeListPagination({page: ++this.state.surgePriceList.pageNo})
    }
    handleChange=(e)=>{

     //   const { value, maxLength } = event.target;
   
      var self=this;   

             let { name, value,maxLength } = e.target;
             const message = value.slice(0, maxLength);
             if(name=='email'){
                if( /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)){

                    self.setState({
                        [name]: value,
                        [name+'Error']:false
                     });
                    

                }else{

                    self.setState({
                        [name]: value,
                        [name+'Error']:true
                     });
                    
                }
             }
             else if( name == "routingNumber"){

                if(/^[0-9]\d*(\d+)?$/i.test(value)){
                    self.setState({
                        [name]: value,
                        [name+'Error']:false
                     });
                }else{
                   
                    self.setState({
                        [name]: value,
                        [name+'Error']:true
                     });
                }
                
            }
            else{
                
                self.setState({
                         [name]: message,
                         [name+'Error']:(message)?false:true
                      });
            }     
           

     
    }

    toggleStripeModal = ()=>{

        // this.setState(()=>({}))
        
         this.setState((preState)=>({
             stripeModal:!preState.stripeModal, 
             stripeAccountMessage:{status:true, message:""}, 
             emailError:false, 
             routingNumberError:false,
             striperror:false,
             bankNumberError:false,
             email:'',
             accountNumber:"",
             routingNumber:"",
             bankNumber:""
            }))
    }



    render() {

        let adminDetail = JSON.parse( localStorage.getItem("adminDetail"))

        let userType = null

        if(adminDetail && _.has(adminDetail, "userType")){

            userType = adminDetail.userType

        }

        // console.log(typeof userType)
        let btn = <button onClick={this.parentHandle} >Click</button>
        return (
            <React.Fragment>
                <div className="dashboard">
                    <div className="container">
                    <center><div>{this.stripeConnect()}</div></center>
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <div className="row">
                                    <div className={`col-md-${ +adminDetail.invitedBy === 3 && adminDetail.stripeAccountID || +adminDetail.invitedBy === 2 || +adminDetail.invitedBy === 0  ? "4":"3"}`}>
                                        <ReservedPermits permitCount={  this.state.dashboardReportsuccess.weekly || +adminDetail.invitedBy === 2 || +adminDetail.invitedBy === 0 } permitDescription="Permits reserved this week" />
                                    </div>
                                    <div className={`col-md-${ +adminDetail.invitedBy === 3 && adminDetail.stripeAccountID || +adminDetail.invitedBy === 2 || +adminDetail.invitedBy === 0 ? "4":"3"}`}>
                                        <ReservedPermits permitCount={  this.state.dashboardReportsuccess.monthly} permitDescription="Permits reserved this month" />
                                    </div>
                                    <div className={`col-md-${ +adminDetail.invitedBy === 3 && adminDetail.stripeAccountID || +adminDetail.invitedBy === 2 || +adminDetail.invitedBy === 0 ? "4":"3"}`}>
                                        <ReservedPermits permitCount={this.state.dashboardReportsuccess.yearly} permitDescription="Permits reserved this year" />
                                    </div>
                                    {
                                    ( +adminDetail.invitedBy === 3 && !adminDetail.stripeAccountID  ) ?
                                    <div className={`col-md-3`}>
                                            <a type="button" class="btn btn-dash" href="https://connect.stripe.com/express/oauth/authorize?client_id=ca_E8NQrZpEvPkplfRExMBpD8hB51BCwsmZ&redirect_uri=" > Connect to Stripe</a>
                                    </div> : null
                                    }
                                </div>
                            </div>



                            {/* <div class="col-md-4 pt-1 pb-4">
                <label class="input-add-new">Stripe Account Number</label>
                <input defaultValue="" name="accountNumber" value={this.state.accountNumber} onChange={(e) => this.handleChange(e)} className="location-search-input form-control" type="text" />
                {this.state.accountNumberError == true ? 'Please enter account number' : ''}
                <br />
                <button type="submit" class="btn" onClick={() => this.accountNumber()} >Save</button>
                <br />or<br />
                <label class="input-add-new">Stripe email</label>
                <input defaultValue="" name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} className="location-search-input form-control" type="email" />
                {this.state.emailError == true ? 'Please enter vaild stripe email' : ''}
                <br />
                <label class="input-add-new">Routing Number</label>
                <input defaultValue="" name="routingNumber" value={this.state.routingNumber} onChange={(e) => this.handleChange(e)} className="location-search-input form-control" type="number" />
                {this.state.routingNumberError == true ? 'Please enter vaild routing number' : ''}
                <br />
                <label class="input-add-new">Bank Account</label>
                <input defaultValue="" name="bankNumber" value={this.state.bankNumber} onChange={(e) => this.handleChange(e)} className="location-search-input form-control" type="number" />
                {this.state.bankNumberError == true ? 'Please enter vaild bank account number' : ''}

                <br />
                <button type="submit" class="btn" onClick={() => this.emailNumber()} >Save</button> */}
                                        <div>
                                               <StripeModal
                                               accountNumber = {this.state.accountNumber} 
                                               email  = {this.state.email} 
                                               routingNumber = {this.state.routingNumber} 
                                               emailError = {this.state.emailError == true ? 'Please enter vaild stripe email' : ''}
                                               accountNumberError  = {this.state.accountNumberError == true ? 'Please enter account number' : ''}
                                               routingNumberError = {this.state.routingNumberError == true ? 'Please enter vaild routing number' : ''}
                                               bankNumberError =  {this.state.bankNumberError == true ? 'Please enter vaild bank account number' : ''}
                                               bankNumber = {this.state.bankNumber}
                                               handleChange = {this.handleChange}
                                               emailNumber = {this.emailNumber}
                                               accountNumberMethod= {this.accountNumberMethod}
                                               toggleStripeModal= {this.toggleStripeModal}
                                               stripeModal= {this.state.stripeModal}
                                               stripeAccountMessage={this.state.stripeAccountMessage}
                                               />
                                               </div>

                                            
                            <div className="col-lg-4 col-md-12">

                            {/* {(+userType === 3)? */}
                            <AdministratorCard />
                            {/* :null */}
                        
                            </div>
                        </div>
                    </div>

                    <div className="container mt-5">

                        <div className="row">
                            <div className="col-md-4">
                                <CommonListCard 
                                type="schedule" 
                                disableButton={(this.state.surgePriceList.pageNo === this.state.surgePriceList.totalPages)} 
                                loadSurgeListMore={this.loadSurgeListMore} 
                                surgePriceList={this.state.surgePriceList.data}  
                                commonFun={this.parentHandle} 
                                error={this.state.surgePriceList.error}
                                heads="Scheduled Surge Pricing" />
                            </div>
                            <div className="col-md-4">
                                <CommonListCard 
                                type="promo" 
                                disableButton={(this.state.promoCodes.pageNo === this.state.promoCodes.totalPages)}  
                                total = {this.state.promoCodes.total} 
                                loadMore={this.loadPromoMore} 
                                error={this.state.promoCodes.error}
                                promoCodes={this.state.promoCodes.data} 
                                heads="Promo Codes" />
                            </div>
                            <div className="col-md-4">
                                <CommonListCard 
                                type="latestPermit"  
                                lPermitList={this.state.lPermitList} 
                                error={this.state.lPermitError}  
                                heads="Latest Permit Reservations" />
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Dashboard = connect(mapStateToProps, mapDispatchToProps)(Dashboard) ;
