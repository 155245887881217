import React from 'react';
import {  Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import warning from '../../../assets/images/warning.png';


         
export const RefundConfirmModal  = ({toggleRequestConfirmModal, requestConfirmModal,requestType, sendRequest, className, requestToRefund})=>{
   

    return (<>
                <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={requestConfirmModal} toggle={()=>{ toggleRequestConfirmModal(requestToRefund, requestType)}} className={className}>
                    
                    <ModalBody>
                        <div className="modal-body-wrapper">
                            <div className="modal-icon text-center">
                                <img src={warning} alt="image is not available" />
                            </div>
                            <div className="modal-message">
                                <div className="text-center pb-2">
                                    Are you sure you want to { (requestType === 1) ? <strong>approve</strong>  : (requestType === 2) ? <strong>reject</strong> :"" } refund request of <br /> <strong>{requestToRefund.name}</strong>  ?
                                   </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "5px" }}>
                            <Row>
                                <Col>
                                    <Button color="primary btn-block delete-exercise-btn"  onClick={ (requestType === 1 || requestType === 2) ?   ()=>sendRequest( {requestType} ) :()=>{} }>Yes</Button>
                                </Col>
                                <Col>
                                    <Button color="secondary btn-block cancel-exercise-btn" onClick={()=>toggleRequestConfirmModal(requestToRefund, requestType)}>Cancel</Button>
                                </Col>
                            </Row>
                        </div>

                    </ModalBody>

                </Modal>
    </>)
}