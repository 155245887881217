import React from 'react'

export const VALIDATE={

    EMAIL_REQ:"Email is required",
    EMAIL_STRIPE_REQ:"Stripe email is required",
    EMAIL_INVALID:"Please enter valid email address",
    EMAIL_MAX:"Email should be of 50 characters maximum",
    PASS_INVALID:"Password is invalid",
    PASS_REQ:"Password is required",
    PASS_MIN:"Password should be of minimum 6 characters long ",
    PASS_MAX:"Password should be of maximum 255 characters long",
    PASS_CONFIRM:"Please confirm the password",
    PASS_MATCH:"Password didn't match",
    PHONE_MIN:"Phone no. should be of minimum 10 digits long",
    PHONE_MAX:"Phone no. should be of maximum 15 digits long",
    PHONE_REQ:"Phone no. is required",
    PHONE_INVALID:"Only numbers are allowed",
    NAME_REQ:"Name is required",
    NAME_INVALID:"Please enter a valid name",
    NAME_MAX:"Name should be of 255 characters maximum",
    INCORRECT:"Please enter correct value",
    STRING_MAX:"You reached maximum characters allowed",
    HOURLY_PRICE:"Hourly price is required",
    DAILY_PRICE:"Daily price is required",
    MONTHLY_PRICE:"Monthly price is required",
    EXPIRY_DATE_REQ:"Expiry date is required",
    PROMO_CODE_REQ:"Promo code is required",
    TITLE_REQ: "title is required"
}