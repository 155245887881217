import React, { Component } from 'react';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Formik} from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import _callApi from '../../Services/baseService';
import { connect } from 'react-redux'
import { LoginApiCall } from "../../store/api-actions/LoginApiCall";
import ReactLoading from 'react-loading';
import { withCookies, Cookies} from 'react-cookie';
import _ from 'lodash';
// import Cookies  from 'js-cookie'
import { APP_CONST } from '../../Constants/AppConstants';
import logo from '../../assets/images/logo.png'
import { VALIDATE } from '../../Constants/ValidationConst';
import Kumulos from '@kumulos/web';
import { END_POINT } from '../../Constants/ApiEndPoints';


function mapPropsToDispatch(dispatch) {

    return {
        login: (form, remember, userType) => dispatch(LoginApiCall(form, remember, userType))
    }
}

function mapPropsToState(state) {

    console.log(state)

    return {
        loggedIn: state.login.logging,
        errors: state.login.errors
    }
}


class AdminLogin extends Component {


    static propTypes = {
        cookies: PropTypes.instanceOf(Cookies).isRequired
      };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            redirect: false,
            admin: {
                email:"",
                password:"",
                remember: false
            }
        }
    }

    handleSubmit(values, formikBeg) {
        let { email, password, remember } = values
// =========================DEFINING USERTYPE OF 1 i.e. AdminLogin
        let userType = 2;
//=================================SETTING FORMIK FORM STATUS=================================//
        formikBeg.setSubmitting(true)
//=================================SETTING FORMIK FORM STATUS END=================================//

        const { cookies } = this.props;


       

        let form = new FormData();
        form.append("params[userType]",userType)
        form.append("params[email]", email)
        form.append("params[password]", password)

        this.props.login(form, remember, userType).then(async (res) => {

            let { error } = res

            if (!error) {

                if(remember){
                    
                    let d = new Date()

                    d.setTime( +d + 24*30*60*60*1000)
                    // console.log(d)
                    cookies.set("email",email, {path: "/ipermit/adminLogin", expires: d})
                    cookies.set("password", password, {path:"/ipermit/adminLogin", expires:d})
                    cookies.set("remember", remember, {path:"/ipermit/adminLogin", expires:d}) 
        
                    // console.log("set cookies ",cookies.get("email"));
                }
               else {
                // const kumulosClient = await new Kumulos({
                //     apiKey: '981ac0ef-3940-4d9c-a812-b5c99a0b1463',
                //     secretKey: 'Qmw0A/bjWJX8Dou5tf2P/8BTRHSiQ2gcdPMV',
                //     vapidPublicKey: 'BIhozs9CuyxgE5QUtuzQh-EaLdEpQhj7FzyA_jtvmk0Gh0iNc-tftOKnmY8eADzmwXISFt9lsKd-nNYfQ_n-_VY',
                //     serviceWorkerPath: process.env.PUBLIC_URL + "/worker.js"
                // });
                //live
                const kumulosClient = new Kumulos({
                    apiKey: '770d6615-87aa-4195-b330-b3cb892a93ef',
                    secretKey: '9IwP7OJZ8yRbMq4PYfI3hk+4EUp7BEbG5whr',
                    vapidPublicKey: 'BAD6ICXvynpnR-gND0qWzPLtNWwMTy5BPDghIzWX8U_5EymGx2kVapg6GuZooB1x6NNYSIngQYtBFXdCutlya_s',
                    serviceWorkerPath: process.env.PUBLIC_URL + "/worker.js"
                });
                kumulosClient.pushRegister();
                let installID = await kumulosClient.getInstallId();
                this.setState({installID:installID});
                let form = new FormData()
                form.append("params[token]", installID)
                form.append("params[user]", res["result"]["userID"])
                form.append("params[deviceType]", 2)
                _callApi(form, END_POINT.ADD_DEVICE_TOKEN.END_POINT, END_POINT.ADD_DEVICE_TOKEN.POST, {}).then(function(){
                    console.log("API CALLED");
                });
                let d = new Date()

                    // cookies.remove
                    cookies.remove("email", {path:"/"})
                    cookies.remove("email", {path:"/ipermit/adminLogin", expires:d.getTime() })
                    cookies.remove("password",{path:"/ipermit/adminLogin" , expires:d.getTime()})
                    cookies.remove("remember", {path:"/ipermit/adminLogin", expires:d.getTime()})
        
                    // console.log("remove cookies",cookies.getAll());
        
                }
                formikBeg.setSubmitting(false)

                this.props.history.push("/main");
            }
            else {

                formikBeg.setSubmitting(false)
            }
        })
    }

    componentWillReceiveProps() {}

    componentDidMount() {

        // let reload = localStorage.getItem("reload")

        // if(reload === "1"){

        //    setTimeout(()=>{

        //        localStorage.setItem("reload", 0)
  
        //        window.location.reload()
        //    }, 500 )
        // }

        localStorage.clear()

        const { cookies } = this.props

       setTimeout(()=>{ let  obj =  cookies.getAll({doNotParse:false})

        // console.log(obj)
        
        if(obj.email && !_.has( obj.email, "path") && obj.password && obj.remember){

            let admin = this.state.admin

            let { email, password, remember } = obj

            admin.email = email
            admin.password = password
            admin.remember = remember

            this.setState((preState)=>{
                return {...preState, admin }
            })
        }}, 1000)

    }

    render() {

        // console.log(this.props.loggedIn)

        // if (this.state.redirect && localStorage.getItem("jwtToken")) {
        //     return <Redirect to="/main" />
        // }

        // if(localStorage.getItem("jwtToken")){

        //     return <Redirect to="/main"/>
        // }
        // console.log(this.props)


        return (

            <React.Fragment>
                <div className="login">
                    <div className="login-wrapper">
                        <Formik
                            initialValues={
                                this.state.admin
                            }
                            // validationSchema={Yup.object().shape({

                            //     email: Yup.string()
                            //         .email(VALIDATE.EMAIL_INVALID),
                            //         // .required(VALIDATE.EMAIL_INVALID),

                            //     password: Yup.string()
                            //         // .required(VALIDATE.PASS_REQ)
                            //         // .max(255, VALIDATE.PASS_MAX)

                            // })}
                            // validateOnBlur={true}
                            onSubmit={this.handleSubmit}
                            render={({ errors, values, handleChange, handleSubmit, handleBlur, isSubmitting, isValid, touched, validateOnBlur }) => (

                                <form onSubmit={handleSubmit} >
                                    <div className="image">
                                        <img src={ logo} />
                                    </div>
                                    <div className="login-form">
                                        {/* 
//===========================================REDUX WILL SEND ERROR MESSAGE FROM BACKEND WITH 'message' PROPERTY IN this.props.errors OBJECT ==============================// 
                                        */}
                                        <span style={{ fontSize: "12px", color: "red" }} >{this.props.errors && this.props.errors.message}</span>

                                        <div className="form-group">
                                            <input onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                name="email"
                                                type="text"
                                                placeholder="Email"
                                                className="form-control"
                                                // validateOnBlur={true}
                                                 />
                                        </div>
                                        <span style={{ fontSize: "12px", color: "red" }} >
                                            { touched.email && errors.email && errors.email}
                                        </span>
                                        <div className="form-group">
                                            <input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                                className="form-control"
                                                // validateOnBlur={true}
                                                 />
                                        </div>
                                        <span style={{ fontSize: "12px", color: "red" }} >{ touched.password && errors.password && errors.password}</span>

                                    </div>

                                    <div className="remember-fogot">
                                        <div className="remember">
                                            <div className="">
                                                <input
                                                name="remember"
                                                type="checkbox"
                                                checked={values.remember}
                                                id="test1" 
                                                onChange={handleChange}
                                                // validateOnBlur={true}
                                                />
                                                <label for="test1"></label>
                                            </div>
                                            <span>Remember me</span>
                                        </div>
                                        <div className="forgot">
                                            <Link to="/forgot-password">
                                                <span>Forgot your password?</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div class="common-save-btn mt-4">
                                        {/* <Link to="/dashboard"> */}

                                        <button disabled={isSubmitting} type="submit" class="btn credential-btn">
                                            {
                                                (isSubmitting) ?
                                                <div className="loder-right">
                                                    <ReactLoading
                                                className="btn-loader"
                                                type="bars"
                                                color="white"
                                                height={"auto"}
                                                width={'10%'} />
                                                </div>
                                                    : "Log In"}  </button>

                                        {/* </Link> */}
                                    </div>
                                </form>

                            )}


                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default AdminLogin = withCookies( withRouter(connect(mapPropsToState, mapPropsToDispatch)(AdminLogin)));
// export default AdminLogin = withRouter(connect(mapPropsToState, mapPropsToDispatch)(AdminLogin));