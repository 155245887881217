import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
 
export default class LocationSearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef()
    this.state = { address: '' };
  }
 
  handleChange = address => {

  //========setting address for current parking lot====//
  this.props.handleChange(this.props.name,address)
  // ==============end======================//
   
    this.setState({ address });
  };
 
  handleSelect = address => {
    this.setState({ address })

//========setting address for current parking lot====//
    this.props.handleChange(this.props.name,address)
    // ==============end======================//
    geocodeByAddress(address)
      .then(results =>getLatLng(results[0]))
      .then(latLng =>{  ; this.props.getLatLng({latLng, address }) })
      .catch(error => console.error('Error', error));
  };
 
  render() {

    // console.log(this.inputRef.target.value)
    return (
      <PlacesAutocomplete
        value={ (this.state.address) ? this.state.address: this.props.location  }
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input form-control',
              })}
              onBlur={(e)=>{
                if(!e.nativeEvent.target.value){
                  this.props.showErrorOnClick(true)
                }else{
                  this.props.showErrorOnClick(false)
                }
                // console.log(e.nativeEvent)
              }}
              // name={this.props.address}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div className="drops-suggestion"
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}