import React, { Component } from 'react';
import { ListRow } from './ListRow';
import { isArray } from 'util';
import ReactLoading from 'react-loading';


export default class DriversTable extends Component {



    constructor(props) {
        super(props)

        this.state = {
            rows: []
        }
    }

    componentWillMount() {

        this.setState({ rows: [] })

    }

    componentWillReceiveProps(props) {

        let rows = this.state.rows

        rows = props.drivers

        this.setState((prevState) => {

            return {
                rows: rows
            }
        })
    }


    loadRows = () => {


        if (isArray(this.state.rows)) {

            return this.state.rows.map((item, index) => {

                return (<ListRow row={item} key={index} toggleEditDriverPopup={this.props.toggleEditDriverPopup} toggleChatModal={this.props.toggleChatModal} toggleDeleteConfirmModal={this.props.toggleDeleteConfirmModal} />)

            })
        }
    }

    render() {

        let userType = localStorage.getItem("userType");


        return (<>

            <div className="table-listing-parking common-padding-left-right">
                <table>
                    <thead>
                        <tr className="head margin-row-zero">
                            { <td>#</td> }
                            <td>Name</td>
                            <td>Phone</td>
                            <td>Email</td>
                            {/* <td>Plate#</td> */}
                            {(userType && +userType === 3) ? <td> Chat  </td> : null}
                            <td>#</td>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            (this.props.loading) ?
                                <tr key={"error"}>
                                    <td colspan="12" className="text-center">

                                        <div className="driver-loder">

                                            <ReactLoading color="blue" type="spinningBubbles" />
                                        </div>
                                    </td>

                                </tr>
                                :
                                this.loadRows()
                        }

                    </tbody>
                </table>
            </div>

        </>)
    }
}