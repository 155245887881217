import React, { Component } from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import _callApi from '../../../Services/baseService';
import { END_POINT } from '../../../Constants/ApiEndPoints';
import ReactLoading from 'react-loading';
import * as moment from 'moment';

class ChatHistory extends Component {
    constructor(props) {
        super(props);

        // this.months = { 0:"January", 1: fa }
        this.state = {
            toUser: '',
            currentChat: {},
            message: null,
            userMessage: [],
            date:0
        }


    }

    componentWillMount() {

    }

    componentWillReceiveProps(nextProps) {


        let { currentUserMessages,  error, first_time_chat } = nextProps


    }

    getChatTime=(time)=>{


        let chat = moment( +time*1000)
        let current = moment()

        // console.log(current.date())
        let chatDate =  chat.get("date")
        let currentDate = current.get("date")

        if(chatDate === currentDate){

            return "Today "+chat.format("h:mm A")
        }else{

            return chat.format("MM-DD-YYYY h:mm A")
        }


        
    }


    getToken() {

        return localStorage.getItem("jwtToken");

    }

    render() {

        // console.log("called chat history render method");

        return (
            // <div ref={this.props.refProp} className="chat-cont" onScroll={(e)=>this.props.handleScroll(e)} >
            <div ref={this.props.refProp} className="chat-cont scrollbar" id="style-4" onScroll={(e) => this.props.handleScroll()}  >
                <h3 style={{ display: "none" }} className="text-center chat-msg pt-4 pb-4"><strong>Chat Messages</strong></h3>

                {(this.props.messageForEndList)? 
                        <div className="chat-tile pb-4"  key={"endOfList"}>
                        <div className="col-md-12 ">
                            <div className="message-list-end">
                                <span> {this.props.messageForEndList}</span>
                            </div>
                        </div>
                    </div> : (this.props.loading) ?  <div className="driver-loder">
                                        <ReactLoading color="blue" type="spinningBubbles" />
                                        </div> :null
                    }
                {
                    (this.props.currentUserMessages.length) ?
                        <div className="section-chat">
                            {this.props.currentUserMessages.map((message, index) => {

                                let current = moment();
                                let currentDate = current.date();
                                let yesDate = currentDate - 1;

                                
                                let chat = moment(+message.timeCreated*1000)
                                
                                let chatDate = chat.date()
                                let chatMonth = chat.format("MMMM")
                                
                                // console.log(currentDate, chatMonth)
                                if (message.sender === this.props.toUser)

                                    return <>
                                    
                                    <div className="chat-tile pb-4" key={message.messageID} id={message.messageID}>
                                        <div className="col-md-8 float-left word-wrap">
                                            <div className="message-left">
                                                <span> {message.message}</span>
                                            </div>
                                            <div class="message-time">
                                                { this.getChatTime( message.timeCreated)}
                                            </div>
                                        </div>
                                    </div>
                                    </>
                                else {
                                    return <div className="chat-tile pb-4" key={message.messageID} id={message.messageID} >
                                        <div className="col-md-8 float-right word-wrap">
                                            <div className="message-right">
                                                <span className="text-color">{message.message}</span>
                                            </div>
                                            <div class="message-time">
                                                { this.getChatTime( message.timeCreated)}
                                            </div>
                                        </div>
                                    </div>
                                }

                            })}
                            <div className="fixed-input-bottom">
                                <div className="row">
                                    <div className="col-md-9">
                                        {this.props.children}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                        

                        // </ReactLoading>
                            // <div  >
                            // <div className="driver-loder">
                            //             <ReactLoading color="blue" type="spinningBubbles" />
                            // </div>
                            // </div>
                        

                }


                <br />

            </div>
        );
    }
}


export default ChatHistory;