import React, { Component } from 'react';
import DriversTable from './views/DriversTable';
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import DriversFilter from './views/DriversFilter';
import ReactPaginate from 'react-paginate';
import {  NewDriverModal } from './views/NewDriverModal';
import { EditDriverModal } from './views/EditDriverModal';
import { DeleteConfirmModal } from './views/DeleteConfirmModal';
import { connect } from 'react-redux';
import { DriverListApi } from '../../store/api-actions/DriverListApi';
import { SendChatModal } from './views/SendChatModal';
import { SetAuthTokenApi } from '../../store/api-actions/SetAuthTokenApi';

// import { Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';


const mapStateToProps=(state)=>{

    return {
        totalDrivers:state.driverList.total,
        listByIds: state.driverList.listByIds,
        error : state.driverList.error
    }
}

const mapDispatchToProps=(dispatch)=>{

    return {
        getDrivers:({form})=>dispatch(DriverListApi({form})),
        setAuthToken:()=>dispatch(SetAuthTokenApi())

    }
}

class Drivers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            filter: {
                searchText: "",
            },
//================================PAGINATION==============================================//
            pagination: {
                totalPage: 0,
                selectedPage: 1,
                pageSize: 0
            },
//==================================END===================================================//

            currentDriver:{
                name:"",
                phone:"",
                email:"",
                userId:""
            },

// =========================DELETE DRIVER===================================//
            driverToDelete:{
                name:""
            },
//==============================END ========================================//


// ====================================ERROR MESSAGES======================================//
            addDriverMessage:{
                error:null,
                success:null
            },
            addExistingDriverMessage:{
                error:null,
                success:null
            },
            editDriverMessage:{
                error:null,
                success:null
            },
            listDriverMessage:{

            },
//==========================================END============================================//

            drivers: [],

//=====================================POP UPS=============================================// 
            newDriverPopup:false,
            editDriverPopup:false,
            deleteConfirmModal: false,
            chatModal:false,

// ====================FOR LOADER ==================================//
            loading:false
// =======================END=======================================//
        
        };
//========================================END==============================================//
    }

    
    componentWillReceiveProps(nextProps){

        let { listByIds, totalDrivers, error } = nextProps
        
        
        // console.log(listByIds, totalDrivers , error);
        this.setState({loading:false})
        
        let totalPage = Math.ceil(+totalDrivers / 10);
        
        let drivers = this.state.drivers
        let pagination = this.state.pagination
        let listDriverMessage = this.state.listDriverMessage
        
        drivers = []
        pagination.totalPage = 0
        listDriverMessage = {}

        if(!error){
            
            Object.keys(listByIds).forEach((key, index)=>{
                
                drivers[index] = listByIds[key]
           })
           
           pagination.totalPage = totalPage
        }
        else{
            
            listDriverMessage = error
        } 
        
        this.setState((preState)=>{

            return {
                drivers :   drivers,
                pagination: pagination,
                listDriverMessage : listDriverMessage
            }
        })


    }
    componentWillMount() {


        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)

        
        let form = new FormData()

        // form.append()
        form.append("params[page]", this.state.pagination.selectedPage)
        form.append("params[searchText]", this.state.filter.searchText.trim())
        form.append("params[authToken]", this.getToken());

        this.applyFilter({ form })

        
    }

    applyFilter = ({ values, formikBag, form }) => {

        this.setState((preState)=>{

            let pagination = preState.pagination

                pagination.selectedPage = 1

           return {loading:true, pagination}
        })

        if (values) {

            let { searchText } = values

            this.setState((preState) => {

                let filter = preState.filter
                

                filter.searchText = searchText
                return {
                    filter: filter,
                   
                }
            })
            // let pageNo = this.state.filter.selectedPage+1
        }

        if (form) {

            this.props.getDrivers({form})
        }

        else {

            let form = new FormData()
            form.append("params[authToken]", this.getToken());
            // form.append("params[locationId]", values.locationId.value)
            form.append("params[searchText]", values.searchText.trim())
            form.append("params[page]", 1)

            this.props.getDrivers({form})
        }
    }

    listDrivers = (form) => {

        _callApi(form, END_POINT.DRIVER_LIST.END_POINT, END_POINT.DRIVER_LIST.POST, {}).then((res) => {

            let { result = null, error = null } = res.data.payload

            if (!error) {

                let { total, data } = result

                let totalPage = Math.ceil(+total / 10);

                this.setState((preState) => {

                    let pagination = preState.pagination

                    pagination.totalPage = totalPage

                    let drivers = preState.drivers

                    drivers = data

                    return {
                        pagination: pagination,
                        drivers: drivers
                    }
                })
            }
        })
    }

    handlePageNo = () => {


        this.setState({loading:true})

        let form = new FormData()

        // form.append()
        form.append("params[page]", this.state.pagination.selectedPage)
        form.append("params[searchText]", this.state.filter.searchText.trim())
        form.append("params[authToken]", this.getToken());

        this.props.getDrivers({form})
    }

    handleNewDriverByEmail=(values, formikBag)=>{

        // console.log(values)
        // this.setState({loading:true})

        let form = new FormData()

        for(let key in values){

            form.append(`params[${key}]`,values[key].trim())
        }

        form.append("params[authToken]", this.getToken());
        
        let { addExistingDriverMessage } = this.state

        _callApi(
            form,
            END_POINT.ADD_EXISTING_DRIVER.END_POINT,
            END_POINT.ADD_EXISTING_DRIVER.POST,{}).then(res=>{

            let { result= null, error= null } = res.data.payload


            if(error == 401){

                this.props.setAuthToken()
            }else if(!error){

                console.log(result)

                addExistingDriverMessage.success = "Successfully Added!"
                addExistingDriverMessage.error = null
                this.setState((preState)=>{

                    return {
                        addExistingDriverMessage
                    }
                })

                let form = new FormData()

                // form.append()
                form.append("params[page]", 1)
                form.append("params[searchText]", this.state.filter.searchText.trim())
                form.append("params[authToken]", this.getToken());

                this.applyFilter({ form })

            }else{
                
                addExistingDriverMessage.error = error.message
                addExistingDriverMessage.success = null

                this.setState({loading:false})


                this.setState((preState)=>{

                    return {
                        addExistingDriverMessage
                    }
                })

                return   
            }
            setTimeout(()=>{
                addExistingDriverMessage.error =null  
                addExistingDriverMessage.success =null
                this.toggleNewDriverPopup()
                
                this.setState((preState)=>{

                    return {
                        addExistingDriverMessage
                    }
                })
            }, 2000)
        }).catch(error=>{

            console.log(error.response)

        })
    }

    handleNewDriver=(values, formikBag)=>{

        // console.log(values)
        this.setState({loading:true})

        let form = new FormData()

        Object.keys(values).forEach((key)=>{
           if(key !== "confirmPass"){

               form.append(`params[${key}]`,values[key])
           } 
        })

        form.append("params[authToken]", this.getToken());
        

        _callApi(form,END_POINT.ADD_DRIVER.END_POINT,END_POINT.ADD_DRIVER.POST,{}).then(res=>{

            let { result= null, error= null } = res.data.payload

            let addDriverMessage = this.state.addDriverMessage

            if(error == 401){

                this.props.setAuthToken()
            }else if(!error){

                console.log(result)

                addDriverMessage.success = "Successfully Added!"
                addDriverMessage.error = null
                this.setState((preState)=>{

                    return {
                        addDriverMessage: addDriverMessage
                    }
                })

                let form = new FormData()

                // form.append()
                form.append("params[page]", 1)
                form.append("params[searchText]", this.state.filter.searchText.trim())
                form.append("params[authToken]", this.getToken());

                this.applyFilter({ form })

            }
            else{
                
                addDriverMessage.error = error.message
                addDriverMessage.success = null

                this.setState({loading:false})


                this.setState((preState)=>{

                    return {
                        addDriverMessage: addDriverMessage
                    }
                })

                return   
            }
            setTimeout(()=>{
                addDriverMessage.error =null  
                addDriverMessage.success =null
                this.toggleNewDriverPopup()
                
                this.setState((preState)=>{

                    return {
                        addDriverMessage: addDriverMessage
                    }
                })
            }, 2000)
        })
    }

    handleEditDriver=(values, formikBag)=>{

        this.setState({loading:true})

        // console.log(values)
        let form = new FormData()

        Object.keys(values).forEach((key)=>{


            form.append(`params[${key}]`, values[key]+"")
        })

        form.append('params[authToken]',this.getToken())

        _callApi(form,
            END_POINT.UPDATE_DRIVER_DETAILS.END_POINT,
            END_POINT.UPDATE_DRIVER_DETAILS.POST,{} ).then(res=>{

                let { result= null, error= null } = res.data.payload

                let editDriverMessage = this.state.editDriverMessage

                if(error == 401){

                    this.props.setAuthToken()

                }else if(!error){
    
                    console.log(result)
    
                    editDriverMessage.success = "Successfully Updated!"
                    editDriverMessage.error = null
                    this.setState((preState)=>{
    
                        return {
                            editDriverMessage: editDriverMessage
                        }
                    })
    
                    let form = new FormData()
    
                    // form.append()
                    form.append("params[page]", 1)
                    // form.append("params[searchText]", this.state.filter.searchText)
                    form.append("params[authToken]", this.getToken());
    
                    this.applyFilter({ form })
    
                }
                else{
                    
                    editDriverMessage.error = error.fileName
                    editDriverMessage.success = null

                    this.setState({loading:false})
    
    
                    this.setState((preState)=>{
    
                        return {
                            editDriverMessage: editDriverMessage
                        }
                    })
    
                    return   
                }
                setTimeout(()=>{
                    editDriverMessage.error =null  
                    editDriverMessage.success =null

                    this.toggleEditDriverPopup(this.state.currentDriver)
                    
                    this.setState((preState)=>{
    
                        return {
                            editDriverMessage: editDriverMessage
                        }
                    })
                }, 2000)
    
            })

    }


    toggleEditDriverPopup = (driver) => {

        // console.log(driver)

        
            this.setState((preState)=>{
    
                return {
                    currentDriver: driver,
                    editDriverPopup: !preState.editDriverPopup
                }
            })
        


    }

    toggleNewDriverPopup = () => {

        let { addDriverMessage, addExistingDriverMessage } = this.state;

        addDriverMessage.success = ""
        addDriverMessage.error = ""

        addExistingDriverMessage.success = false
        addExistingDriverMessage.error = ''

        this.setState(()=>({newDriverPopup: !this.state.newDriverPopup,addExistingDriverMessage, addDriverMessage: addDriverMessage}))
    }

    toggleChatModal = (user) => {

        // console.log(user)
        this.setState({chatModal: !this.state.chatModal})
    }


    toggleDeleteConfirmModal = (driver) => {


        console.log("CONFIRM ==========",this.state.driverToDelete)

        this.setState((preState)=>{

          return  {deleteConfirmModal: !this.state.deleteConfirmModal, driverToDelete: driver }
        })
    }

    handleChat=(values, formikBag)=>{

        let form = new FormData()

        for(let key in values){
            form.append(`params[${key}]`, values[key])
        }
        
        
        form.append("params[authToken]", this.getToken())

        this.sendChat({form})
        //  console.log(values)

        
    }

    sendChat=({form})=>{

        _callApi(form,
            END_POINT.SEND_CHAT.END_POINT,
            END_POINT.SEND_CHAT.POST,
            {}).then(res=>{

                let { error= null, result=null } = res.data.payload

                if(error == 401){

                     this.props.setAuthToken()
                }
                else{
                    
                    result.isDeleted = 0; // THIS IS THE FLAG TO NOTIFY THIS DRIVER WAS NOT DELETED 
                    localStorage.setItem("selected_chat_user", JSON.stringify(result))

                    setTimeout(()=>{

                        this.props.history.push("/main/messaging")
                    })
                }
            })
    }

    deleteDriver=()=>{

        // console.log("ACTION ==========",this.state.driverToDelete)

        this.setState({loading:true})
        let form = new FormData()

        let driverToDelete = this.state.driverToDelete
        Object.keys(driverToDelete).forEach((key)=>{


            form.append(`params[${key}]`, driverToDelete[key]+"")
        })

        form.append("params[authToken]", this.getToken())

        _callApi(form, 
            END_POINT.DELETE_DRIVER.END_POINT,
            END_POINT.DELETE_DRIVER.POST,{}).then(res=>{

                let { result= null, error= null } = res.data.payload

                if(error == 401){

                     this.props.setAuthToken()
                }else if(!error){

                    console.log(result)

                    this.toggleDeleteConfirmModal(driverToDelete);

                    let form = new FormData()
    
                    // form.append()
                    form.append("params[page]", 1)
                    // form.append("params[searchText]", this.state.filter.searchText)
                    form.append("params[authToken]", this.getToken());
    
                    this.applyFilter({ form })
                }
            })

                    
    }

    getToken() {

        return localStorage.getItem("jwtToken");
    }

    render() {
        return (
            <div className="row">

                <DriversFilter toggleNewDriverPopup={this.toggleNewDriverPopup} applyFilter={this.applyFilter} />

                <DriversTable loading={this.state.loading} toggleChatModal={this.toggleChatModal} toggleEditDriverPopup={this.toggleEditDriverPopup} drivers={this.state.drivers} toggleDeleteConfirmModal={this.toggleDeleteConfirmModal} />

                <div style={{margin:"0 auto"}} >
                <ReactPaginate
                    pageCount={this.state.pagination.totalPage}
                    pageRangeDisplayed={this.state.pagination.pageSize}
                    onPageChange={(page) => {
                        let pagination = this.state.pagination

                        pagination.selectedPage = page.selected + 1

                        this.setState({ pagination: pagination })

                        this.handlePageNo()
                    }}
                    forcePage={this.state.pagination.selectedPage - 1}   // USED TO FORCEFULLY SET CURRENT PAGE NO.,
                    //I USED IT IN CASE OF FILTERS WHERE WE WANT TO SYNC PAGES OF AVAILABLE PAGES FOR SELECTED FILTERS
                    containerClassName="pagination-custom" />
                </div>
                    <NewDriverModal
                     handleNewDriver={this.handleNewDriver}
                     addDriverMessage={this.state.addDriverMessage}
                     addExistingDriverMessage={this.state.addExistingDriverMessage}
                     newDriverPopup={this.state.newDriverPopup} 
                     toggleNewDriverPopup={this.toggleNewDriverPopup}
                     loading={this.state.loading}
                     handleNewDriverByEmail={this.handleNewDriverByEmail}
                     />
                     {/* handleEditDriver, editDriverPopup, toggleEditDriverPopup, editDriverMessage, className, values */}

                    <EditDriverModal
                    handleEditDriver={this.handleEditDriver}
                    editDriverPopup={this.state.editDriverPopup}
                    toggleEditDriverPopup={this.toggleEditDriverPopup}
                    driver={this.state.currentDriver}
                    editDriverMessage={this.state.editDriverMessage}
                    />

                    <DeleteConfirmModal
                    toggleDeleteConfirmModal={this.toggleDeleteConfirmModal}
                    deleteConfirmModal={this.state.deleteConfirmModal}
                    deleteDriver={this.deleteDriver}
                    driverToDelete = { this.state.driverToDelete}
                    />
                    <SendChatModal
                    toggleChatModal={this.toggleChatModal}
                    chatModal={this.state.chatModal}
                    // deleteConfirmModal={this.state.deleteConfirmModal}
                    handleChat={this.handleChat}
                    className={""}
                    // driverToDelete = { this.state.driverToDelete}
                    />



                    
            </div>
        )
    }

}

export default Drivers = connect(mapStateToProps, mapDispatchToProps,null, {pure:false})(Drivers);