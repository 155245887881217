import { ACTIONS } from "../../Constants/Actions";





export default function AdminLogging(status){

    
    
    // console.log("=================.>",result)
    return {
        type: ACTIONS.ADMIN_ACTION.ADMIN_LOGGING,
        payload: { status}
    }
}