import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { VALIDATE } from '../../../Constants/ValidationConst';
// import ReactLoading from 'react-loading';


export const NewDriverModal = ({handleNewDriverByEmail, addExistingDriverMessage, handleNewDriver, newDriverPopup, toggleNewDriverPopup, addDriverMessage, className, loading }) => {

    return (
        <>
            <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={newDriverPopup} toggle={toggleNewDriverPopup} className={className}>
                <ModalHeader >Add New Driver</ModalHeader>
                <ModalBody>

                    <div>
                     {/* {
                         (loading) ? 
                                 <ReactLoading color="blue" type="spinningBubbles" /> : null 
                        } */}
                    </div>
                    <Formik
                        key="newPermit"
                        initialValues={{
                            name: "",
                            email: "",
                            password: "",
                            confirmPass: "",
                            phone: ""
                        }}
                        validateOnBlur={true}
                        validationSchema={Yup.object().shape({


                            phone: Yup.string().required(VALIDATE.PHONE_REQ)
                                .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                .min(10, VALIDATE.PHONE_MIN)
                                .max(15, VALIDATE.PHONE_MAX),

                            email: Yup.string().max(50, VALIDATE.EMAIL_MAX)
                                .email(VALIDATE.EMAIL_INVALID)
                                .required(VALIDATE.EMAIL_REQ),

                            password: Yup.string()
                                .required(VALIDATE.PASS_REQ)
                                .min(6, VALIDATE.PASS_MIN)
                                .max(255, VALIDATE.PASS_MAX),

                            confirmPass: Yup.string()
                                .required(VALIDATE.PASS_CONFIRM),

                            name: Yup.string().max(255,VALIDATE.NAME_MAX)
                                .required(VALIDATE.NAME_REQ)
                                .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),
                        })}

                        validate={({ password, confirmPass }) => {

                            let errors = {}

                            if (password !== confirmPass) {
                                errors.confirmPass = "Password doesn't match"
                            }
                            return errors
                        }}
                        onSubmit={handleNewDriver}
                        render={({ values, handleChange, handleBlur, errors, setFieldValue, touched, validateForm }) =>
                            (<Form>

                                <div className="popup-form mng-form">

                                    <span style={{ fontSize: "12px", color: "red" }} >{addDriverMessage.error && addDriverMessage.error}</span>
                                    <span style={{ fontSize: "12px", color: "green" }} >{addDriverMessage.success && addDriverMessage.success}</span>
                                    <div className="row mb-2">
                                        <div className="col-md-3 text-right">
                                            <label>
                                                Name
                                            </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="text" name="name" value={values.name} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.name && errors.name && errors.name}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-3 text-right">
                                            <label>
                                                Phone
                                                </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="text" name="phone" value={values.phone} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.phone && errors.phone && errors.phone}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-3 text-right">
                                            <label >
                                                Email
                                                </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" autoComplete="false" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.email && errors.email && errors.email}</span>
                                        </div>
                                    </div>



                                    <div className="row mb-2">

                                        <div className="col-md-3 text-right">
                                            <label >
                                                Password
                                                    </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="password" name="password" value={values.password} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" autoComplete="false"/>
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.password && errors.password && errors.password}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-3 text-right">
                                            <label>
                                                Confirm Password
                                                </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="password" name="confirmPass" value={values.confirmPass} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" autoComplete="false" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.confirmPass && errors.confirmPass && errors.confirmPass}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-3">

                                        <div className="col-sm-6  col-12">
                                            <div className="float-right">
                                                <button type="button" onClick={toggleNewDriverPopup} class="btn width-btn btn-common">Cancel</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6  col-12">
                                            <div className="">
                                                <button type="submit" class="btn btn-common width-btn">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {JSON.stringify(values)} */}

                                </div>
                            </Form>)}
                    />

                    <div className="mb-5 mt-5 position-relative" style={{borderBottom: '1px dashed #000000'}}>
                    <span style={{position: 'absolute', left: '50%', top: '-12px', transform: 'translateX(-50%)', padding:'0 10px', backgroundColor: '#ffffff' }}>ADD EXISTING DRIVER</span>
                    </div>
                    <Formik
                        key="newPermit1"
                        initialValues={{

                            email: "",
                         
                        }}
                        validateOnBlur={true}
                        validationSchema={Yup.object().shape({


                            email: Yup.string().max(50, VALIDATE.EMAIL_MAX)
                                .email(VALIDATE.EMAIL_INVALID)
                                .required(VALIDATE.EMAIL_REQ),

                        })}

                        onSubmit={handleNewDriverByEmail}
                        render={({ values, handleChange, handleBlur, errors, setFieldValue, touched, validateForm }) =>
                            (<Form>

                                <div className="popup-form mng-form">

                                    <div className="row">
                                       <div className="col-md-3" >

                                       </div>
                                       <div className="col-md-9" >
                                            <span style={{ fontSize: "12px", color: "red" }} >{addExistingDriverMessage.error && addExistingDriverMessage.error}</span>
                                            <span style={{ fontSize: "12px", color: "green" }} >{addExistingDriverMessage.success && addExistingDriverMessage.success}</span>

                                       </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-3 text-right">
                                            <label >
                                                Email
                                                </label>
                                        </div>
                                        <div className="col-md-9" >
                                            <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" autoComplete="false" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.email && errors.email && errors.email}</span>
                                        </div>
                                    </div>

                                    <div className="row mt-3 mb-3">

                                        <div className="col-sm-6  col-12">
                                            <div className="float-right">
                                                <button type="button" onClick={toggleNewDriverPopup} class="btn width-btn btn-common">Cancel</button>
                                            </div>
                                        </div>
                                        <div className="col-sm-6  col-12">
                                            <div className="">
                                                <button type="submit" class="btn btn-common width-btn">Assign</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {JSON.stringify(values)} */}

                                </div>
                            </Form>)}
                    />
                </ModalBody>

            </Modal>
        </>
    )
}