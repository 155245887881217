import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { RefundListActions } from "../actions/RefundListActions";

export const RefundListAPI = ({form})=>{

    return (dispatch, getState)=>{

        return _callApi(form, 
            END_POINT.REFUND_LIST.END_POINT,
            END_POINT.REFUND_LIST.POST ).then(res=>{

                let { result=null, error=null } = res.data.payload;

                // console.log(res)

                dispatch(RefundListActions({ result, error }))


            })
    }
}