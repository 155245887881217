import React, { Component } from 'react';
import PersonCard from './PersonCard';
import profile1 from '../../../assets/images/profile1.jpg'
import profile2 from '../../../assets/images/profile2.jpg'
import profile3 from '../../../assets/images/profile3.jpg'
import profile4 from '../../../assets/images/avatar.png'
const capitalize = (s) => {
    if (s == ' ') return s.trim();
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}
class ChatPersons extends Component {
    constructor(props) {
        super(props);

        this.state = {
            users: []
        }

    }

    onlineOrOffline(member){
        console.log(member.onlineStatus);
        if(member.onlineStatus == "0"){
            return "Offline";
        }else{
            return (
                <div>
                    <div class="dot"></div> Online
                </div>
            );
        }
    }

    componentWillReceiveProps(nextProps) {

        let { users } = nextProps

        let arr = []
        Object.keys(users).map((key, index) => {

            // users[key]["className"] = " "
            arr.push(users[key])
        })

        this.setState(() => {

            return {
                users: arr
            }
        })
    }

   
    render() {


        return (
            <div className="messaging-list">
            
                <ul>
                    
                    { 
                        (this.state.users.length)?
                        this.state.users.map((member, index) => {
                        return <li onClick={()=>{
                             
                            localStorage.setItem("selected_chat_user",JSON.stringify( member));

                            this.props.handleSelectedChat(member)
                            
                            }} >
                                    <div className={`person-card ${member.className}`}>
                                        <div className="person-image">
                                            <img src={profile4} />
                                        </div>
 
                                        <div className="person-status">
                                            <h5>{capitalize(member.name)}</h5>
                                            {/* <p>{ this.onlineOrOffline(member) }</p> */}
                                        </div>
                                    </div>
                                
                            </li>
                        }) :
                        <li>
                            <div className="person-card">
                                <div className="person-status">
                                    <h5>No chat users</h5>

                                </div>
                            </div>
                        </li>
                    }
                   

                </ul>
            </div>
        )
    }
}

export default ChatPersons;