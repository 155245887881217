import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import ReactLoading from 'react-loading';
import * as Yup from 'yup'
import { connect } from 'react-redux';
import _callApi from '../../Services/baseService';
import { withAlert  } from 'react-alert';
import { APP_CONST } from '../../Constants/AppConstants';
import logo from '../../assets/images/logo.png'
import { VALIDATE } from '../../Constants/ValidationConst';

class ForgotPassword extends Component {


    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            // errors: {},
            message: null,
            isError: false
        }
    }

    handleSubmit(values, formikBeg) {
        formikBeg.setSubmitting(true)
        // console.log(values, formikBeg, this.props)
        // setTimeout(()=>formikBeg.setSubmitting(false), 3000)

        // setTimeout(()=>this.props.history.push("/reset-password"),6000)
        this.setState({ message: "" })
        let { email } = values

        let form = new FormData();
        form.append("params[email]", email)

        _callApi(form, "forgotAdminPassword.json", 1, {}).then((res) => {

            let { error, result } = res.data.payload

            // console.log(res);

            if (error) {


                formikBeg.setSubmitting(false)
                this.setState({ message: error.fileName, isError:true })
                // this.setState({ success: "" })

                formikBeg.setSubmitting(false)
                console.log(this.props)
                // this.props.alert.show(this.state.errors.fileName, {
                //     timeout:2000,
                //     type:"error"
                // })

            }
            else {

                this.setState({ message: "Password reset instructions have been sent to your email.", isError: false })

                formikBeg.setSubmitting(false)

                // this.setState({ errors: {} })
                // formikBeg.setSubmitting(false)
                // this.props.alert.show("An email has been to your email", {
                //     timeout:2000,
                //     type:"success"
                // })

            }

        })

    }

    componentWillMount() {

        let { params } = this.props.match
        console.log(this.props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="login">
                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={Yup.object().shape({
                                email: Yup.string()
                                    .email(VALIDATE.EMAIL_INVALID)
                                    .required(VALIDATE.EMAIL_INVALID),
                        })}
                        onSubmit={this.handleSubmit}
                        // validateOnBlur={true}
                        render={({ values, errors, handleSubmit, handleChange, isSubmitting, touched }) => (
                            <form onSubmit={handleSubmit} >
                                <div className="login-wrapper">
                                    <div className="image">
                                        <img src={logo} />
                                    </div>
                                    <div className="login-form">
                                        <div className="forgot-password">
                                            <h2>Forgot Your Password?</h2>
                                            <p>Please enter your email. A link will be sent to your recovery email to reset your password.</p>
                                        </div>
                                        <span style={{ fontSize: "12px", color: this.state.isError? "red": "green" }} >
                                            {this.state.message && this.state.message}
                                        </span>
                                        {/* <span style={{ fontSize: "12px", color: "green" }} >
                                            {this.state.success && this.state.success}
                                        </span> */}
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                placeholder="Email Address"
                                                name="email"
                                               
                                                value={values.email}
                                                onBlur={handleChange}
                                                onChange={handleChange}
                                                className="form-control"
                                                 />
                                            <span style={{ fontSize: "12px", color: "red" }} >{ touched.email && errors.email && errors.email}</span>
                                        </div>
                                    </div>
                                    <div className="common-save-btn mt-4">
                                        {/* <Link to="/reset-password"> */}
                                        <button
                                            disabled={isSubmitting}
                                            type="submit"
                                            className="btn credential-btn">
                                            {
                                                (isSubmitting) ?

                                                
                                                <ReactLoading
                                                className="btn-loader"
                                                type="bars"
                                                color="white"
                                                height={"auto"}
                                                width={'10%'} />
                                                : "Reset Password"
                                            }
                                        </button>
                                        {/* </Link> */}
                                    </div>
                                </div>
                            </form>
                        )}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default ForgotPassword = withAlert(connect()(ForgotPassword));