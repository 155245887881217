import React from 'react'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Collapse,
  Card,
} from "reactstrap";
import images from "../../../assets/images/images.png";


import black_plus from "../../../assets/images/black-plus.png";


export const ParkingListView = ({ toggleSuspendParkingLot, suspendParkingLot , row, toggleEditLots, toggleEvent, toggleReservedPopup, handleReservPSubmit }) => {


  let encodedAdd = encodeURI(row.address)

  // console.log(encodedAdd)

  return (     
  <>
    <tr  className='solid margin-row-zero'>
      <td><Link onClick={() => {

        localStorage.setItem("onEdit", 1);
        localStorage.setItem("currentParkLot", JSON.stringify(row))
      }
        } to="/main/parking-lot-list/edit-parking-lot"><i className="fa fa-edit" ></i></Link></td>
      <td className="hover-handler">{row.company}
        <span className="popupimage">
          <div className="">

          </div>
          <span className="lotimage">
            <img src={(row.imgUrl) ? row.imgUrl : images} />
            <div className="arrow-left">

            </div>
          </span>
        </span>
      </td>
      <td className="addresstd"> <a href={`https://www.google.com/maps/search/?api=1&query=${encodedAdd}`} target="_blank"  >{row.address}</a> </td>
      <td>${(+row.hourlyRate).toFixed(2)}</td>
      <td>${(+row.dailyRate).toFixed(2)}</td>
      <td>{(row.hasMonthly === "0") ? "N/A" : "$" + (+row.monthlyRate).toFixed(2)}</td>
      <td>{ +row.lotCapacity-row.totalReserved +" / " +row.lotCapacity} </td> 
      <td> {(+row.totalReserved === +row.lotCapacity || !row.userID)? null : <span className="reserved" onClick={ () => toggleReservedPopup({ row })}>Reserve</span>}</td>
      <td> {row.contactName?row.contactName:'N/A'}</td>
      <td> {row.phone}</td>
      <td style={{cursor:'pointer', color:"blue"}} onClick={()=>toggleSuspendParkingLot(row.isSuspended, row.locationId)} > { +row.isSuspended===0 ?  "Suspend" : "Unsuspend" }  </td>
      <td><span onClick={() => { toggleEvent(row.locationId) }}>EVENTS <img className="event-icon" src={black_plus} width="20px" /></span></td>

    </tr>
    <tr>
      <td colSpan="11">
      <div className="collapse-margin collps">

        <Collapse isOpen={row.collapse}>
          <Card>


            <table>
            <tbody>
             
              {(Object.keys(row).includes("surgeEvents")) ?

                // <React.Fragment>
                row.surgeEvents.map((item, index) =>

                  (<React.Fragment key={item.surgeEventID}>
                    <tr className="solid-collapse">

                      {(index === 0) ? <td><strong>SURGE DATES</strong></td> : <td></td>}
                      <td>
                        <div>
                        <span>  <span>{  (item.fromDate) ? formatDate(item.fromDate) : null  }
                        </span> &nbsp;-&nbsp; <span>{(item.toDate)? formatDate(item.toDate) : null}</span></span>
                        </div>
                       
                      </td>
                      <td>${(item.hourlyPrice) ? (+item.hourlyPrice).toFixed(2) : null}</td>
                      <td>${(item.dailyPrice) ? (+item.dailyPrice).toFixed(2) : null}</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                   
                    </tr>

                  </React.Fragment>)) : 
                <tr>
                  <td colSpan="11">
                <div  className="border-surge">
                  <strong className="prompt">SURGE DATES</strong>
                  <small class="small-padding">N/A</small>
                </div>
                </td>
                </tr>


              }


              {
                (Object.keys(row).includes("promotions")) ?

                  row.promotions.map((item, index) =>


                    (<React.Fragment key={item.promotionID}>
                      <tr className="solid-collapse1">
                        {(index === 0) ? <td><strong>PROMOTIONS</strong></td> : <td></td>}
                        <td><span> {item.title}</span></td>
                        <td>
                          <span className="text-color code-color">CODE:   </span> {item.promoCode}
                        </td>
                        <td>
                          <span className="text-color code-color" >EXPIRES: {(item.expiredAt)? formatDate(item.expiredAt) : null}</span> 
                        </td>
                        <td colSpan ="6"></td>
                       
                      </tr>
                    </React.Fragment>)) :
                 <tr>
                    <td>
                  <div>
                    <strong className="prompt">PROMOTIONS</strong>
                    <small class="small-padding">N/A</small>
                  </div>
                  </td>  
                </tr>

              }
             

              </tbody>
            </table>



          </Card>
        </Collapse>
      </div>
      </td>
      
      </tr>
      </>
  )
}


export function formatDate(item){

  let date =  new Date(+item*1000)
   let  day = date.getDate()
   let month = date.getMonth()+1
   let year = date.getFullYear()
    date = month+"/"+day+"/"+year

    return date;
}