import React from 'react';
import {  Modal, ModalBody, Row, Col, Button } from 'reactstrap';
import warning from '../../../assets/images/warning.png';

export const DeleteAdminConfirmModal = ({toggleDeleteConfirmModal, deleteConfirmModal, name, deleteAdmin, className, adminToDelete})=>{
   

    return (<>
                <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={deleteConfirmModal} toggle={toggleDeleteConfirmModal} className={className}>
                    
                    <ModalBody>
                        <div className="modal-body-wrapper">
                            <div className="modal-icon text-center">
                                <img src={warning} alt="image is not available" />
                            </div>
                            <div className="modal-message">
                                <div className="text-center pb-2">
                                    Are you sure you want to delete <br /> <strong>{adminToDelete.name}</strong>  ?
                                   </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "5px" }}>
                            <Row>
                                <Col>
                                    <Button color="primary btn-block delete-exercise-btn" onClick={()=>deleteAdmin()}>Yes</Button>
                                </Col>
                                <Col>
                                    <Button color="secondary btn-block cancel-exercise-btn" onClick={()=>toggleDeleteConfirmModal(adminToDelete)}>Cancel</Button>
                                </Col>
                            </Row>
                        </div>

                    </ModalBody>

                </Modal>
    </>)
}