import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';


export const SendChatModal =({chatModal, toggleChatModal, className, handleChat})=> {
  

    let chatUser = localStorage.getItem("selected_chat_user")
    let message = null
    let toId = null
    chatUser = JSON.parse(chatUser)

    if(chatUser){
       message = chatUser.message
       toId = chatUser.userID
    }


    return (
        <>
        <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={chatModal} toggle={toggleChatModal} className={className}>
            <ModalHeader >Send Message</ModalHeader>
            <ModalBody>

                <Formik
                    key="newPermit"
                    initialValues={{
                       
                        message: message,
                        messageType: 0,
                        toId: toId
                    }}

                    enableReinitialize={true}

                    validationSchema={Yup.object().shape({

                        message: Yup.string()
                            .required("Pleas enter some message first").nullable(true)
                    })}

                   
                    onSubmit={handleChat}
                    validateOnBlur={true}
                    render={({ values, handleChange, handleBlur, errors, setFieldValue, touched , submitCount }) =>
                        (<Form>

                            <div className="popup-form">

                                <div className="row mb-2">
                                    {/* <span style={{ fontSize: "12px", color: "red" }} >{editDriverMessage.error && editDriverMessage.error}</span>
                                    <span style={{ fontSize: "12px", color: "green" }} >{editDriverMessage.success && editDriverMessage.success}</span> */}
                                    <div className="col-md-2 text-right">
                                        <label>
                                            Message
                                                </label>
                                    </div>
                                    <div className="col-md-10" >
                                        <input 
                                        type="text" 
                                        name="message" 
                                        value={values.message} 
                                        onBlur={handleBlur} 
                                        onChange={handleChange} 
                                        className="form-control form-width"
                                        ref={ref=> ref && ref.focus()} />
                                        <span style={{ fontSize: "12px", color: "red" }} >{ submitCount > 0 && touched.message && errors.message && errors.message}</span>
                                    </div>
                                </div>

                                


                                <input name="messageType" defaultValue={values.messageType} type="hidden" />
                                <input name="toId" defaultValue={values.toId} type="hidden" />

                                 <div className="row mt-3 mb-3">

                                    <div className="col-sm-6  col-12">
                                        <div className="float-right">
                                            <button type="button" onClick={toggleChatModal} className="btn  btn-common">Cancel</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-6  col-12">
                                        <div className="">
                                            <button type="submit" className="btn btn-common width-btn">Send</button>
                                        </div>
                                    </div>
                                    </div>

                            </div>
                        </Form>)}
                />
            </ModalBody>

        </Modal>
    </>
    )
  
}
