import React from 'react';
import _moment from 'moment';

function LatestPermit({ head, item, index }) {



    let joiningDate = null;
    let expiryDate =null

     if(item.expiredAt){
         
        expiryDate = formatDate(item.expiredAt)
     
    }
    


     if(item.timeCreated){
        

        joiningDate = _moment(item.timeCreated).format('MM/DD/YYYY')

     }
    
   
    return (
        <React.Fragment>
            <ul key= {index} className="latest-permits">
                <li>
                    <div className="list-innew">
                        <p><strong>{item.name},</strong> {item.company}</p>
                        <p> { ( +item.reservedType === 1)? "Hourly" : ( +item.reservedType === 2)? "Daily" : null } , { joiningDate +"-"+ expiryDate }</p>
                    </div>
                </li>
            </ul>
           
        </React.Fragment>
    )

}



export function formatDate(item){

    let date =  new Date(+item*1000)
     let  day = date.getDate()
     let month = date.getMonth()+1
     let year = date.getFullYear()
      date = month+"/"+day+"/"+year

      return date;
}

export default LatestPermit;