import React, { Component } from 'react';
import {  Modal, ModalHeader, ModalBody,  } from 'reactstrap';
import { Formik, Form,  } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { lotAdminInvite } from '../../store/api-actions/lotAdminInvite';
import { lotAdminListApi } from '../../store/api-actions/lotAdminListApi';
import Select from 'react-select'
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import { VALIDATE } from '../../Constants/ValidationConst';
import _ from 'lodash'

function mapDispatchToProps(dispatch) {

    return {
        invite: (form) => dispatch(lotAdminInvite({ form })),
        adminList: (form) => dispatch(lotAdminListApi({ form })),
    }
}


function mapStateToProps(state) {

    return {
        inviteError: state.adminInvitation.invitation.failed,
        inviteSuccess: state.adminInvitation.invitation.success,
        admin: {
            adminArray: state.adminList.adminArray,
            adminTotal: state.adminList.adminTotal,
            adminError: state.adminList.adminError,
        },
    }
}

class AdministratorCard extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,
            inviteError: {},
            inviteSuccess: {},
            admin: {
                adminError: {},
                adminArray: [],
                adminTotal: null
            },
            permitsDropdown: [],
            selectedIds: {},
            dropdownOpen: false
        };
        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleSubmit = (values, formikBeg) => {

        let { email, name, uALots, phone, ccEmail } = values;

        let locationIds = []

        let form = new FormData();

        // console.log(values)

        if (uALots.length) {

            uALots.map((item, index) => {

                locationIds.push(item.value)
            })
        }

        locationIds = JSON.stringify(locationIds)

        form.append("params[email]", email)
        form.append("params[name]", name)
        form.append("params[phone]", phone)
        form.append("params[locationId]", locationIds)
        form.append('params[ccEmail]', ccEmail)

        this.props.invite(form).then((res) => {

            // console.log(res)
            let { error, result } = res.data.payload


            if (!error) {
                this.getUParkingLots()

                let token = localStorage.getItem("jwtToken");

                let form = new FormData()

                form.append("params[authToken]", token);
                form.append("params[page]", 0);

                this.props.adminList(form).then(res => {

                    this.getUParkingLots()
                    this.toggle()

                 })

            }else{

                console.log(error);
            }
        })

    }

    getUParkingLots = () => {

        let token = localStorage.getItem("jwtToken");

        let form = new FormData()

        form.append("params[authToken]", token)

        _callApi(form,
            END_POINT.UNASSIGNED_PARKING_LOTS.END_POINT,
            END_POINT.UNASSIGNED_PARKING_LOTS.POST, {}
        ).then(res => {

            let { result, error } = res.data.payload

            if (!error) {


                let arr = this.state.permitsDropdown;

                let obj = {}

                result.map((item, index) => {



                    obj.value = item.locationId;
                    obj.label = item.company
                    
                    arr.push(obj)

                    obj={}

                })

                this.setState(()=>({ permitsDropdown: arr }))

            }

        })
    }


    handlePermitSelect = (method, value) => {

        method("uALots", value)
    }

    componentWillUnmount(){

         this.setState(()=>({permitsDropdown:[]}))
    }

    componentWillMount() {

        let token = localStorage.getItem("jwtToken");

        let form = new FormData()

        form.append("params[authToken]", token);
        form.append("params[page]", 0);

        this.props.adminList(form).then(res => { })

        this.getUParkingLots()
    }

    componentWillReceiveProps(nextProps) {

        // console.log(nextProps)

        let { inviteError, inviteSuccess } = nextProps

        if (inviteError) {

            let { inviteError, inviteSuccess } = nextProps
            this.setState({ inviteError: inviteError, inviteSuccess: {} })
        }
        else if (inviteSuccess) {

            this.setState({ inviteSuccess: inviteSuccess, inviteError: {} });

            setTimeout(() => { this.toggle() }, 3000)
        }

        if (nextProps.admin.adminArray.length) {


            let { adminArray, adminTotal, adminError } = nextProps.admin

            let admin = this.state.admin

            admin.adminArray = adminArray;
            admin.adminTotal = adminTotal;
            admin.adminError = adminError;

            this.setState({ admin: admin })

        }
        else if (nextProps.admin.adminError) {

            let { adminArray, adminTotal, adminError } = nextProps.admin

            let admin = this.state.admin

            admin.adminArray = adminArray;
            admin.adminTotal = adminTotal;
            admin.adminError = adminError;

            this.setState({ admin: admin })
        }

    }

    listAdmin = () => {

        return this.state.admin.adminArray.map((item, index) => {

            return <li key={index} >{item.name}</li>
        })
    }

    toggleDropdown = () => {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {

        let adminDetail = JSON.parse( localStorage.getItem("adminDetail"))

        let userType = null

        if(adminDetail && _.has(adminDetail, "userType")){

            userType = adminDetail.userType

        }
        return (
            <React.Fragment>
                <div className="common-card">
                    <div className="card-header">
                        <ul>
                            <li>
                                Administrator
                        </li>
                            <li>
                                {
                                     <a onClick={this.toggle}>
                                                Add New
                                    </a>
                                }
                            </li>
                        </ul>
                    </div>
                    <div className="card-body" title="Scroll More Content">
                        <ul id="scr">
                            { this.props.admin.adminError ?   this.props.admin.adminError :  this.listAdmin()}

                            { (+userType === 3 && this.state.admin.adminArray.length)? <li key={this.state.admin.adminArray.length+1} > <Link to="/main/admins" className="btn btn-common dashboard-btn-margin" style={{position:"static"}} >See all admins</Link> </li> : null}
                        </ul>
                    </div>
                </div>
                
                <div className="add-new-admin-modal">
                    <Modal centered={true} isOpen={this.state.modal} style={{ maxWidth: "400px" }} toggle={this.toggle} className={this.props.className} >
                        <ModalHeader >Invite New Admin</ModalHeader>
                        <ModalBody>
                            <Formik

                                initialValues={
                                    { email: "", name: "", phone:"", uALots: [] }
                                }
                                validationSchema={Yup.object().shape({

                                    email: Yup.string()
                                        .email(VALIDATE.EMAIL_INVALID)
                                        .required(VALIDATE.EMAIL_REQ),

                                    name: Yup.string()
                                        .required(VALIDATE.NAME_REQ)
                                        .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),

                                    phone: Yup.string()
                                            .required(VALIDATE.PHONE_REQ)
                                            .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                            .min(10, VALIDATE.PHONE_MIN)
                                            .max(15, VALIDATE.PHONE_MAX),
                                    uALots: Yup.array().required("Please select a unassigned location ")
                                        // .min(3, 'Pick at least 3 tags')
                                        .of(
                                            Yup.object().shape({
                                                label: Yup.string().required(),
                                                value: Yup.string().required(),
                                            })
                                        ),
                                    ccEmail:Yup.string()
                                    .email(VALIDATE.EMAIL_INVALID)
                                })


                                }
                                onSubmit={this.handleSubmit}
                                render={
                                    ({ values, handleBlur, handleChange, handleSubmit, errors, setFieldValue, touched }) => (

                                        <Form>
                                            <div className="admin-modal">

                                                <div className="admin-add-popup">
                                                    <div className="admin-add-popup">
                                                        <span style={{ fontSize: "12px", color: "red" }} >
                                                            {this.state.inviteError && this.state.inviteError.fileName}
                                                        </span>
                                                        <span style={{ fontSize: "12px", color: "green" }} >
                                                            {this.state.inviteSuccess && this.state.inviteSuccess.message}
                                                        </span>
                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Name
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    value={values.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Name"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.name && errors.name && errors.name}
                                                                </span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Email
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.email && errors.email && errors.email}
                                                                </span>
                                                            </div>

                                                            <div className="clearfix"></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                  CC  Email
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="ccEmail"
                                                                    value={values.ccEmail}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="CC  Email"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.ccEmail && errors.ccEmail && errors.ccEmail}
                                                                </span>
                                                            </div>

                                                            <div className="clearfix"></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Phone No.
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="phone"
                                                                    value={values.phone}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Enter mobile no."
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.phone && errors.phone && errors.phone}
                                                                </span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                       
                                                        <div style={{ margin: '1rem 0' }} className="admin-select-lot" >
                                                            <label htmlFor="color">Select Unassigned Parking Lots </label>
                                                            <Select
                                                                id="topics"
                                                                // multi={true}
                                                                options={this.state.permitsDropdown}
                                                                // options={options}
                                                                onChange={(value) => this.handlePermitSelect(setFieldValue, value)}
                                                                onBlur={handleBlur}
                                                                value={values.uALots}
                                                                isMulti={true}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: "12px", color: "red" }} >
                                                            { touched.uALots && errors.uALots && errors.uALots}
                                                        </span>

                                                    </div>
                                                    <div className="btns-admin-add">
                                                        <button type="submit" className="btn btn-admin">Send Invite</button>
                                                        <button type="button" onClick={this.toggle} className="btn btn-admin">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {JSON.stringify(values)} */}
                                        </Form>)}
                            />
                        </ModalBody>
                    </Modal>
                </div>
            </React.Fragment>
        )
    }
}

export default AdministratorCard = connect(mapStateToProps, mapDispatchToProps)(AdministratorCard);