import  React from 'react';


export const SearchBar = ({name, handleChange, handleBlur, type, value, className, touched, errors, setFieldValue})=>{

    return (
        <>
        <input 
        value={value} 
        type={type} 
        className={className}
        name={name} 
        onChange={(e)=>handleChange(setFieldValue, name,e.target.value)}
        placeholder="Search" 
        onBlur={handleBlur}/>
        {/* <span style={{ fontSize: "12px", color: "red" }} >
        { touched[name] && errors[name] && errors[name]}
        </span> */}

        </>
    )
}

