import React, { Component } from 'react';
import LatestPermit from './dashboardComponents/latestPermit';
import Schedule from './dashboardComponents/schedule';
import Promo from './dashboardComponents/promo';
import { withRouter } from 'react-router-dom';


class CommonListCard extends Component {
    constructor(props) {
        super(props)

        this.button = null
    }

    componentDidMount() {


    }

    redirectToAllPermits=()=>{

       if(this.props.type === "latestPermit"){
           
           this.props.history.replace("/main/permits/guests")
       } 
    }

    renderCardButton=()=>{
        switch(this.props.type){

            case "promo":{

            //   return  this.button =<button disabled={this.props.disableButton} onClick={()=>{  this.props.loadMore()}} className="btn btn-common dashboard-btn-margin">Load More</button>
              break;
            }
            case "latestPermit":{

             return   this.button =  <button onClick={this.redirectToAllPermits} className="btn btn-common dashboard-btn-margin">See All Permits</button>

            }
            case "schedule":{

              break;

                // return   this.button =  <button disabled={this.props.disableButton} onClick={()=>this.props.loadSurgeListMore()} className="btn btn-common dashboard-btn-margin">Load More</button>            
            }
        }
    }
    render() {

        let cards = []

        switch (this.props.type) {

            case "promo":
                // cards = Promo({ head: this.props.heads, commonFun: this.props.commonFun })
                // console.log(this.props.promoCodes)
                if(this.props.promoCodes){
                    cards =  this.props.promoCodes.map((item, index)=>{
                        
                        // console.log(item)
                        return <Promo  head={this.props.heads} index = {index} item={item} />
                     })
                 
                    }
                    break;

            case "schedule":

                    // cards = Schedule({ head: this.props.heads })

                if(this.props.surgePriceList.length){

                    // console.log(this.props.surgePriceList)

                    cards =  this.props.surgePriceList.map((item, index)=>{
                        
                        // alert(item)
                        // console.log(item)
                        return <Schedule  head={this.props.heads} index = {index} item={item} />
                     })
                }

                break;
            case "latestPermit":

                        if(!this.props.lPermitError){

                            // console.log(this.props.lPermitList)
                        cards =  this.props.lPermitList.map((item, index)=>{
                    

                               return <LatestPermit head={this.props.heads} index = {index} item={item} />
                            })
                        }
                break;
        }

        return (
            <div className="listing-card">
                <div className="card-header">
                    <ul>
                        <li>
                            {this.props.heads}
                        </li>

                    </ul>
                </div>
                <div className="common-body" >


                     {(this.props.error)? this.props.error: 
                        <>
                        {cards}
                       {this.renderCardButton()}
                        </>
                    }

                </div>
            </div>
        )
    }
}

export default CommonListCard = withRouter(CommonListCard);