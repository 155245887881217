
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, InputGroup, Input } from 'reactstrap';


export default class AdminSuspendModal extends Component {

    constructor(props) {
        super(props)
    }




    render() {
        
        const { status } = this.props

        return <>
            <div>

                <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.props.isOpen} toggle={()=>this.props.toggleSuspendConfirmModal()} className={this.props.className}>
                    <ModalHeader > { +status === 0 ? "Suspend" : 'Unsuspend' }  Lot Admin</ModalHeader>
                    <ModalBody>
                       
                    <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "5px" }}>
                            <Row>
                                <Col>
                                    <Button color="primary btn-block delete-exercise-btn" onClick={this.props.suspendAdmin}>Yes</Button>
                                </Col>
                                <Col>
                                    <Button color="secondary btn-block cancel-exercise-btn" onClick={()=>this.props.toggleSuspendConfirmModal()}>Cancel</Button>
                                </Col>
                            </Row>
                        </div>

                    </ModalBody>

                </Modal>
            </div>

        </>
    }
}