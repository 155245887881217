import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { LotAdminListAction } from "../actions/LotAdminListAction";


export const lotAdminListApi = ({form} )=>{


    return (dispatch, getState )=>{

        return _callApi(
            form,
            END_POINT.LOT_ADMIN_LIST.END_POINT, 
            END_POINT.LOT_ADMIN_LIST.POST,
            {}
            ).then(res=>{

                let { result=null , error=null } = res.data.payload;
                // console.log(res)

                // if(!error)
                dispatch(LotAdminListAction({result, error}))

                return res
            })

    }
}