import React, { Component } from 'react';
import { SearchBar } from './SearchBar';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { AdminParkingDropdown } from './AdminParkingDropdown';

export default class AdminFilter extends Component {

    constructor(props) {
        super(props)
        this.btnRef = React.createRef()
        this.state = {

            parkingOptions: []

        }

    }

    componentWillReceiveProps(props) {

        this.setState((preState, preProps) => {

            return { parkingOptions: props.parkingOptions }

        })
    }

    handleChange = (setFieldValue, name, value) => {

        try {

            setFieldValue(name, value);
        }
        catch (error) {

            console.log(error)
        }

        this.btnRef.click()
        // console.log(name, value)
    }

    render() {

        return (<>
            <div className="header-panel1 common-padding-left-right">

                <Formik
                    key="newPermit"
                    initialValues={{
                        locationId: { value: "", label: "Show All" },
                        searchText: ''
                    }}

                    onSubmit={(values, formikBag) => this.props.applyFilter({ values, formikBag })}
                    render={({ handleChange, handleBlur, errors, values, touched, setFieldValue }) => (
                        <Form>
                            <div className="row">
                                <div className="col-md-9">
                                    <div className="search-box w-100 ">
                                        <SearchBar
                                            handleChange={this.handleChange}
                                            handleBlur={handleBlur}
                                            setFieldValue={setFieldValue}
                                            type="text"
                                            touched={touched}
                                            errors={errors}
                                            value={values.searchText}
                                            className="form-control search-field"
                                            name="searchText"
                                            errors={errors} />
                                        <i className="fa fa-search search-icon" />
                                    </div>
                                </div>
                                <div className="col-md-3">

                                    <div className="btn-csv">
                                        <ul>
                                            <li className="dropdown-list filters-dropdown">
                                                <AdminParkingDropdown

                                                    handleChange={this.handleChange}
                                                    options={this.state.parkingOptions}
                                                    setFieldValue={setFieldValue}
                                                    value={values.locationId}
                                                    name="locationId"
                                                    handleBlur={handleBlur}
                                                />
                                            </li>
                                        </ul>
                                    </div>
                                </div>


                                <button type="submit" style={{ display: "none" }} ref={(node) => { this.btnRef = node; }}  ></button>

                            </div>
                        </Form>

                    )}
                />
                <div className="col-md-6">
                    <div className="btn-csv float-right">
                        <button className="btn btn-common" onClick={this.props.toggleNewAdminModal}>
                            Add New
                                     </button>
                    </div>
                </div>
            </div>
        </>)
    }
}