import React from 'react';
import {  Modal, ModalBody, Form, Row, Col, Button } from 'reactstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

// import warning from '../../../assets/images/warning.png';


         
export const PassRefundConfirmModal  = ({togglePassReqModal, sendPassRefundRequest,passRefundResponse, className,passRefundModal, requestToRefund})=>{
   

    return (<>
                <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={passRefundModal} toggle={()=>{ togglePassReqModal(requestToRefund)}} className={className}>
                    
                    <ModalBody>
                    <Formik
                    key="newPermit"
                    initialValues={{
                       
                        password: "",
                    }}

                    enableReinitialize={true}

                    validationSchema={Yup.object().shape({

                        password: Yup.string()
                            .required("Please enter your password first").nullable(true)
                    })}

                   
                    onSubmit={sendPassRefundRequest}
                    validateOnBlur={true}
                    render={({ values, handleSubmit, handleChange, handleBlur, errors, setFieldValue, touched , submitCount }) =>
                        (<Form onSubmit={handleSubmit} >

                            <div className="popup-form">

                                <div className="row" >
                                          <div className="col-md-6">
                                            <span style={{ fontSize: "12px", color:  passRefundResponse['isSuccess']  ? "green" : 'red' }} >{passRefundResponse['msg']}</span>

                                          </div>

                                </div>
                                <div className="row mb-2">
                                    {/* <span style={{ fontSize: "12px", color: "red" }} >{editDriverMessage.error && editDriverMessage.error}</span> */}
                                    
                                    <div className="col-md-4 text-left">
                                        <label>
                                            Password
                                        </label>
                                    </div>
                                    <div className="col-md-8" >
                                        <input 
                                        placeholder="Please enter your password"
                                        type="password" 
                                        name="password" 
                                        value={values.password} 
                                        onBlur={handleBlur} 
                                        onChange={handleChange} 
                                        className="form-control form-width"
                                        ref={ref=> ref && ref.focus()} />
                                        <span style={{ fontSize: "12px", color: "red" }} >{ submitCount > 0 && touched.password &&  errors.password}</span>
                                    </div>
                                </div>

                                

                                 <div className="row mt-3 mb-3 mr-1">

                                    <div className="col-sm-4 col-4">

                                    </div>
                                    <div className="col-sm-4 col-4">
                                        <div className="float-right">
                                            <button type="button" onClick={()=>{ togglePassReqModal(requestToRefund)}} className="btn  btn-common">Cancel</button>
                                        </div>
                                    </div>
                                    <div className="col-sm-4">
                                        <div className="">
                                            <button type="submit" className="btn btn-common width-btn">Send</button>
                                        </div>
                                    </div>
                                    </div>

                            </div>
                        </Form>)}
                    />
                    </ModalBody>

                </Modal>
    </>)
}