import axios from 'axios'
import { END_POINT } from '../Constants/ApiEndPoints';


function getJwtToken(){

    return localStorage.getItem("jwtToken");
}


export default function _callApi(data, url, method, headerData){


        let header = {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization":
            "Basic " +
            btoa(
                `${END_POINT.KUMOLOUS.API_KEY}:${END_POINT.KUMOLOUS.SECRET_KEY}`
            ),
            // "authToken": getJwtToken()
        };
        let  instance = axios.create({
                                baseURL:`https://api.kumulos.com/b2.2/${END_POINT.KUMOLOUS.API_KEY}/`,
                                headers:"Basic " +btoa(  `${END_POINT.KUMOLOUS.API_KEY}:${END_POINT.KUMOLOUS.SECRET_KEY}`)
                            });

    let defaultSetting = { API_URL : `https://api.kumulos.com/b2.2/${END_POINT.KUMOLOUS.API_KEY}/` };

    let result ;
// =========================================METHOD 1 = POST, METHOD 2 = GET=================================================================================//
    if(method === 1){

      result =  instance.post(defaultSetting.API_URL+url, data,{headers: header})
        .then(res=>{

            return res
        })
    }

    return result;
}
