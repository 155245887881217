import Select from 'react-select';
import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Row, Col, Button, InputGroup, Input } from 'reactstrap';
import { connect } from 'react-redux'
import { PermitListApi } from '../../../store/api-actions/PermitListApi';
import { GuestList } from './guests-views/GuestList';
import ReactPaginate from 'react-paginate';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { GetSiglePermit } from '../../../store/actions/GetSiglePermit';
import _callApi from '../../../Services/baseService';
import { END_POINT } from '../../../Constants/ApiEndPoints';
import EditPermitModal from './guests-views/EditPermitModal';
import { APP_CONST } from '../../../Constants/AppConstants';
import warning from "../../../assets/images/warning.png";
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { VALIDATE } from '../../../Constants/ValidationConst';
import ReactLoading from 'react-loading';
import { SetAuthTokenApi } from '../../../store/api-actions/SetAuthTokenApi';
import CSVReader from 'react-csv-reader'
import  DateTimePicker from "react-datetime-picker";
import DatePicker from "react-datepicker";

require('react-datepicker/dist/react-datepicker.css');

function mapStateToProps(state) {

    return {
        permitList: state.permitsList.listByIds,
        total: state.permitsList.total,
        selectedPermit: state.permitList && state.permitList.selectedPermit | {},
        listError: state.permitsList.error,
        loading: state.permitsList.isFetching
    }
}

const permitTypeOptions = [
    { value: "", label: "Show All" },
    { value: "1", label: "Hourly" },
    { value: "2", label: "Daily" },
    { value: "3", label: "Monthly" },
    // { value: "4", label: "Yearly" },
    // { value: "4", label: "Yearly" }
]
function mapDispatchToProps(dispatch) {

    return {
        getPermitList: (form) => dispatch(PermitListApi({ form })),
        getSPermit: (id) => dispatch(GetSiglePermit({ id })),
        setAuthToken: () => dispatch(SetAuthTokenApi())
    }

}


class Guests extends Component {
    constructor(props) {
        super(props);
        this.searchInput = React.createRef();

        this.importCsv = React.createRef();
        this.state = {

            expDate: new Date(),

            collapse: false,

            shouldUpdate: 1,
            pages: [],

            // activePage: 1,
            //=====FOR PAGINATION===//
            pageSize: 0,
            totalPage: 0,
            //========END==========//

            //=======FOR EDITING PERMIT====// 

            selectedPermit: {},

            //========FOR DROPDOWN VALUES======//
            editPermit: {
                locationId: { value: "", label: "" },
                permitType: { value: "", label: "" }
            },
            //============END=============// 
            startDate: new Date(),
            selectedOption: null,

            guestPermit: {
                list: [],
                error: {},
                total: 0
            },

            parkingList: {
                options: []
            },

            currentPLOptions: {
                options: []
            },
            // =================ERROR MESSAGES=========================//
            newPermit: {
                error: "",
                success: ""
            },
            // =====================END================================//

            current: {
                permitType: "",
                locationId: "",
                pageNo: 1,// FORCEFULLY SET PAGINATION TO 1
                searchText: ""
            },

            modal: false,
            modalEdit: false,
            modalReserved: false,
            suspendModal: false,
            confirmModal: false,

            // ==========================FOR LIST LOADER==========================//
            loading: false,
            csvUploadMsg: {
                error: false,
                msg: ""
            },
            csvConfirmModal: false
            // =============================END==============================//
        };

        this.toggleEvent = this.toggleEvent.bind(this);
        this.toggleEditPermit = this.toggleEditPermit.bind(this);
        this.toggleEditLots = this.toggleEditLots.bind(this);
        this.toggleReservedPopup = this.toggleReservedPopup.bind(this);
    }

    onChange = expDate => this.setState({ expDate: expDate })

    componentDidMount() {

        let token = this.getToken()

        let form = new FormData()

        form.append("params[authToken]", token);
        form.append("params[page]", 1);
        form.append("params[isGuest]", 1);

        this.props.getPermitList(form)

        // console.log(this.props)

        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)

        this.getParkingListOptions()
    }

    //==================================================GETTING PARKING LIST OPTIONS===========================================================================================//
    getParkingListOptions() {

        let parkingListForm = new FormData()

        // parkingListForm.append("params[page]", 0)
        parkingListForm.append("params[authToken]", this.getToken())

        _callApi(parkingListForm,
            END_POINT.ALL_PARKING_LIST.END_POINT,
            END_POINT.PARK_LIST.POST,
            {}).then(res => {

                let { result = null, error = null } = res.data.payload


                let parkingList = this.state.parkingList

                if (!error) {

                    // console.log(result)

                    parkingList.options = result.map((item, index) => {

                        return { value: item.locationId, label: item.company }
                    })

                    parkingList.options.splice(0, 0, { value: "", label: "Show All" })



                    this.setState((preState) => {

                        return { ...preState, ...parkingList }
                    })


                }
                else {


                }
            })
    }
    // ========================================================END=============================================================================================================//

    loadPermitList = () => {

        return this.state.guestPermit.list.map((item, index) => {

            return <GuestList item={item} key={item.reserveLocationID} index={index} toggleEditPermit={(id) => this.toggleEditPermit({ id, item })} />
        })
    }

    componentWillReceiveProps(nextProps) {


        let { total, permitList, loading, listError } = nextProps

        let arr = []
        let guestPermit = this.state.guestPermit;

        if (permitList) {

            Object.keys(permitList).map((key, index) => {

                arr[index] = permitList[key]
            })
        }


        if (!listError) {

            guestPermit.list = arr.reverse();
            guestPermit.total = total;
        } else {

            guestPermit.list = [];
            guestPermit.total = null;
        }



        let totalPage = Math.ceil(total / 10);



        this.setState({ guestPermit: guestPermit, totalPage: totalPage, loading: loading })
    }

    handlePageNo = () => {


        let current = this.state.current

        let token = this.getToken()

        let form = new FormData()

        form.append("params[authToken]", token)
        form.append("params[page]", current.pageNo);
        form.append("params[locationId]", current.locationId);

        if (current.searchText && current.searchText.trim()) {

            form.append("params[searchText]", current.searchText.trim());
        } else {
            form.append("params[searchText]", "");

        }
        form.append("params[reservedType]", current.permitType);
        form.append("params[isGuest]", 1);

        this.props.getPermitList(form).then(res => {

            // this.setState({shouldUpdate:Math.random()})
        })
        // this.setState({activePage:pageNumber})
    }



    handleDateChange = (setFieldValue, value, fieldName) => {

        // console.log(value)

        setFieldValue(fieldName, value)
        //  this.setState({startDate: e})
    }


    shouldComponentUpdate(nextProps, nextState) {

        // console.log(nextProps, nextState)

        return true;
    }

    // SETTING STATE FOR EDITING A PERMIT FROM A LIST OF PERMITS 
    toggleEditPermit({ id, item }) {

        // console.log(id, item)

        let editPermit = this.state.editPermit

        let reservedType = ''

        if (item) {

            switch (item.reservedType) {

                case "1": reservedType = "Hourly"
                    break;
                case "2": reservedType = "Daily"
                    break;
                case '3': reservedType = "Monthly"
                    break;
            }

            editPermit.locationId = { value: item.locationId, label: item.company }
            editPermit.permitType = { value: item.reservedType, label: reservedType }

            this.setState({ selectedPermit: item, editPermit: editPermit })

        }

        // let toggleEditPermit = this.state.selectedParking
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleEditLots() {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }

    toggleReservedPopup() {
        this.setState((preState) => {

            let newPermit = preState;

            newPermit.success = ""
            newPermit.error = ""

            return { modalReserved: !preState.modalReserved, newPermit }
        });
    }

    // collapse events
    toggleEvent() {
        this.setState({ collapse: !this.state.collapse });
    }

    // 
    handleOptionChange = (setFieldValue, item, name) => {

        setFieldValue(name, item)

    }

    handleNewPSubmit = (values, formikBeg) => {


        let { locationId, licenseNumber, model, make, phone, email } = values;
        // let { expDate } = this.state;
        values['expDate'] = this.state.expDate;
        // console.log(this.state.expDate);
        let exp = values.expDate;
        let current_timestamp = new Date()

        var difference = exp.getTime() - current_timestamp.getTime();

        var daysDifference = Math.floor(difference / 1000 / 60 / 60 / 24);
        difference -= daysDifference * 1000 * 60 * 60 * 24

        var hoursDifference = Math.floor(difference / 1000 / 60 / 60);
        difference -= hoursDifference * 1000 * 60 * 60

        var minutesDifference = Math.floor(difference / 1000 / 60);
        difference -= minutesDifference * 1000 * 60

        var secondsDifference = Math.floor(difference / 1000);

        // console.log(secondsDifference);
        // console.log(minutesDifference);
        // console.log(hoursDifference);
        // console.log(daysDifference);
        if (daysDifference > 29) {
            values["reservedType"] = "3";
            values["reservedVal"] = daysDifference / 12;
        } else if (daysDifference > 0) {
            values["reservedType"] = "2";
            values["reservedVal"] = daysDifference;
        } else if (hoursDifference > 0) {
            values["reservedType"] = "1";
            values["reservedVal"] = hoursDifference;
        } else {
            values["reservedType"] = "1"
            values["reservedVal"] = "2"
        }

        // console.log(values);


        // values.reservedVal = values.expDate;

        // console.log(values);
        // return;

        formikBeg.setSubmitting(true)
        let form = new FormData()

        form.append("params[authToken]", this.getToken())
        form.append("params[noCharge]", 1);
        for (let key in values) {

            console.log(key);
            console.log(values[key]);

            if (key === "locationId" || key === "reservedType" || key === "reservedVal" || key === "year") {


                // if (key === "reservedType" && values[key].value === "4") {

                //     //form.append(`params[reservedVal]`, 1)

                //     //form.append(`params[${key}]`, values[key].value)

                //     //delete values["reservedVal"];// REMOVE reservedVal KEY IF reservedType VALUE IS 2 OR 3 BECAUSE BACKEND WANT 1 VALUE BY DEFAULT 
                //     // SO WE DON'T WANT TO ITERATE IT ANYMORE 
                // }
                // else {

                if (values[key])
                    form.append(`params[${key}]`, values[key].value);
                // form.append(`params[${key}]`, values[key].value)
                // }
            }
            else {
                form.append(`params[${key}]`, values[key]);
            }

        }

        console.log(form);
        // return;

        //form.append("params[searchText]", "");

        try {

            _callApi(form, END_POINT.RESERVE_PERMIT.END_POINT, END_POINT.RESERVE_PERMIT.POST, {}).then(res => {

                console.log("res " + JSON.stringify(res));
                let { result = null, error = null } = res.data.payload

                if (error == 401) {

                    this.props.setAuthToken()
                }
                else if (error) {

                    let newPermit = this.state.newPermit

                    newPermit.error = error.message
                    newPermit.success = ""
                    this.setState({ newPermit: newPermit })

                    formikBeg.setSubmitting(false)


                }
                else {

                    let newPermit = this.state.newPermit

                    formikBeg.setSubmitting(false)

                    // UPDATE PERMIT LIST //
                    let form2 = new FormData()

                    form2.append("params[authToken]", this.getToken())
                    form2.append("params[page]", 1);
                    form2.append("params[isGuest]", 1);
                    this.props.getPermitList(form2).then(res => {

                        // this.setState({shouldUpdate:Math.random()})
                    })
                    //========== END======//

                    // UPDATE PARKING LIST OPTIONS
                    this.getParkingListOptions()
                    // ========= END =========//
                    newPermit.success = "Successfully added"
                    newPermit.error = ""
                    this.setState({ newPermit: newPermit })

                    setTimeout(() => {
                        newPermit.success = ""
                        this.setState({ newPermit: newPermit })
                        this.toggleReservedPopup()

                    }, 2000)
                }
            })
        }
        catch (error) {
            console.error(error)
        }
    }

    handleEditSubmit = (values, formikBag) => {



        formikBag.setSubmitting(true)
        let form = new FormData()

        form.append("params[authToken]", this.getToken())


        for (let key in values) {


            // console.log(key)
            if (key === "locationId" || key === "reservedType" || key === "reservedVal" || key === "year") {

                if (values[key]) {
                    form.append(`params[${key}]`, values[key].value)
                }

            } else {
                form.append(`params[${key}]`, values[key])
            }
        }


        _callApi(form,
            END_POINT.UPDATE_PERMIT_DETAILS.END_POINT,
            END_POINT.UPDATE_PERMIT_DETAILS.POST, {}).then(res => {

                let { result = null, error = null } = res.data.payload

                if (error == 401) {

                    this.props.setAuthToken()



                    formikBag.setSubmitting(false)
                }
                else if (!error) {


                    formikBag.setSubmitting(false)

                    let form2 = new FormData()

                    form2.append("params[authToken]", this.getToken())
                    form2.append("params[page]", 1);
                    form2.append("params[isGuest]", 1);
                    this.props.getPermitList(form2)

                    this.toggleEditPermit({})

                } else {

                }
            })
    }

    //  THIS IS FOR HANDLING FILTER OPTIONS WHETHER THEY ARE CHANGED ALONG TIME IF THEY ARE CALL LISTING API AGAIN AND SET THEIR CORRESPONDING VALUES TOO
    dropdownOptionChange = ({ setFieldValue, item, name, resetForm }) => {


        // CHECKING CURRENT STATE OF FILTERS SINCE WE HAVE TWO DROPDOWN FILTERS

        // 1) locationId
        let current = this.state.current

        if (name === "locationId") {

            current.locationId = item.value

            if (item.value === "") {

                current.pageNo = 1
                current.locationId = ""


                setFieldValue(name, item)

                //======================================== setFieldValue method is of formik form handler=================================
                // setFieldValue("permitType", { value: "", label: "Show All" })

            }
            else {
                setFieldValue(name, item)
                current.pageNo = 1
            }
        }
        // 2) permitType
        if (name === "permitType") {

            current.permitType = item.value

            if (item.value === "") {
                current.pageNo = 1

                current.permitType = ""
                //======================================== setFieldValue method is of formik form handler=================================
                // setFieldValue("locationId", { value: "", label: "Show All" })
                setFieldValue(name, item)

            }
            else {

                setFieldValue(name, item)
                current.pageNo = 1

            }
        }

        // CALL API WITH SELECTED FILTERS
        let form = new FormData()

        form.append("params[locationId]", current.locationId)
        form.append("params[reservedType]", current.permitType)
        form.append("params[searchText]", current.searchText)
        form.append("params[page]", current.pageNo)
        form.append("params[authToken]", this.getToken())
        form.append("params[isGuest]", 1);
        current.pageNo = 1
        this.setState(() => ({ current: current, totalPage: 0, pageSize: 0 }))

        // CALLING REDUX ASYNC API THAT WAS ADDED DURING COMPONENT MOUNTED THROUGH USE OF `connect` method AT BOTTOM
        this.props.getPermitList(form)


    }

    // THIS OPTION CHANGE HANDLER IS FOR ALL MODALS THAT HAVE select input TAG 
    handleCurrentOptionChange({ setFieldValue, item, name }) {

        setFieldValue(name, item)
    }



    toggleSuspendModal = () => {


        this.setState({ suspendModal: !this.state.suspendModal })

        // IF EDIT PERMIT MODAL IS OPENED

        // this.suspendConfirmModal()

        if (this.state.modal)
            this.toggleEditPermit({})
    }


    suspendUser = () => {


        let { reserveLocationID, isSuspended } = this.state.selectedPermit

        // console.log("isSuspended===========",isSuspended)
        let form = new FormData()

        if (+isSuspended === 0) {

            form.append("params[status]", 1)

        } else {

            form.append("params[status]", 0)
        }
        form.append("params[reserveLocationID]", reserveLocationID)
        form.append("params[authToken]", this.getToken())

        _callApi(form,
            END_POINT.SUSPEND_USER.END_POINT,
            END_POINT.SUSPEND_USER.POST,
            {}).then(res => {

                let { result = null, error = null } = res.data.payload

                // console.log(res)
                if (error == 401) {

                    this.props.setAuthToken()
                } else if (!error) {

                    this.setState({ suspendModal: !this.state.suspendModal })

                    this.suspendConfirmModal()



                }
            })

    }


    suspendConfirmModal = () => {


        this.setState({ confirmModal: !this.state.confirmModal })

        let { current } = this.state

        let form = new FormData()

        form.append("params[locationId]", current.locationId)
        form.append("params[reservedType]", current.permitType)

        if (current.searchText && current.searchText.trim()) {

            form.append("params[searchText]", current.searchText.trim())

        }

        form.append("params[page]", current.pageNo)
        form.append("params[authToken]", this.getToken())
        form.append("params[isGuest]", 1);
        this.props.getPermitList(form)

    }

    handleCsvRead = data => {
        // console.log(data);

        let form = new FormData()


        // console.log(typeof data);
        form.append("params[authToken]", this.getToken());
        form.append("params[permits]", JSON.stringify(data));
        // console.log(data);
        _callApi(form, END_POINT.IMPORT_PERMIT_CSV.END_POINT, END_POINT.IMPORT_PERMIT_CSV.POST, {}).then(res => {

            // console.log(res)
            let { result = null, error = null } = res.data.payload

            if (error) {
                let csvUploadMsg = this.state.csvUploadMsg

                csvUploadMsg.error = true
                csvUploadMsg.msg = error.message
                this.setState(() => ({ csvUploadMsg: csvUploadMsg }))

                this.toggleCsvConfirm()


            }
            else if (error === 401) {

                this.props.setAuthToken();

                this.toggleCsvConfirm()
            }
            else {



                let csvUploadMsg = this.state.csvUploadMsg

                csvUploadMsg.msg = "Succefully Uploaded"
                csvUploadMsg.error = false

                this.setState(() => ({ csvUploadMsg: csvUploadMsg }))
                this.toggleCsvConfirm()
                setTimeout(() => {
                    let form = new FormData()

                    form.append("params[authToken]", this.getToken())
                    form.append("params[page]", this.state.current.pageNo);
                    form.append("params[isGuest]", 1);
                    // this.setState({ newPermit: newPermit })

                    // this.toggleReservedPopup({ t

                    this.props.getPermitList(form)

                }, 2000)
            }
        })
    };


    toggleCsvConfirm = () => {


        let current = this.state.current;
        current.pageNo = 1;
        // this.setState(()=>({csvUploadMsg:csvUploadMsg}))
        this.setState((preState) => ({ csvConfirmModal: !preState.csvConfirmModal, current, totalPage: 0, pageSize: 0 }))
    }
    buildCsv = () => {

        let arrData = new Array();

        this.state.guestPermit.list.map((value, index, array) => {
            const picked = (({ name, phone, email, company, reservedAt, expiredAt, licenseNumber, make, model,permitAddress }) => {
                let reserved = new Date(reservedAt * 1000);
                let expired = new Date(expiredAt * 1000);
                
                reserved = reserved.toLocaleDateString() + " " + reserved.getHours() + ":" + reserved.getMinutes();
                expired = expired.toLocaleDateString() + " " + expired.getHours() + ":" + expired.getMinutes()
                return {
                    Name: name,
                    Phone: phone,                    
                    Email: email,
                    "Parking Lot": company,
                    Plate: licenseNumber,
                    Make: make,
                    Model: model,
                    Year: value.year,
                    State: value.state,
                    Reserved: reserved,
                    Expires: expired,
                    Address:permitAddress
                }
            })(value);

            arrData.push(picked);
        });

        if (arrData.length) {

            this.downloadFile(arrData);
        }
    }

    downloadFile(data) {
        const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        let csv = data.map(row =>
            header
                .map(fieldName => JSON.stringify(row[fieldName], replacer))
                .join(",")
        );
        csv.unshift(header.join(","));
        let csvArray = csv.join("\r\n");

        var a = document.createElement("a");
        document.body.appendChild(a);
        var blob = new Blob([csvArray], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        console.log("blob", blob);
        console.log("url", url);

        a.href = url;
        a.download = `myFile-${new Date().toLocaleDateString()}.csv`;
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            console.log("a", a);
            window.URL.revokeObjectURL(url);
            console.log("a", "zsasas");
        }, 100);
    }


    getToken() {

        return localStorage.getItem("jwtToken");
    }

    getDateUnit = unit => {
        let time = 0;
        let date = new Date();
        switch (unit) {
          case "date":
            time = date.getDate();
            break;
          case "year":
            time = date.getFullYear();
            break;
          case "month":
            time = date.getMonth();
            break;
          case "hours":
            time = date.getHours();
            break;
          case "minutes":
            time = date.getMinutes();
            break;
          case "seconds":
            time = date.getSeconds();
            break;
        }
    
        return time;
      };

    render() {

        let startYear = new Date().getFullYear() + 1
        // console.log(startYear);
        let yearBehind100 = startYear - 99

        let years = []

        for (let i = yearBehind100; i <= startYear; i++) {
            // console.log(i);
            years.push(i)
        }

        let options = years.reverse().map(item => {
            return { value: item + "", label: item + "" }
        })




        return (
            <React.Fragment>

                <div className="row">

                    <div className="header-panel1 common-padding-left-right">
                        <div className="search-box">
                            <input type="text" placeholder="Search" ref={this.searchInput} onChange={(e) => {
                                let current = this.state.current
                                current.searchText = e.target.value
                                current.pageNo = 1;


                                let totalPage = this.state.totalPage
                                let pageSize = this.state.pageSize

                                totalPage = 0;
                                pageSize = 0;
                                // current.searchText = e.target.value



                                this.setState({ current: current, totalPage, pageSize })

                                this.handlePageNo()

                            }} className=" form-control search-field" />
                            <i className="fa fa-search search-icon" />
                        </div>
                        <div className="btn-csv">
                            <ul>
                                <li className="">
                                    <div className="btn-toggle-class">
                                        <ul className="clearfix">
                                            <li>
                                                <NavLink to={`${this.props.match.path}`} className={(this.props.match.url === "/main/permits/guests") ? "active" : ""}>Guest</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to={"/main/permits/residents"} className={(this.props.match.url === "/main/permits/residents") ? "active" : ""} >Resident</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="d-flex align-items-center">
                                    <Formik
                                        initialValues={{
                                            locationId: [],
                                            permitType: []
                                        }}
                                        enableReinitialize={true}
                                        render={({ values, errors, handleBlur, handleChange, setFieldValue, resetForm }) => (

                                            <Form>
                                                <ul className="drop-btns">
                                                    <li className="dropdown-list filters-dropdown">


                                                        <Select
                                                            value={values.locationId}
                                                            // onChange={handleChange}
                                                            options={this.state.parkingList.options}
                                                            className=""
                                                            name="locationId"
                                                            // isSearchable={false}
                                                            onBlur={handleBlur}
                                                            onChange={(item) => this.dropdownOptionChange({ setFieldValue, item, name: "locationId", resetForm })}
                                                            placeholder={"Parking Lot"}
                                                        />

                                                    </li>

                                                    <li className="dropdown-list filters-dropdown">

                                                        <Select
                                                            value={values.permitType}
                                                            // onChange={handleChange}
                                                            className=""
                                                            options={permitTypeOptions}
                                                            name="permitType"
                                                            isSearchable={false}
                                                            onBlur={handleBlur}
                                                            onChange={(item) => this.dropdownOptionChange({ setFieldValue, item, name: "permitType", resetForm })}
                                                            placeholder={"Permit type"}
                                                        />
                                                    </li>
                                                </ul>
                                            </Form>
                                        )}
                                    />
                                </li>
                                <li>
                                    <button className="btn btn-common" onClick={this.toggleReservedPopup}>
                                        Add New
                                    </button>
                                </li>

                                <li>

                                    <CSVReader
                                        cssClass="csv-reader-input pointer-cursor padding btn-permit browse-parent"
                                        label="Import CSV"

                                        onFileLoaded={this.handleCsvRead}
                                        onError={this.handleDarkSideForce}
                                        inputId="ObiWan"
                                    // inputStyle={{color: 'red'}}
                                    // inputStyle={{visibility: 'none'}}

                                    />

                                    {/* <div class="fileUpload btn-permit">
                                        <span>Import CSV</span>
                                        <input type="file" class="upload" />
                                    </div> */}
                                </li>

                                <li>
                                    <button onClick={this.buildCsv} className="btn btn-common">
                                        Export as CSV
                    </button>

                                </li>
                            </ul>

                        </div>
                    </div>



                    <div className="table-listing-parking common-padding-left-right">
                        <table>
                            <thead>

                                <tr className="head margin-row-zero">
                                    <td>#</td>
                                    <td>Name</td>
                                    <td>Phone</td>
                                    <td>Email</td>
                                    <td>Parking Lot</td>
                                    <td>Type</td>
                                    <td>Reserved</td>
                                    <td>Expires</td>
                                    <td>Plate #</td>
                                    <td>Make</td>
                                    <td>Model</td>
                                    <td>Reservations</td>
                                    <td>Status</td>
                                </tr>

                            </thead>

                            <tbody>



                                {
                                    (this.props.listError)
                                        ? <tr key={"error"}> <td colSpan="10">{this.props.listError.message}</td> </tr> :
                                        (this.props.loading) ?
                                            <tr key={"error"}>
                                                <td colspan="12" className="text-center">

                                                    <div className="driver-loder">

                                                        <ReactLoading color="blue" type="spinningBubbles" />
                                                    </div>
                                                </td>

                                            </tr>
                                            : this.loadPermitList()
                                }

                            </tbody>
                        </table>

                        <ReactPaginate
                            pageCount={this.state.totalPage}
                            pageRangeDisplayed={this.state.pageSize}
                            onPageChange={(page) => {
                                let current = this.state.current

                                current.pageNo = page.selected + 1

                                this.setState({ current: current })

                                this.handlePageNo()
                            }}
                            forcePage={this.state.current.pageNo - 1}   // USED TO FORCEFULLY SET CURRENT PAGE NO.,
                            //I USED IT IN CASE OF FILTERS WHERE WE WANT TO SYNC PAGES OF AVAILABLE PAGES FOR SELECTED FILTERS
                            containerClassName="pagination-custom" />


                    </div>
                </div>

                <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.state.csvConfirmModal} toggle={() => this.toggleCsvConfirm()}>
                    <ModalHeader style={{ backgroundColor: "white" }} > <span style={{ fontSize: "40px", color: `${this.state.csvUploadMsg.error ? "red" : "green"}` }} >{this.state.csvUploadMsg.msg && this.state.csvUploadMsg.msg}</span> </ModalHeader>
                    <div className="row mt-3 mb-3 ">

                        <div className="col-md-12 justify-content-center d-flex">

                            <button type="button" onClick={this.toggleCsvConfirm} className="btn btn-common width-btn">Ok</button>

                        </div>
                    </div>
                </Modal>

                <EditPermitModal
                    handleEditSubmit={this.handleEditSubmit}
                    modal={this.state.modal}
                    toggleEditPermit={this.toggleEditPermit}
                    toggleSuspendModal={this.toggleSuspendModal}
                    handleCurrentOptionChange={this.handleCurrentOptionChange}
                    parkingList={this.state.parkingList}
                    selectedPermit={this.state.selectedPermit}
                    editPermit={this.state.editPermit} />

                <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={this.state.suspendModal} toggle={this.toggleSuspendModal} className={this.props.className}>

                    <ModalBody>
                        <div className="modal-body-wrapper mb-2">
                            <div className="modal-icon text-center">
                                <img src={warning} alt="image is not available" />
                            </div>
                            <div className="modal-message text-center">
                                <div>Are you sure you want to {+this.state.selectedPermit.isSuspended === 0 ? "suspend" : "unsuspend"}  <strong>{this.state.selectedPermit.name}</strong> from <strong>{this.state.selectedPermit.company}</strong> ?</div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "5px" }}>
                            <Row>
                                <Col>
                                    <Button color="primary btn-block delete-exercise-btn" onClick={this.suspendUser}>Yes</Button>
                                </Col>
                                <Col>
                                    <Button color="secondary btn-block cancel-exercise-btn" onClick={this.toggleSuspendModal}>Cancel</Button>
                                </Col>
                            </Row>
                        </div>

                    </ModalBody>

                </Modal>

                <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={this.state.confirmModal} toggle={this.suspendConfirmModal} className={this.props.className}>

                    <ModalBody>
                        <div className="modal-body-wrapper mb-2">
                            <div className="modal-icon text-center">
                                <img src={warning} alt="image is not available" />
                            </div>
                            <div className="modal-message text-center">
                                <div className="pt-2 pl-2">
                                    <strong>{this.state.selectedPermit.name}</strong> is {+this.state.selectedPermit.isSuspended === 0 ? "suspended" : "unsuspended"}  from reserving permits  at <strong> {this.state.selectedPermit.company} </strong>
                                </div>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                        <div style={{ paddingLeft: "20px", paddingRight: "20px", paddingBottom: "5px" }}>
                            <Row>
                                <Col>
                                    <Button color="primary btn-block delete-exercise-btn" onClick={this.suspendConfirmModal}>OK</Button>
                                </Col>
                            </Row>
                        </div>

                    </ModalBody>

                </Modal>
                <div>

                    <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.state.modalReserved} toggle={this.toggleReservedPopup} className={this.props.className}>
                        <ModalHeader >Reserve a Permit</ModalHeader>
                        <ModalBody>

                            <Formik
                                key="newPermit"
                                initialValues={{
                                    locationId: null,
                                    licenseNumber: "",
                                    model: "",
                                    make: "",
                                    // reservedType: { value: "1", label: "Hourly" },
                                    // reservedVal: null,
                                    phone: "",
                                    email: "",
                                    year: options[0],
                                    decal: "",
                                    state: '',
                                    name: '',
                                    permitAddress:'',
                                    expDate: new Date()
                                }}

                                validationSchema={Yup.object().shape({

                                    locationId:
                                        Yup.object().shape({
                                            value: Yup.string(),
                                            label: Yup.string()
                                        }).nullable(true).required("Please select a location"),

                                    licenseNumber: Yup.string()
                                        .required("Please enter licence number"),

                                    expDate: Yup.string().required("Please select expiration date"),

                                    model: Yup.string()
                                        .required("Please enter model number"),

                                    make: Yup.string()
                                        .required("Please enter make value"),

                                    // reservedType: Yup.object().shape({
                                    //     value: Yup.string(),
                                    //     label: Yup.string()
                                    // }).nullable(true).required("Please select permit type i.e. Hourly, Daily, or Monthly"),

                                    // reservedVal: Yup.object().shape({
                                    //     value: Yup.string(),
                                    //     label: Yup.string()
                                    // }).when(
                                    //     "reservedType",
                                    //     {
                                    //         is: (reservedType) => (reservedType && (reservedType.value === "2" || reservedType.value === "4" || reservedType === null)),
                                    //         then: Yup.object().nullable(true),
                                    //         otherwise: Yup.object().shape({
                                    //             value: Yup.string(),
                                    //             label: Yup.string()
                                    //         }).nullable(true).required("Please select options")
                                    //     }),

                                    phone: Yup.string().matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                        .min(10, VALIDATE.PHONE_MIN),

                                    email: Yup.string()
                                        .email(VALIDATE.EMAIL_INVALID)
                                        .required(VALIDATE.EMAIL_REQ),
                                    year: Yup.object().shape({
                                        value: Yup.string(),
                                        label: Yup.string()
                                    }).nullable(true).required("Please select a Year"),
                                    state: Yup.string().required("Enter state name").max(255, "You reached the limit of characters"),
                                    name: Yup.string().required("Enter owners name").max(100, "Maximum characters met.")

                                    // decal: Yup.string()
                                })}
                                onSubmit={this.handleNewPSubmit}
                                render={({ values, handleChange, handleBlur, errors, setFieldValue, touched, setFieldError, isInitialValid, isSubmitting }) =>
                                    (<Form>


                                        <div className="popup-form">

                                            <div className="row  mb-2">
                                                <span style={{ fontSize: "12px", color: "red" }} >{this.state.newPermit.error && this.state.newPermit.error}</span>
                                                <span style={{ fontSize: "12px", color: "green" }} >{this.state.newPermit.success && this.state.newPermit.success}</span>

                                            </div>
                                            <Row>
                                                <Col md="6">
                                                    <InputGroup>
                                                        <label class="mr-2">Name</label>
                                                        <input type="text" name="name" value={values.name} onBlur={handleBlur} onChange={handleChange} className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.name && errors.name && errors.name}</span>
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup>
                                                        <label class="mr-2">Email</label>
                                                        <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.email && errors.email && errors.email}</span>
                                                    </InputGroup>
                                                </Col>
                                            </Row>

                                            <Row className="pt-2">
                                                <Col md="6">
                                                    <InputGroup>
                                                        <label class="mr-2">Phone</label>
                                                        <input className="form-control" type="text" name="phone" value={values.phone} onBlur={handleBlur} onChange={handleChange} autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.phone && errors.phone && errors.phone}</span>
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup>
                                                        <label class="mr-2">Address</label>
                                                        <textarea className="form-control" name="permitAddress" value={values.permitAddress} onBlur={handleBlur} onChange={handleChange} />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.permitAddress && errors.permitAddress && errors.permitAddress}</span>
                                                    </InputGroup>
                                                </Col>


                                            </Row>

                                            <Row className="pt-2">
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                        <label class="mr-2">Parking Lot</label>
                                                        <div className="input-box-inputcls filters-dropdown">
                                                            <Select
                                                                value={values.locationId}
                                                                onChange={(item) => this.handleOptionChange(setFieldValue, item, "locationId")}
                                                                options={this.state.parkingList.options.filter((item, index) => index != 0)}
                                                                className="selectlocation"
                                                                name="locationId"
                                                                onBlur={handleBlur}
                                                                minMenuHeight={10}
                                                                placeholder={"Select a location"}

                                                            />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.locationId && errors.locationId && errors.locationId}</span>
                                                        </div>
                                                    </InputGroup>
                                                </Col>

                                                <Col md="6" >
                                                    <InputGroup>
                                                        <label class="mr-2">Decal #</label>
                                                        <input
                                                            type="text"
                                                            name="decal"
                                                            value={values.decal}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            className="form-control"
                                                            autoComplete="false"
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.decal && errors.decal && errors.decal}</span>
                                                    </InputGroup>
                                                </Col>

                                            </Row>
                                            <Row className="pt-2">
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                    <label class="mr-2">Reserved To</label>
                                                    {/* <DateTimePicker
                                                        className="mr-2"
                                                        name="expDate"
                                                        onChange={this.onChange}
                                                        value={this.state.expDate}
                                                    /> */}
                                                    
                                                    <DatePicker
                                                        selected={this.state.expDate}
                                                        // value= { event.fromDate }
                                                        onChange={this.onChange}
                                                        name="expDate"
                                                        autoComplete="off"
                                                        showTimeSelect
                                                        className="form-control"
                                                        //   onBlur={handleBlur}
                                                        minTime={
                                                            this.value && this.value.getDate() === this.getDateUnit("date")
                                                            ? new Date(
                                                                this.getDateUnit("year"),
                                                                this.getDateUnit("month"),
                                                                this.getDateUnit("date"),
                                                                this.getDateUnit("hours"),
                                                                this.getDateUnit("minutes"),
                                                                this.getDateUnit("seconds")
                                                                )
                                                            : null
                                                        }
                                                        maxTime={
                                                            this.value && this.value.getDate() === this.getDateUnit("date")
                                                            ? new Date(
                                                                this.getDateUnit("year"),
                                                                this.getDateUnit("month"),
                                                                this.getDateUnit("date"),
                                                                23,
                                                                59,
                                                                59
                                                                )
                                                            : null
                                                        }
                                                        dateFormat="MM / dd / yyyy h:mm aa"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        minDate={new Date()}
                                                        disabledKeyboardNavigation
                                                        onKeyDown={e => {
                                                            e.nativeEvent.preventDefault();
                                                        }}
                                                        />
                                                    </InputGroup>
                                                    </Col>
                                                    <Col md="6">
                                                        <InputGroup class="pt-2">
                                                            <label class="mr-2">Plate #</label>
                                                            <input name="licenseNumber" value={values.licenseNumber} onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" autoComplete="false" />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.licenseNumber && errors.licenseNumber && errors.licenseNumber}</span>
                                                        </InputGroup>
                                                    </Col>
                                            </Row>
                                            {/*<Row className="pt-2 pb-2">
                                                 <Col md="6">
                                                    <InputGroup>
                                                        <label class="mr-2">Permit Type</label>
                                                        <div className="input-box-inputcls filters-dropdown">
                                                            <Select
                                                                value={values.reservedType}
                                                                onChange={handleChange}
                                                                options={permitTypeOptions.filter((item, index) => index != 0)}
                                                                className="selectlocation"
                                                                name="reservedType"
                                                                isSearchable={false}
                                                                onBlur={handleBlur}
                                                                onChange={(item) => {
                                                                    this.handleOptionChange(setFieldValue, item, "reservedType")
                                                                    
                                                                    if (item.value !== "1") {
                                                                        this.handleOptionChange(setFieldValue, null, "reservedVal")
                                                                    } else {

                                                                        // setFieldError("reservedVal","Please select hours")
                                                                        this.handleOptionChange(setFieldValue, null, "reservedVal")
                                                                    }
                                                                }
                                                                }
                                                                placeholder={"Select a permit type"}
                                                            />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.reservedType && errors.reservedType && errors.reservedType}</span>
                                                        </div>

                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup class="pt-2">

                                                        <label class="mr-2">Reserved </label>
                                                        <div className="input-box-inputcls filters-dropdown">
                                                            <Select
                                                                value={values.reservedVal}
                                                                onChange={handleChange}
                                                                options={values.reservedType && values.reservedType.value === '1' ? APP_CONST.HOURS : values.reservedType && values.reservedType.value === '3' ? APP_CONST.months : APP_CONST.days}
                                                                className="selectlocation"
                                                                name="reservedVal"
                                                                isSearchable={false}
                                                                isDisabled={(values.reservedType && (values.reservedType.value === '4'))}
                                                                onBlur={handleBlur}
                                                                onChange={(item) => this.handleOptionChange(setFieldValue, item, "reservedVal")}
                                                                placeholder={values.reservedType && values.reservedType.value === '1' ? "Select hours" : values.reservedType && values.reservedType.value === '3' ? "Select month" : 'daily'}
                                                            />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.reservedVal && errors.reservedVal && errors.reservedVal}</span>
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                            </Row> */}
                                            <Row>
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                        <label class="mr-2">Make</label>
                                                        <input type="text" name="make" value={values.make} onBlur={handleBlur} onChange={handleChange} className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.make && errors.make && errors.make}</span>
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                        <label class="mr-2">Model</label>
                                                        <input type="text" name="model" value={values.model} onBlur={handleBlur} onChange={handleChange} className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.model && errors.model && errors.model}</span>
                                                    </InputGroup>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                        <label class="mr-2">State</label>
                                                        <input name="state" value={values.state} onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.state && errors.state}</span>
                                                    </InputGroup>
                                                </Col>
                                                <Col md="6">
                                                    <InputGroup className="pt-2">
                                                        <label class="mr-2">Vehicle Year</label>
                                                        <div className="input-box-inputcls filters-dropdown">
                                                            <Select
                                                                value={values.year}
                                                                onChange={(item) => this.handleOptionChange(setFieldValue, item, "year")}
                                                                options={options}
                                                                className="selectlocation"
                                                                name="year"
                                                                onBlur={handleBlur}
                                                                minMenuHeight={10}
                                                                placeholder={"Select a Year"}
                                                            />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.year && errors.year && errors.year}</span>
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                            </Row>

                                            <div className="row mt-3 mb-3">
                                                <div className="col-sm-4 offset-sm-4 col-12">
                                                    <div className="row align-items-center justify-content-center">
                                                        <button disabled={isSubmitting} type="submit" class="btn btn-common save-btn save-padding ">
                                                            {(isSubmitting) ? "Submitting" : "Save"}</button>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* {JSON.stringify(errors)} */}

                                        </div>
                                    </Form>)}
                            />
                        </ModalBody>

                    </Modal>
                </div>



            </React.Fragment >
        );
    }
}

export default Guests = withRouter(connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(Guests));