import React, { Component } from 'react';
import { Link, Switch, Route } from 'react-router-dom';
// import { Link, } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Row, Col, InputGroup } from 'reactstrap';
import { connect } from "react-redux";
import { ParkingLotsApiCall } from "../../../store/api-actions/ParkingLotsApiCall";
import { withRouter } from "react-router-dom";
import { ParkingListView } from "../views/ParkingListView";
import PropTypes from "prop-types";
import ReactPaginate from 'react-paginate';
import { isString } from 'util';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import _callApi from '../../../Services/baseService';
import { END_POINT } from '../../../Constants/ApiEndPoints';
import { APP_CONST } from '../../../Constants/AppConstants';
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { VALIDATE } from '../../../Constants/ValidationConst';
import ReactLoading from 'react-loading';
import CSVReader from 'react-csv-reader'
import _ from 'lodash';
// import AddnewLot from './AddnewLot';
import { SetAuthTokenApi } from '../../../store/api-actions/SetAuthTokenApi';
import ParkingLotSuspendModal from '../views/ParkingLotSuspendModal';


function mapDipatchToProps(dispatch) {
    return {
        callParkListApi: (form) => dispatch(ParkingLotsApiCall({ form })),
        setAuthToken:()=>dispatch(SetAuthTokenApi())
    };
}

function mapStateToProps(state) {
    // console.log(state)
    return {
        parkingLots: state.parkList.parkByIDs,
        parkingError: state.parkList.error,
        totalParks: state.parkList.total,
        isFetching: state.parkList.isFetching,
        logging: state.login.logging,
    };
}

const permitTypeOptions = [
    { value: "1", label: "Hourly" },
    { value: "2", label: "Daily" },
    { value: "3", label: "Monthly" },
    // { value: "4", label: "Yearly" },
]

class ParkingLotList extends Component {
    constructor(props) {
        super(props);

        this.searchInput = React.createRef()
        this.state = {
            collapse: false,
            modal: false,
            modalReserved: false,
            modalEdit: false,
            isChecked: props.isChecked || false,
            parkingLots: [],
            update: null,
            userType: null,
            // ============PARKING LOTS OPTIONS=================//
            currentPark: {
                locationId: "",
                licenseNumber: "",
                model: "",
                make: "",
                reservedType: "",
                reservedVal: "",
                phone: "",
                email: "",
                company: ""
            },
            parkingList: {
                options: []
            },
            newPermit: {
                error: "",
                success: ""
            },
            suspendParkingLotModal:{
                ipermitLocationID:"",
                isOpen:false,
                status:null,
            },
            // =========for pagination =================//
            pageSize: 0,
            totalPage: 0,
            selected: 0,
            // =============END======================= //
            parkingError: null,
            csvUploadMsg:{
                error:false,
                msg:""
            },
            csvConfirmModal:false
        };


        this.toggleReservedPopup = this.toggleReservedPopup.bind(this);

        this.toggleEvent = this.toggleEvent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleParkingLots = this.toggleParkingLots.bind(this);
        this.toggleEditLots = this.toggleEditLots.bind(this);
    }



    toggleReservedPopup({ row, toggle }) {

        // console.log(row)
        if (toggle) {


            this.setState((preState)=>{
                
                let newPermit = preState;

                newPermit.success = ""
                newPermit.error = ""
             return    { modalReserved: !preState.modalReserved, newPermit: newPermit   }
            
            })
        }
        else {

            this.setState({
                modalReserved: !this.state.modalReserved,
                currentPark: row
            });

        }

        // console.log(this.state.currentPark)
    }


    // check toggle btn
    handleChange() {
        this.setState({ isChecked: !this.state.isChecked })
    }

    toggleParkingLots() {
        this.setState({
            modal: !this.state.modal
        });
    }


    handlePageNo = ({ selected , search }) => {

        let form = new FormData()


        form.append("params[authToken]", this.getToken())


        if(selected){

            form.append("params[page]", selected );

        }

        // ==========================HERE SEARCH INPUT this.searchInput is a reference to input box================== //

        let searchText = this.searchInput.current.value

        if (isString(searchText) && searchText.trim()) {


            this.setState(() => ({
                    totalPage: 0,
                    selected:0,
                    pageSize:0
                }))

            form.append("params[searchText]", searchText.trim())

        }
        else {
            form.append("params[searchText]", "")

        }
        if (search) {

            this.props.callParkListApi(form)

        } else {

            this.props.callParkListApi(form)

        }

        // this.setState(() => ({
        //     totalPage: 0,
        //     selected:0,
        //     pageSize:0
        // }))
        // console.log("selected page ", this.state.selected, "total page ", this.state.totalPage)

    }




    toggleEvent(id) {

        let newState = [...[], ...this.state.parkingLots];

        newState.map((item, index) => {

            if (item.locationId === id) {

                item.collapse = !item.collapse;

            }
            else {
                item.collapse = false;
            }
        })
        this.setState({
            collapse: !this.state.collapse
        });
    }

    toggleEditLots() {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }

    componentWillUnmount(){

        // let wasOnEdit = localStorage.getItem("onEdit")
    }

    componentWillMount() {

        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)

        //    console.log(pathname)
        // this.props.login()
        
                let userType = localStorage.getItem("userType");
        
        
        // =================GETTING LIST OF PARKING LIST BEFORE COMPONENT IS BEING LOADED===========================//
        let token = this.getToken()
        
        let form = new FormData()
        
        form.append("params[authToken]", token)
        
        let currentPageNo = localStorage.getItem("page_no")
        
        if(currentPageNo){

             form.append("params[page]", +currentPageNo);

             this.setState({ userType: userType , selected : +currentPageNo-1})

        }else{
            this.setState({ userType: userType })

            form.append("params[page]", 1);
        }
        
        localStorage.removeItem("page_no")
        this.props.callParkListApi(form);


    }

    componentWillReceiveProps() {

        let parkings = this.props.parkingLots;
        let parkingLots = this.state.parkingLots;
        let total = this.props.totalParks
        let error = this.props.parkingError


        let totalPage = Math.ceil(+total / 10);


        parkings.map((item) => {

            item["collapse"] = false;

        })

        this.setState({
            parkingLots: parkings, totalPage: totalPage, parkingError: error
        });

    }


    //==================================================GETTING PARKING LIST OPTIONS===========================================================================================//

    // ========================================================END=============================================================================================================//

    handleNewPSubmit = (values, formikBeg) => {

        // console.log(values)
        let { locationId, licenseNumber, model, make, reservedType, reservedVal, phone, email } = values

        formikBeg.setSubmitting(true)
        let form = new FormData()

        form.append("params[authToken]", this.getToken())

        for (let key in values) {

            if ( key === "reservedType" || key === "reservedVal" || key === "year") {

                // console.log(key, values);

                if (key === "reservedType" && values[key].value === "2") {

                    form.append(`params[reservedVal]`, 1)

                    form.append(`params[${key}]`, values[key].value)

                    delete values["reservedVal"];// REMOVE reservedVal KEY IF reservedType VALUE IS 2 OR 3 BECAUSE BACKEND WANT 1 VALUE BY DEFAULT 
                    // SO WE DON'T WANT TO ITERATE IT ANYMORE 
                }
                else {

                    if (values[key])
                        form.append(`params[${key}]`, values[key].value)
                    // form.append(`params[${key}]`, values[key].value)
                }
            }
            else {
                form.append(`params[${key}]`, values[key])
            }

        }



        _callApi(form, END_POINT.RESERVE_PERMIT.END_POINT, END_POINT.RESERVE_PERMIT.POST, {}).then(res => {

            console.log(res)
            let { result = null, error = null } = res.data.payload

            if (error) {

                let newPermit = this.state.newPermit

                newPermit.error = error.message
                newPermit.success = ""
                this.setState({ newPermit: newPermit })

                formikBeg.setSubmitting(false)

            }
            else {

                let newPermit = this.state.newPermit

                // UPDATE PERMIT LIST //
                // this.props.getPermitList(form)
                //========== END======//

                // UPDATE PARKING LIST OPTIONS

                // ========= END =========//
                formikBeg.setSubmitting(false)

                newPermit.success = "Successfully reserved"
                newPermit.error = ""
                this.setState({ newPermit: newPermit })

                setTimeout(() => {
                    newPermit.success = ""


                    let form = new FormData()

                    form.append("params[authToken]", this.getToken())
                    form.append("params[page]", this.state.selected+1);

                    this.setState({ newPermit: newPermit })

                    this.toggleReservedPopup({ toggle: true })
                    this.props.callParkListApi(form)

                }, 2000)
            }
        })

    }

    handleOptionChange = (setFieldValue, item, name) => {

        setFieldValue(name, item)
        // console.log(e)
    }


    buildCsv() {

        let arrData = new Array();

        this.state.parkingLots.map((value, index, array) => {
            const picked = (({ 
                company, 
                address,
                hasMonthly, 
                hourlyRate, 
                dailyRate, 
                monthlyRate, 
                contactName, 
                phone, 
                totalReserved, 
                lotCapacity,
                latitude, 
                longitude,
                isSuspended,
                contactEmail }) => {
                return {
                    Company: company,
                    Address: address,
                    Hourly: (hourlyRate) ? (+hourlyRate).toFixed(2) : "",
                    Daily: (dailyRate) ? (+dailyRate).toFixed(2) : "",
                    Monthly: (monthlyRate && hasMonthly === "1") ?   (+monthlyRate).toFixed(2) : "0",
                    Available: lotCapacity,
                    ContactName: contactName,
                    Phone: phone,
                    Latitude :latitude,
                    Longitude:longitude,
                    Contact_Email: contactEmail,
                    Suspended: +isSuspended === 0 ? "Not" : "Yes"
                }
            })(value);

            arrData.push(picked);
        });

       if(arrData.length){

           this.downloadFile(arrData);
       } 
    }

    downloadFile(data) {
        const replacer = (key, value) => (value === null ? "" : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        let csv = data.map(row =>
            header
                .map(fieldName => JSON.stringify(row[fieldName], replacer))
                .join(",")
        );
        csv.unshift(header.join(","));
        let csvArray = csv.join("\r\n");

        var a = document.createElement("a");
        document.body.appendChild(a);
        var blob = new Blob([csvArray], { type: "text/csv" }),
            url = window.URL.createObjectURL(blob);

        console.log("blob", blob);
        console.log("url", url);

        a.href = url;
        a.download = `myFile-${new Date().toLocaleDateString()}.csv`;
        a.click();
        setTimeout(function () {
            document.body.removeChild(a);
            console.log("a", a);
            window.URL.revokeObjectURL(url);
            console.log("a", "zsasas");
        }, 100);
    }



    loadParkingList() {
        return this.state.parkingLots.map(item => {

            // console.log(item.locationId)
            return (

                <ParkingListView
                    
                    toggleSuspendParkingLot={this.toggleSuspendParkingLot}
                    key={item.locationId}
                    toggleReservedPopup={this.toggleReservedPopup}
                    toggleEditLots={this.toggleEditLots}
                    toggleEvent={this.toggleEvent}
                    row={item}
                    handleReservPSubmit={this.handleNewPSubmit}
                />
            );
        });
    }

    getToken() {

        return localStorage.getItem("jwtToken")
    }

    handleForce = data => {
        // console.log(data);

        let form = new FormData()
        

        
        form.append("params[authToken]", this.getToken())
        form.append("params[parkingLots]", JSON.stringify(data))

        _callApi(form, END_POINT.IMPORT_PARKING_CSV.END_POINT, END_POINT.IMPORT_PARKING_CSV.POST, {}).then(res => {

            // console.log(res)
            let { result = null, error = null } = res.data.payload

            if (error) {
                let csvUploadMsg = this.state.csvUploadMsg

                csvUploadMsg.error = true
                csvUploadMsg.msg = error.message
               this.setState(()=>({csvUploadMsg:csvUploadMsg}))

               this.toggleCsvConfirm()


            }
            else if (error === 401){

                    this.props.setAuthToken();

                    this.toggleCsvConfirm()
            }
            else {



                    let csvUploadMsg = this.state.csvUploadMsg

                    csvUploadMsg.msg = "Succefully Uploaded"
                    csvUploadMsg.error = false

               this.setState(()=>({csvUploadMsg:csvUploadMsg}))
               this.toggleCsvConfirm()     
               setTimeout(() => {
                    let form = new FormData()

                    form.append("params[authToken]", this.getToken())
                    form.append("params[page]", 1);

                    // this.setState({ newPermit: newPermit })

                    // this.toggleReservedPopup({ toggle: true })
                    this.props.callParkListApi(form)

                }, 2000)
            }
        })
      };

    toggleSuspendParkingLot = (status, ipermitLocationID)=>{


        // console.log(id);
        let { suspendParkingLotModal } = this.state

        if(ipermitLocationID){

            suspendParkingLotModal.ipermitLocationID = ipermitLocationID;
            suspendParkingLotModal['status'] = status;
            suspendParkingLotModal.isOpen = true  ;
        }else{
            suspendParkingLotModal.ipermitLocationID = '';
            suspendParkingLotModal.isOpen = false  ;
            suspendParkingLotModal['status'] = null;
        }

        this.setState({ suspendParkingLotModal });

    }


    suspendParkingLot=()=>{

        let { status, ipermitLocationID } = this.state.suspendParkingLotModal
        let form1 = new FormData()

        if(+status === 1){

             status = 0

        }else{

             status = 1
        }
        form1.append("params[authToken]", this.getToken())
        form1.append("params[status]", status)
        form1.append('params[ipermitLocationID]', ipermitLocationID)


        _callApi(
            form1, 
            END_POINT.SUSPEND_PARKING_LOT.END_POINT, 
            END_POINT.SUSPEND_PARKING_LOT.POST).then(res => {

            console.log(res)
            let { result = null, error = null } = res.data.payload

            if (error) {


                // this.setState({ newPermit: newPermit })

                // formikBeg.setSubmitting(false)

            }
            else {

                setTimeout(() => {

                    let form = new FormData()
                    let searchText = this.searchInput.current.value
                    
                    form.append("params[authToken]", this.getToken())
                    form.append("params[page]", this.state.selected+1);
                    
                    if(searchText && searchText.trim()){
                        
                        form.append("params[searchText]", searchText.trim())
                    }

                    this.toggleSuspendParkingLot();

                    this.props.callParkListApi(form)

                }, 2000)
            }
        })

    }

      toggleCsvConfirm= ()=>{

        

        // this.setState(()=>({csvUploadMsg:csvUploadMsg}))
        this.setState((preState)=>({csvConfirmModal:!preState.csvConfirmModal}))
      }

    render() {

        let currentYear = new Date().getFullYear()
        let yearBehind100 = currentYear - 100

        let years = []

        for (let i = yearBehind100; i <= currentYear; i++) {

            years.push(i)
        }


        let options = years.reverse().map(item => {
            return { value: item + "", label: item + "" }
        })

        let adminDetail = JSON.parse( localStorage.getItem("adminDetail"))

        let userType = null

        if(adminDetail && _.has(adminDetail, "userType")){

            userType = adminDetail.userType

        }

        // console.log(this.props)
        return (
            <React.Fragment>
                <div className="row">
                    <div className="header-panel common-padding-left-right">
                        <div className="search-box">
                            <input type="text" ref={this.searchInput} onChange={(e) => {
                                // this.setState(()=>({totalPage:0})) ; 

                                // ============================I USED SETTIMEOUT BECAUSE I NEED TO RESET PAGINATION SO THAT NO MORE PAGINATION SHOWS===============================================================// 
                                setTimeout(() => this.handlePageNo({  search: this.searchInput }))
                                // ==============================END=====================================//

                            }} className=" form-control search-field" placeholder="Search" name="search" />
                            <i className="fa fa-search search-icon" />
                        </div>
                        <div className="btn-csv">
                            <ul>
                                {
                                    (+this.state.userType === 3) ?
                                        <li>
                                            <Link to={`${this.props.match.path}/add-new`} >
                                                <button className="btn-permit" >
                                                    Add New
                                        </button>
                                            </Link>
                                        </li>
                                        : null
                                }
                        {


                                <li>
                                    
                                        <CSVReader
                                            cssClass="csv-reader-input pointer-cursor padding btn-permit browse-parent"
                                            label="Import CSV"
                                            
                                            onFileLoaded={this.handleForce}
                                            onError={this.handleDarkSideForce}
                                            inputId="ObiWan"
                                            // inputStyle={{color: 'red'}}
                                            // inputStyle={{visibility: 'none'}}
                                            
                                        />
                                    
                                    {/* <div class="fileUpload btn-permit">
                                            <span>Import CSV</span>
                                            <input type="file" class="upload" />
                                        </div> */}
                                </li> 
                        }
                                <li>
                                    <button onClick={() => this.buildCsv()} className="btn-permit">
                                        Export as CSV
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className="table-listing common-padding-left-right">
                        <table className="">
                            <thead className="parking-lot-table">
                                <tr className="head margin-row-zero">
                                    <td>#</td>
                                    <td>Company</td>
                                    <td className="addresstd">Address</td>
                                    <td>Hourly</td>
                                    <td>Daily</td>
                                    <td>Monthly</td>
                                    <td># Available</td>
                                    <td></td>
                                    <td>Contact Name</td>
                                    <td>Phone</td>
                                    <td>Action</td>
                                    <td style={{ color: '#4a4a4a' }}></td>
                                </tr>
                            </thead>

                            <tbody className="parking-lot-table">

                                

                                {
                                    (this.props.isFetching) ?
                                        <tr key={"error"}>
                                            <td colspan="10" width="100%" className="text-center">

                                                <div className="driver-loder">

                                                    <ReactLoading color="blue" type="spinningBubbles" />
                                                </div>
                                            </td>

                                        </tr>

                                        : (this.state.parkingError) ? <tr key={"errorList"} > <td> {" Data not found "} </td> </tr> : this.loadParkingList()
                                }
                                
                            </tbody>
                        </table>
                        

                        <ReactPaginate
                            pageCount={this.state.totalPage}
                            pageRangeDisplayed={this.state.pageSize}
                            onPageChange={({ selected }) => {
                                // this.setState({ selected: selected }); 
                                    localStorage.setItem("page_no", selected+1)
                                this.handlePageNo({ selected:selected+1, search: this.searchInput })
                            }
                            }
                            containerClassName="pagination-custom"
                            forcePage={this.state.selected} />

                    </div>
                </div>
               
               <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.state.csvConfirmModal} toggle={() => this.toggleCsvConfirm()}> 
                   <ModalHeader style={{backgroundColor:"white"}} > <span style={{ fontSize: "40px", color: `${this.state.csvUploadMsg.error ? "red" : "green"}` }} >{this.state.csvUploadMsg.msg && this.state.csvUploadMsg.msg   }</span> </ModalHeader>
                   <div className="row mt-3 mb-3 ">

                                    <div className="col-md-12 justify-content-center d-flex">
                                       
                                            <button type="button" onClick={this.toggleCsvConfirm} className="btn btn-common width-btn">Ok</button>
                                       
                                    </div>
                    </div>
               </Modal>

                {/*==============================RESERVE A NEW PERMIT=============================================================================  */}

                <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.state.modalReserved} toggle={() => this.toggleReservedPopup({ toggle: true })} className={this.props.className}>
                    <ModalHeader >Reserve a Permit</ModalHeader>
                    <ModalBody>

                        {/* { (this.state.currentPark) ? this.state.currentPark.locationId : "" } */}
                        <Formik
                            key="newPermit"
                            initialValues={{
                                company: (this.state.currentPark) ? this.state.currentPark.company : "",
                                locationId: (this.state.currentPark) ? this.state.currentPark.locationId : "",
                                licenseNumber: "",
                                model: "",
                                make: "",
                                reservedType: permitTypeOptions[0],
                                reservedVal:APP_CONST.HOURS[0],
                                phone: "",
                                email: "",
                                year: options[0]
                            }}

                            validationSchema={Yup.object().shape({

                                locationId: Yup.string().required("Please select a location"),

                                licenseNumber: Yup.string()
                                    .required("Please enter licence number"),

                                model: Yup.string()
                                    .required("Please enter model number"),

                                make: Yup.string()
                                    .required("Please enter make value"),

                                reservedType: Yup.object().shape({
                                    value: Yup.string(),
                                    label: Yup.string()
                                }).nullable(true).required("Please select permit type i.e. Hourly, Daily, or Monthly"),

                                reservedVal: Yup.object().shape({
                                    value: Yup.string(),
                                    label: Yup.string()
                                }).when(
                                    "reservedType",
                                    {
                                        is: (reservedType) => (reservedType && (reservedType.value === "2" || reservedType.value === "4" || reservedType === null)),
                                        then: Yup.object().nullable(true),
                                        otherwise: Yup.object().shape({
                                            value: Yup.string(),
                                            label: Yup.string()
                                        }).nullable(true).required("Please select options")
                                    }),

                                phone: Yup.string().required("Phone no. is required")
                                    .matches(/^\d+$/, "Only numbers are allowed")
                                    .min(10, VALIDATE.PHONE_MIN)
                                    .max(15, VALIDATE.PHONE_MAX),

                                email: Yup.string()
                                    .email(VALIDATE.EMAIL_INVALID)
                                    .required(VALIDATE.EMAIL_REQ),
                                year: Yup.object().shape({
                                    value: Yup.string(),
                                    label: Yup.string()
                                }).nullable(true).required("Please select a Year"),
                                state: Yup.string().required("Enter state name").max(255, "You reached the limit of characters"),
                            })}
                            onSubmit={this.handleNewPSubmit}
                            render={({ values, handleChange, handleBlur, errors, setFieldValue, touched, isValid, isSubmitting }) =>
                                (<Form>

                                    <div className="popup-form">
                                        <div className="row  mb-2">
                                            <span style={{ fontSize: "12px", color: "red" }} >{this.state.newPermit.error && this.state.newPermit.error}</span>
                                            <span style={{ fontSize: "12px", color: "green" }} >{this.state.newPermit.success && this.state.newPermit.success}</span>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="mr-2">
                                                        Phone
                                            </label>
                                                    <input type="text" name="phone" value={values.phone} onBlur={handleBlur} onChange={handleChange} className="form-control " />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.phone && touched.phone && errors.phone}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="mr-2">
                                                        Email
                                            </label>
                                                    <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.email && touched.email && errors.email}</span>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row pt-2">
                                            <div className="col-md-6">
                                                <div className="input-group pt-2">
                                                    <label className="mr-2">
                                                        Parking Lot
                                                </label>
                                                    <div className="input-box-inputcls">
                                                        <input type="text" name="locationId" defaultValue={values.locationId} style={{ display: "none" }} className="selectlocation" />
                                                        <input type="text" name="company" value={values.company} disabled={true} className="selectlocation" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group">
                                                    <label className="mr-2">
                                                        Permit Type
                                                </label>
                                                    {/* <input type="text" className="form-control form-width" /> */}
                                                    <div className="select-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.reservedType}
                                                            onChange={handleChange}
                                                            options={permitTypeOptions}
                                                            className="selectlocation"
                                                            name="reservedType"
                                                            isSearchable={false}
                                                            onBlur={handleBlur}
                                                            onChange={(item) => {
                                                                this.handleOptionChange(setFieldValue, item, "reservedType")
                                                                console.log(item)
                                                                if (item.value !== "1") {
                                                                    this.handleOptionChange(setFieldValue, null, "reservedVal")
                                                                } else {

                                                                    // setFieldError("reservedVal","Please select hours")
                                                                    this.handleOptionChange(setFieldValue, null, "reservedVal")
                                                                }
                                                            }
                                                            }
                                                            placeholder={"Select a permit type"}
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.reservedType && touched.reservedType && errors.reservedType}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>


                                        <div className="row pt-2">

                                             <Col md="6">
                                                    <InputGroup class="pt-2">

                                                        <label class="mr-2">Reserved </label>
                                                        <div className="input-box-inputcls filters-dropdown">
                                                            <Select
                                                                value={values.reservedVal}
                                                                onChange={handleChange}
                                                                options={values.reservedType && values.reservedType.value === '1' ? APP_CONST.HOURS : values.reservedType && values.reservedType.value === '3' ? APP_CONST.months : []}
                                                                className="selectlocation"
                                                                name="reservedVal"
                                                                isSearchable={false}
                                                                isDisabled={(values.reservedType && (values.reservedType.value === "2" || values.reservedType.value === '4'))}
                                                                onBlur={handleBlur}
                                                                onChange={(item) => this.handleOptionChange(setFieldValue, item, "reservedVal")}

                                                                placeholder={values.reservedType && values.reservedType.value === '1' ? "Select hours" : values.reservedType && values.reservedType.value === '3' ? "Select month" : 'daily'}
                                                            />
                                                            <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.reservedVal && errors.reservedVal && errors.reservedVal}</span>
                                                        </div>
                                                    </InputGroup>
                                                </Col>
                                            <div className="col-md-6">
                                                <div className="input-group pt-2">
                                                    <label className="mr-2">
                                                        Plate #
                                                </label>
                                                    <input name="licenseNumber" value={values.licenseNumber} onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.licenseNumber && touched.licenseNumber && errors.licenseNumber}</span>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="row pt-2">
                                            <div className="col-md-6">
                                                <div className="input-group pt-2">
                                                    <label className="mr-2">
                                                        Make
                                            </label>
                                                    <input type="text" name="make" value={values.make} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.make && touched.make && errors.make}</span>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input-group pt-2">
                                                    <label className="mr-2">
                                                        Model
                                            </label>
                                                    <input type="text" name="model" value={values.model} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.model && touched.model && errors.model}</span>
                                                </div>
                                            </div>
                                        </div>

                                        <Row>
                                            <Col md="6 pt-2">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Years</label>
                                                    <div className="input-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.year}
                                                            onChange={(item) => this.handleOptionChange(setFieldValue, item, "year")}
                                                            options={options}
                                                            className="selectlocation"
                                                            name="year"
                                                            onBlur={handleBlur}
                                                            minMenuHeight={10}
                                                            placeholder={"Select a Year"}
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.year && errors.year && errors.year}</span>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                                <Col md="6">
                                                    <InputGroup class="pt-2">
                                                        <label class="mr-2">State</label>
                                                        <input name="state" value={values.state} onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" autoComplete="false" />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.state && errors.state }</span>
                                                    </InputGroup>
                                                </Col>
                                        </Row>
                                        <div className="row mt-3 mb-3">

                                            <div className="col-sm-4 offset-sm-4 col-12">
                                                <div className="row align-items-center justify-content-center">
                                                    <button disabled={isSubmitting} type="submit" class="btn btn-common save-btn"> {(isSubmitting)? "Submitting...": "Save"}</button>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {JSON.stringify(values)} */}

                                    </div>
                                </Form>)}
                        />
                    </ModalBody>

                </Modal>

                <ParkingLotSuspendModal
                 { ...this.state.suspendParkingLotModal}
                 toggleSuspendParkingLot={this.toggleSuspendParkingLot}
                 suspendParkingLot={this.suspendParkingLot}
                />

                {/*===========================================================END=================================================================================  */}
               
            </React.Fragment>
        );
    }
}

ParkingLotList.propTypes = {
    parkingLots: PropTypes.arrayOf(PropTypes.object)
};

export default (ParkingLotList = withRouter(
    connect(
        mapStateToProps,
        mapDipatchToProps,
        null, { pure: false }
    )(ParkingLotList)
));