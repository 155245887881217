import { ACTIONS } from "../../Constants/Actions";



export const ChatUsersActions = ({ result, error })=>{

   return {
     type: (error)? (ACTIONS.CHAT.USERS_LIST.USERS_LIST_FAILED) : (ACTIONS.CHAT.USERS_LIST.USERS_LIST_SUCCESS),
     payload:{ result, error }
   }
}