import { ACTIONS } from "../../Constants/Actions";


export const LotAdminListAction= ({ result, error })=>{

    return {
        type: (error) ? ACTIONS.LOT_ADMIN_LIST.ADMIN_LIST_FAILED : ACTIONS.LOT_ADMIN_LIST.ADMIN_LIST_SUCCESS,
        payload: { 
            // list: result.data,
            // total: result.total,
            result : result,
            error: error }
        }

}