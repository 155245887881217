import { ACTIONS } from "../../Constants/Actions";


export const forgotPassword = ( state = {} , action = {} )=>{

    /**
     * forgotPassword: {
                            errors: {},
                            result:{}
                        }
     */

    switch(action.type){

        case ACTIONS.ADMIN_ACTION.FORGOT_PASSWORD: {

                let newState = { ...{}, ...state };

                let { result , error } = action.payload;

                newState.result = result;
                
                newState.errors = error;

                return newState

        }
        default : {
            return state;

        }
    }


}