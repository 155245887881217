import { ACTIONS } from "../../Constants/Actions";


export const lotaAdminInviteAction=( { result, error  } )=>{


    return {
        type: (error)? ACTIONS.ADMIN_INVITE.INVITE_FAILED : ACTIONS.ADMIN_INVITE.INVITE_SUCCESS,
        payload:{ result, error}
    }
}