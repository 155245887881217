import React,{ Component  } from 'react';
import Select from 'react-select';


export const RefundParkingDropdown =({handleChange,setFieldValue, value, name, handleBlur, options})=>{

    
      return  <>
                        <Select
                            value={value}
                            onChange={(item) => {handleChange(setFieldValue,name, item)}}
                            options={options}
                            className=""
                            name={name}
                            onBlur={handleBlur}
                            minMenuHeight={10}
                            placeholder={"Select a location"}
                        />
            </>
  
}