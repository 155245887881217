import _callApi from "../../Services/baseService";

export const SetAuthTokenApi = ()=>{

    return ()=>{


    let jwt = localStorage.getItem("jwtToken");

    let adminDetails = JSON.parse(localStorage.getItem("adminDetail"))

    // console.log(adminDetails)

    let userID = null;

    if(adminDetails){

        userID = adminDetails.userID;
    

                let form2 = new FormData()
                
                form2.append("params[userID]",  userID)
               return _callApi(form2, "_generateAdminToken_.json",1,{}).then(res=>{
                    
                       let  { error, result } = res.data.payload
                    
                    // console.log(res)

                    
                    
                    localStorage.setItem("jwtToken", result )

                    let currentLocation = localStorage.getItem("currentLocation")

                    // console.log("middleware ",currentLocation)
                    if(currentLocation){

                        window.location= currentLocation
                    }
                })
    }}
}