import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Collapse, CardBody, Card } from 'reactstrap';
import { APP_CONST } from '../../Constants/AppConstants';


class ParkingLots extends Component {
    constructor(props) {
        super(props);
        this.state = {

            modalReserved: false,
            isChecked: props.isChecked || false,
            collapse: false
        };

        this.toggleEvent = this.toggleEvent.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.toggleReservedPopup = this.toggleReservedPopup.bind(this);
    }


    componentWillMount()
    {
        let  { pathname } = this.props.location;
       
       localStorage.setItem("currentLocation", pathname)

       console.log(pathname)
    }


    toggleReservedPopup() {
        this.setState({
            modalReserved: !this.state.modalReserved
        });
    }


    // check toggle btn
    handleChange() {
        this.setState({ isChecked: !this.state.isChecked })
    }

    // collapse events
    toggleEvent() {
        this.setState({ collapse: !this.state.collapse });
    }

    render() {
        return (
            <React.Fragment>
                <div className="row">

                    <div className="header-panel common-padding-left-right">
                        <div className="search-box">
                            <input type="text" className=" form-control search-field" placeholder="Search" name="search" />
                            <i className="fa fa-search search-icon" />
                        </div>
                        <div className="btn-csv">
                            <ul>
                                <li>
                                    <button className="btn btn-common" onClick={this.toggleParkingLots}>
                                        Add New
                                        </button>
                                </li>
                                <li>
                                    <button className="btn btn-common">
                                        Import CSV
                                        </button>
                                </li>
                                <li>
                                    <button className="btn btn-common">
                                        Import CSV
                                        </button>
                                </li>
                            </ul>
                        </div>
                    </div>



                    <div className="table-listing common-padding-left-right">
                        <table>
                            <thead>
                                <tr className="head margin-row-zero">
                                    <td></td>
                                    <td>Company</td>
                                    <td>Address</td>
                                    <td>Hourlty</td>
                                    <td>Daily</td>
                                    <td>Monthly</td>
                                    <td># Available</td>
                                    <td>Contact Name</td>
                                    <td>Phone</td>
                                    <td></td>
                                </tr>
                            </thead>

                            <tbody>
                                <tr class='solid margin-row-zero'>
                                    <td><i className="fa fa-edit" ></i></td>
                                    <td>Empire State Apartments</td>
                                    <td>Empire State Apartments</td>
                                    <td>$0.30</td>
                                    <td>$0.30</td>
                                    <td>$0.30</td>
                                    <td>20 &nbsp; &nbsp; <span className="reserved" onClick={this.toggleReservedPopup}>Reserved</span></td>
                                    <td> John Doe</td>
                                    <td> (123) 456-7890</td>
                                    <td><span onClick={this.toggleEvent}>EVENTS <img className="event-icon" src={ APP_CONST.IMG_LOC+"/ipermit/assets/images/plus.png"} width="20px" /></span></td>

                                </tr>
                                <div className="">
                                    <div className="collapse-margin">
                                        <Collapse isOpen={this.state.collapse}>
                                            <Card>
                                                <table>
                                                    <tr class="solid-collapse">
                                                        <td></td>
                                                        <td>SURGE EVENTS</td>
                                                        <td><span>12/12/18</span>&nbsp; &nbsp;To &nbsp;&nbsp; <span>12/12/18</span></td>
                                                        <td>$0.30</td>
                                                        <td>$0.30</td>
                                                        <td>$0.30</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>

                                                    </tr>
                                                </table>
                                            </Card>
                                        </Collapse>
                                    </div>
                                </div>
                                <tr class='solid'>
                                    <td><i className="fa fa-edit"></i></td>
                                    <td>Empire State Apartments</td>
                                    <td>Empire State Apartments</td>
                                    <td>$0.30</td>
                                    <td>$0.30</td>
                                    <td>$0.30</td>
                                    <td>20 &nbsp; &nbsp; <span className="reserved">Reserved</span></td>
                                    <td> John Doe</td>
                                    <td> (123) 456-7890</td>
                                    <td>EVENTS <img className="event-icon" src={ APP_CONST.IMG_LOC+ "/ipermit/ipermit/assets/images/plus.png"} width="20px" /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>







                <div>

                    <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.state.modalReserved} toggle={this.toggleReservedPopup} className={this.props.className}>
                        <ModalHeader >Reserve a Permit</ModalHeader>
                        <ModalBody>
                            <div className="popup-form">
                                <div className="row mb-2">
                                    <div className="label-class">
                                        Name
                                </div>
                                    <div className="input-box-class">
                                        <input type="text" name="company" className="form-control" />
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="label-class">
                                        Parking Lot
                                </div>
                                    <div className="input-box-class">
                                        <input type="text" name="company" className="form-control" />
                                    </div>
                                </div>

                                <div className="row  mb-2">
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Permit Type
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Plate #
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mb-2">
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Reserved
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Expires
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                </div>


                                <div className="row mb-2">
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Make
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row">
                                            <label className="label-label">
                                                Modal
                                            </label>
                                            <input type="text" className="form-control form-width" />
                                        </div>
                                    </div>
                                </div>

                                <div className="row mt-3 mb-3">

                                    <div className="col-4 offset-md-4">
                                        <div className="row align-items-center justify-content-center">
                                            <button class="btn btn-common save-btn">Save</button>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </ModalBody>

                    </Modal>
                </div>


            </React.Fragment>
        );
    }
}

export default ParkingLots;