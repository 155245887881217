import { ACTIONS } from "../../Constants/Actions";


export const driverListReducer = (state={}, action={})=>{

    /**
     * driverList:{
                    listByIds:{},
                    total:null,
                    error:{}        
                }
     */
    switch(action.type){

        case ACTIONS.DRIVER_LIST.DRIVER_LIST_SUCCESS:{

            let { error, result } = action.payload;

            let { data, total } = result;

            // console.log(error, result)
            let newState = {...{}, ...state}

            newState.listByIds = {}

            data.forEach((item,index)=>{

                newState.listByIds[item.userID] = item
            })

            newState.total = total

            newState.error = error


            return newState
        }
        case ACTIONS.DRIVER_LIST.DRIVER_LIST_FAILED:{

            let { error, result } = action.payload;

            let newState = {...{}, ...state}

            newState.listByIds = result

            newState.error = error

            return newState;

        }
        default:{

            return state;
        }
    }
}