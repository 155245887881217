import { ACTIONS } from "../../Constants/Actions";


export const permitListReducer = (state = { }, action = { }) =>{

    /**
 *                       listByIds:{},
                            ids:[],
                            error:{},
                            total:null,
                            selectedPermit:{}
     */
    switch(action.type){

        case ACTIONS.PERMIT_LIST.PERMIT_LIST_SUCCESS:{

            let newState = { ...{ }, ...state }

            let { result, error } = action.payload

            // newState.listByIds = result.data

            newState.listByIds = {};
            // console.log(data)
            result.data.map((item, index)=>{

                if(!newState.listByIds[item.reserveLocationID] ){

                    newState.listByIds[item.reserveLocationID] = item
                    newState.ids.push(+item.reserveLocationID)
                }
            })

            newState.error = error;

            newState.total = +result.total

            // console.log(newState)

            newState.isFetching = false

            return newState;

        }

        case ACTIONS.PERMIT_LIST.PERMIT_LIST_FAILED:{

             let {  error } = action.payload

             let copy = { ...{}, ...state }

             copy.total = 0;

             copy.data = []

             copy.error = error

             copy.isFetching = false

             return copy
             
        }

        case ACTIONS.PERMIT_LIST.PERMIT_LIST_FETCHING:{

            let newState = {...{}, ...state }

            newState.isFetching = true
            // newState.total = 0
            return newState;
        }

        case ACTIONS.PERMIT_LIST.GETS_PERMIT:{

            let { id } = action.payload;

            let newState = { ...{}, ...state };

            newState.selectedPermit = state[id];

            return newState
        }

        default : {
            return state;
        }
    }

}