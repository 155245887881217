import _callApi from "../../Services/baseService";
import { ACTIONS } from "../../Constants/Actions";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { lotaAdminInviteAction } from "../actions/lotAdminInviteAction";



export const lotAdminInvite= ({ form })=>{


    let token = localStorage.getItem("jwtToken");

        form.append("params[authToken]",token)

    return  ( dispatch, getStore )=>{

       return _callApi(
            form,
            END_POINT.LOTADMIN_INVITE.END_POINT,
            END_POINT.LOTADMIN_INVITE.POST,
            {}).then((res)=>{

                console.log(res)
                let { result=null, error=null } = res.data.payload 

                // if(!error){

                    dispatch(lotaAdminInviteAction({result,error}))

                // }
                // console.log(res)

                return res
            })
    }
}