import { ACTIONS } from "../../Constants/Actions";


export default (state = {}, action={}) => {

    /**
     * listByIds:{},
        total:null,
        error:null
     */

  switch (action.type) {

  case ACTIONS.REFUND_LIST.REFUND_LIST_SUCCESS:{


       let newState = { ...{}, ...state }
       let { result ,error } = action.payload
       let obj = {}


    //    newState.listByIds = {};


       if(result){

          result.data.map((item)=>{


            obj[item.refundRequestID] = item

            return item;
        })

        newState.listByIds = obj;
        newState.total = +result.total

        newState.error = null
       
        return newState
    }

       
    return { ...state }

        }
    
    case ACTIONS.REFUND_LIST.REFUND_LIST_FAILED:{

        let newState = { ...{}, ...state }
       let { result ,error } = action.payload
    //    let obj = {}

    newState.listByIds = {};
    newState.total = null
    newState.error = error.message;

    return { ...newState };

    }

  default:
    return state
  }
}
