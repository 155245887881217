import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik } from 'formik';
import  Cookies from 'universal-cookie';
import { connect } from 'react-redux'
import { LoginApiCall } from "../../store/api-actions/LoginApiCall";
import ReactLoading from 'react-loading';
import _ from 'lodash';
import logo from '../../assets/images/logo.png'

function mapPropsToDispatch(dispatch) {

    return {
        login: (form, remember, usertype) => dispatch(LoginApiCall(form, remember,usertype))
    }
}

function mapPropsToState(state) {

    
    return {
        loggedIn: state.login.logging,
        errors: state.login.errors
    }
}


class MasterLogin extends Component {


    // static propTypes = {
    //     cookies: PropTypes.instanceOf(Cookies).isRequired
    //   };

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            redirect: false,
            admin: {
                email:"",
                password:"",
                remember: false
            }
        }
    }

    handleSubmit(values, formikBeg) {

        let { email, password, remember } = values
// =========================DEFINING USERTYPE OF 1 i.e. AdminLogin
        let userType = 3;
//=================================SETTING FORMIK FORM STATUS=================================//
        formikBeg.setSubmitting(true)
//=================================SETTING FORMIK FORM STATUS END=================================//
        // let cookie = new Cookies()
        const cookies  = new Cookies();

        let form = new FormData()
        form.append("params[userType]",userType)
        form.append("params[email]", email)
        form.append("params[password]", password)
        
        this.props.login(form, remember, userType).then((res) => {

            let { error } = res

            if (!error) {

                if(remember){
                    
                    let d = new Date()

                    d.setTime( +d + 24*30*60*60*1000)
                    cookies.set("email",email, {path: "/ipermit/masterLogin", expires: d})
                    cookies.set("password", password, {path:"/ipermit/masterLogin", expires:d})
                    cookies.set("remember", remember, {path:"/ipermit/masterLogin", expires:d}) 
        
                }
               else {
                    let d = new Date()                    
                    // cookies.remove
                    // cookies.remove("email", {path:"/"})
                    cookies.remove("email", {path:"/ipermit/masterLogin",  maxAge:d.getTime()-24*60*60*1000})
                    cookies.remove("password",{path:"/ipermit/masterLogin", maxAge:d.getTime()-24*60*60*1000})
                    cookies.remove("remember", {path:"/ipermit/masterLogin", maxAge:d.getTime()-24*60*60*1000})
        
        
                }
                formikBeg.setSubmitting(false)

                this.props.history.push("/main");
            }
            else {

                formikBeg.setSubmitting(false)
            }
        })
    }

    componentDidMount(){
        
       

        if(localStorage.getItem("jwtToken")){


            this.props.history.push("/main")
        }
       setTimeout(()=>{ 

        const cookies  = new Cookies()
           
        let  obj =  cookies.getAll({doNotParse:false})
        let json = cookies.getAll()
       
      if(obj.remember){
        if(obj.email && !_.has( obj.email, "path") && obj.password && obj.remember){

            let admin = this.state.admin

            let { email, password, remember } = obj

            admin.email = email
            admin.password = password
            admin.remember = remember

            this.setState((preState)=>{
                return {...preState, admin }
            })
        }
        }
    },1000)
    

    }
    
    render() {

        return (

            <React.Fragment>
                <div className="login">
                    <div className="login-wrapper">
                        <Formik

                        
                            initialValues={
                                this.state.admin
                            }
                           
                            validateOnBlur={true}
                            onSubmit={this.handleSubmit}
                            render={({ errors, values, handleChange, handleSubmit, handleBlur, isSubmitting, isValid, submitCount, touched, validateOnBlur}) => (

                                <form onSubmit={handleSubmit} >
                            

                                    <div className="image">
                                        <img src={logo} />
                                    </div>
                                    <div className="login-form">
                                        {/* 
//===========================================REDUX WILL SEND ERROR MESSAGE FROM BACKEND WITH 'message' PROPERTY IN this.props.errors OBJECT ==============================// 
                                        */}
                                        <span style={{ fontSize: "12px", color: "red" }} >{  this.props.errors && this.props.errors.message}</span>

                                        <div className="form-group">
                                            <input 
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                            name="email"
                                            type="text"
                                            placeholder="Email"
                                            className="form-control"
                                            autoComplete="false"
                                            // validateOnBlur={true} 
                                            />
                                        </div>
                                        <span style={{ fontSize: "12px", color: "red" }} >
                                            { touched.email && errors.email && errors.email}
                                        </span>
                                        <div className="form-group">
                                            <input
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                type="password"
                                                placeholder="Password"
                                                className="form-control"
                                                autoComplete="false"
                                                // validateOnBlur={true}
                                                />
                                        </div>
                                        <span style={{ fontSize: "12px", color: "red" }} >{ touched.password && errors.password && errors.password}</span>

                                    </div>

                                    <div className="remember-fogot">
                                        <div className="remember">
                                            <div className="">
                                                <input
                                                name="remember"
                                                type="checkbox"
                                                checked={values.remember}
                                                id="test1" 
                                                onChange={handleChange}
                                                autoComplete={false}
                                                />
                                                <label htmlFor="test1"></label>
                                            </div>
                                            <span>Remember me</span>
                                        </div>
                                        <div className="forgot">
                                            <Link to="/forgot-password">
                                                <span>Forgot your password?</span>
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="common-save-btn mt-4">
                                        {/* <Link to="/dashboard"> */}

                                        <button disabled={isSubmitting} type="submit" className="btn credential-btn">
                                            {
                                                (isSubmitting) ?
                                                // <div className="loder-right">
                                                // </div>
                                                <ReactLoading
                                                className="btn-loader"
                                                type="balls"
                                                color="white"
                                                height={"auto"}
                                                width={'10%'} />
                                                : "Log In"} 
                                                     </button>

                                                
                                        {/* </Link> */}
                                    </div>
                                </form>

                            )}


                        />
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

// export default MasterLogin = withCookies( withRouter(connect(mapPropsToState, mapPropsToDispatch)(MasterLogin)));
export default MasterLogin = withRouter(connect(mapPropsToState, mapPropsToDispatch)(MasterLogin));