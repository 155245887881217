import React, { Component } from 'react'
import { NavLink, Link, Redirect, Route, withRouter } from 'react-router-dom';

import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,

} from 'reactstrap';
import { APP_CONST } from '../Constants/AppConstants';
// import logo from '/logo.png';
import logo from '../assets/images/logo.png';


class DefaultHeader extends Component {
    constructor(props) {
        super(props)

        this.toggleMenu = this.toggleMenu.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggleMenu() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout = () => {


        //   let userType = localStorage.getItem("")  
        let userType = localStorage.getItem("userType");
        if (+userType === 3) {

            localStorage.clear()

            this.props.history.push("/masterLogin")

        }
        else if (+userType === 2) {

            localStorage.clear()

            this.props.history.push("/adminLogin")
        }
    }

    componentWillMount() {

    }

    render() {

        let userType = localStorage.getItem("userType");

        // console.log(logo)

        return (
            <React.Fragment>
                <div className="menu">
                    <Navbar light expand="md">
                        <NavbarBrand >
                            <Link to="/main" >
                                <img className="logo-size" src={logo} alt="Ipermit Logo" />
                            </Link>
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggleMenu} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            <Nav className="" navbar>
                                <NavItem>
                                    <NavLink exact={true} to="/main">Dashboard</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink strict={true} to={`${this.props.match.path}/parking-lot-list`}>Parking Lots</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink strict={true} to={`${this.props.match.path}/permits`}>Permits</NavLink>
                                </NavItem>
                               {(+userType ===3)?
                                <>
                                    <NavItem>
                                        <NavLink strict={true} to={`${this.props.match.path}/admins`}>Admins</NavLink>
                                    </NavItem>
                                </>
                                    : null}
                                    <NavItem>
                                        <NavLink strict={true} to={`${this.props.match.path}/drivers`}>Enforcement</NavLink>
                                    </NavItem>
                            
                            
                                <NavItem>
                                    <NavLink strict={true} to={`${this.props.match.path}/messaging`}>Messaging</NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink strict={true} to={`${this.props.match.path}/refund-list`}>Refund List</NavLink>
                                </NavItem>
                                
                                <NavItem>
                                    <a href="javascript:void(0)" onClick={this.logout}>LogOut</a>
                                </NavItem>
                                {/* <button onClick={this.logout} >
                                    Log Out
                                </button> */}
                            </Nav>
                        </Collapse>
                    </Navbar>
                </div>

                <Route path="redirect" render={() => <Redirect to="/masterLogin" />} />
            </React.Fragment>
        )
    }
}

export default DefaultHeader = withRouter(DefaultHeader);