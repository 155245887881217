import  { createStore, applyMiddleware, compose } from 'redux'
import { reducers } from "./reducers/index";
import thunkMiddleware from 'redux-thunk'
import  { createLogger } from 'redux-logger'
import _callApi from '../Services/baseService';

let initialState = 
                    {
                        parkList:{ 
                            records:[],
                            parkByIDs:[],
                            error:{},
                            isFetching:true,
                            received:false,
                            currentParkLot:{},
                            total:null
                        },
                        login:{
                                details:{
                                    name:'',
                                    email:'',
                                    password:'',
                                    adminID:'',
                                    timeCreated:null,
                                    timeUpdated:null,
                                    jwtToken:'',
                                    remember:false,
                                    type:null
                                },
                                errors:{},
                                logging:null,
                            },
                        forgotPassword: {
                            errors: {},
                            result:{}
                        },
                        adminInvitation:{
                            invitation:{
                                failed:{},
                                success: {}
                            }
                        },
                        adminList:{ 
                                    adminError:null,
                                    adminArray:[],
                                    adminTotal:null
                            },
                        latestReservedPermits:{
                            permitsList:{},
                            permitsListError : {}
                        },
                        permitsList:{
                            listByIds:{},
                            ids:[],
                            error:null,
                            total:null,
                            selectedPermit:{},
                            isFetching:true
                        },
                        dashboardReport:{
                            error:{},
                            success:{}
                        },
                        driverList:{
                            listByIds:{},
                            total:null,
                            error:null
                        },
                        chat:{
                            users:{
                                listByIds: {},
                                error: null
                            },
                            chatList:{
                                // messages:{},
                                listByIds:{},
                                error:null,
                                isTop:null
                            }
                        },
                        refund:{
                            listByIds:{},
                            total:null,
                            error:null
                        }
                        
                    }

let logger =  createLogger()
let checkLocalStorage =  store=>next=>action=>{

    let jwt = localStorage.getItem("jwtToken");

    let adminDetails = JSON.parse(localStorage.getItem("adminDetail"))

        // console.log("environment ", process);
        
    let userID = null;

    if(adminDetails){

        userID = adminDetails.userID;
    }

    let form = new FormData()

    if(jwt){
        
        form.append("params[authToken]", jwt);

        _callApi(form, "_Auth_.json", 1,{}).then(res=>{

            console.log("error" + JSON.stringify(res));
            
            let { error = null } = res.data.payload != null ? res.data.payload : {}

            if(error != null && error === 401){

                let form2 = new FormData()
                
                form2.append("params[userID]",  userID)
                _callApi(form2, "_generateAdminToken_.json",1,{}).then(res=>{
                    
                       let  { error, result } = res.data.payload
                    
                    
                    localStorage.setItem("jwtToken", result )

                    let currentLocation = localStorage.getItem("currentLocation")

                    if(currentLocation){

                        window.location= currentLocation
                    }
                })
            }
            else {
                
            }
        })


    }

    return next(action)
} 

export const store = createStore(reducers, initialState,
                    compose( applyMiddleware(
                        checkLocalStorage,
                        thunkMiddleware, 
                        // logger
                        ), window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__() : f => f) )