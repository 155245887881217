import _callApi from "../../Services/baseService";
import AdminLoggedIn from "../actions/AdminLoggedIn";
import AdminLogging from "../actions/AdminLogging";
import LogInFailure from "../actions/LogInFailure";
export const  LoginApiCall=(form, remember, userType)=>{


    return (dispatch, state)=>{

           dispatch(AdminLogging(true))

         return _callApi(form,"adminLogin.json",1,{}).then((res)=>{

               console.log(res)
             let  { error, result } = res.data.payload;
            //  let {status} = res
            if(result){
       
                console.log("started login")

                localStorage.setItem("userType", result.userType);

                localStorage.setItem("jwtToken", result.jwtToken)

                let adminDetails = JSON.parse(JSON.stringify(result))

                  adminDetails = JSON.stringify({...adminDetails,password:"", remember: remember});

                localStorage.setItem("adminDetail", adminDetails)

                dispatch(AdminLoggedIn({ status: false, remember: remember, userType: userType}, { result:result, errors: error}))
                console.log("stopped login")

                return res.data.payload
            }
            //    dispatch(AdminLogin(false))
            else if(error){

                dispatch(LogInFailure(error))

                return res.data.payload
            }
          })
    }
}