import React, { Component } from 'react';
import { connect } from 'react-redux'
import { InputGroup } from 'reactstrap';
import chat_before from "../../assets/images/chat-before.png"
import { ChatUsersListApi } from '../../store/api-actions/ChatUsersListApi';
import ChatPersons from './messagingComponent/ChatPerson';
import ChatHistory from './messagingComponent/ChatHistory';
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import { ChatListAPI } from '../../store/api-actions/ChatListAPI';
import { ACTIONS } from '../../Constants/Actions';
import Kumulos from '@kumulos/web';

(async function(){
    // const kumulosClient = await new Kumulos({
    //     apiKey: '981ac0ef-3940-4d9c-a812-b5c99a0b1463',
    //     secretKey: 'Qmw0A/bjWJX8Dou5tf2P/8BTRHSiQ2gcdPMV',
    //     vapidPublicKey: 'BIhozs9CuyxgE5QUtuzQh-EaLdEpQhj7FzyA_jtvmk0Gh0iNc-tftOKnmY8eADzmwXISFt9lsKd-nNYfQ_n-_VY',
    //     serviceWorkerPath: process.env.PUBLIC_URL + "/worker.js"
    // });
    //live
    const kumulosClient = new Kumulos({
        apiKey: '770d6615-87aa-4195-b330-b3cb892a93ef',
        secretKey: '9IwP7OJZ8yRbMq4PYfI3hk+4EUp7BEbG5whr',
        vapidPublicKey: 'BAD6ICXvynpnR-gND0qWzPLtNWwMTy5BPDghIzWX8U_5EymGx2kVapg6GuZooB1x6NNYSIngQYtBFXdCutlya_s',
        serviceWorkerPath: process.env.PUBLIC_URL + "/worker.js"
    });
    kumulosClient.pushRegister();
})();

const mapDispatchToProps = (dispatch) => {

    return {
        getChatUsers: (form) => dispatch(ChatUsersListApi({ form })),
        getChatList: (form)=>dispatch(ChatListAPI({form})),
        blankChatList: ()=>dispatch({type:ACTIONS.CHAT.GET_CHAT_LIST.CHAT_LIST_SUCCESS,payload:{result: [],isTop:2,error:null}})
    }
}

const mapStateToProps = (state) => {

    return {
        users: state.chat.users.listByIds,
        usersError: state.chat.users.error,
        chatList: state.chat.chatList.listByIds,
        chatError : state.chat.chatList.error,
        isTop:state.chat.chatList.isTop
    }
}


class Messaging extends Component {
    first_time_chat = true
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this._handleKeyPress = this._handleKeyPress.bind(this)
        // this.setIntervalId = null ;
        this.latestTimeItervalId = null

        this.chatHistoryRef = React.createRef();
        this.loading = true;


        this.state = {
            users: [],
            message: '',

// =================THIS IS FOR CURRENTLY SELECTED USER MESSGE HISTORY=====================//
            selectedChatUser: null, // LOCAL STORAGE VALUE 
            currentUserMessages: null,
            toUser: null,
            messages:{},
            first_time_api: true,
// =====================================END================================================//

            messageForEndList:null,
            scrolledDown:true,
// ======================IT IS USED TO STOP SCROLLER =======================================//
            first_time_chat:true,
            last_message_id:null,
            disableSendButton:true,
//================================END==================================================//
            // isTop:2
        }
    }

    componentWillMount(){

        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)
    }

    componentWillUnmount(){

        if(this.latestTimeItervalId){
            clearInterval(this.latestTimeItervalId)
        }

    }

    componentDidMount() {


        this.props.getChatUsers(this.getChatUsersForm())
        let selectedChatUser = localStorage.getItem("selected_chat_user")

        selectedChatUser = JSON.parse(selectedChatUser)

        this.setState({first_time_chat : true})

// console.log(selectedChatUser)
        if (selectedChatUser) {
            
            this.handleSelectedChat(selectedChatUser)
        }
    }

    componentWillReceiveProps(nextProps) {

        let { users, usersError, chatList, isTop, chatError } = nextProps

        let obj = {};
        let set = true;
        
        if(isTop && +isTop === 2){

            if(!_.isEmpty(chatList)){

    
                Object.keys(chatList).forEach((key)=>{
    
                     if(this.state.messages[`${key}`]){
    
                         set = false
                     }else {
                        obj[key] = chatList[key]
                     }
                })
            
            this.setMessages(obj)
                
            if(chatError){
                    
                    
            }else{
                    
                let last100Chats = _.values(chatList)
                if(set){


                    this.setState((preState) => {
                                    
                                return {
                                    messages:chatList,
                                    currentUserMessages : last100Chats,
                                    last_message_id : last100Chats[0]
                                }
                    })
                }else{

                    let previousChats = _.values( this.state.messages)
    
                    // console.log("Previos chat ", previousChats )
                    if(previousChats.length){
    
                        this.setState((preState) => {
                                        
                            return {
                                currentUserMessages: previousChats,
                                last_message_id: previousChats[0],
                                // first_time_chat:false
                            }
                        })
    
                    }
                }

               if(this.state.first_time_chat){
                   
                  setTimeout(()=>this.scrollToMyRef()) 
               } 

            }
        }
            this.loading = false

        }else if ( isTop && +isTop === 1 ){
            
            if(!_.isEmpty(chatList)){
            

            Object.keys(chatList).forEach((key)=>{

                 if(this.state.messages[`${key}`]){

                     set = false
                 }else {
                    obj[key] = chatList[key]
                 }
            })

            this.setMessages(obj)
            
                if(set){


                    let previousChats = _.values( chatList)
    
                    if(previousChats.length){
    
                        this.setState((preState) => {
                                        
                            return {
                                currentUserMessages: _.concat(previousChats, preState.currentUserMessages),
                                last_message_id: previousChats[0],
                                // first_time_chat:false
                            }
                        })

                        setTimeout(()=>{

                            this.scrollToMyLastMessage()
                        })
    
                    }
                }else{



                    let previousChats = _.values( this.state.messages)
    
                    if(previousChats.length){
    
                        this.setState((preState) => {
                                        
                            return {
                                currentUserMessages: previousChats,
                                last_message_id: previousChats[0],
                            }
                        })

                       
    
                    }




                }

            }

            this.loading = false
        }

        let selectedChatUser = localStorage.getItem("selected_chat_user")

              
        
        if( selectedChatUser && this.state.first_time_api  ){
            
                    selectedChatUser = JSON.parse(selectedChatUser)

                    for(let key in users){
            
                        if(users[key].conversationID === selectedChatUser.conversationID){
                            users[key].className = " active-chat"
            
                        }else{
                            users[key].className = " "
                        }         
                    }
        }

        this.setState(() => {
            return { users: users }
        })
    }


    setMessages=(obj)=>{

        this.setState({messages : {...obj, ...this.state.messages}})

    }

    handleSelectedChat = () => {

        this.setState(()=>({ currentUserMessages:null, first_time_chat:true, messages:{}, messageForEndList:null, first_time_api:true}))
        this.loading = true;
// ====================THIS IS TO CLEAR REDUX STATE BECAUSE IF A USER WERE SELECTED FOR THEIR CHAT HISTORY THEN IN THAT CASE REDUX WILL RETAIN PREVIOUS CHATS OF LAST USER SELECTED 
// ======================================================SO TO UPDATE CHATS WE NEE TO CLEAR THE REDUX STATE==========================//
        this.props.blankChatList()
// ===========================================END======================================//
        let selectedChatUser = localStorage.getItem("selected_chat_user")
       
        selectedChatUser = JSON.parse(selectedChatUser)

        if(selectedChatUser){

            let { toUser = null, receiver=null } = selectedChatUser

            if(receiver){

                 toUser = receiver

                 console.log(selectedChatUser)
            }

            // ========================IT IS FOR ADDING A active-chat CLASS =========================//
                    let users = this.state.users//COMPONENT STATE FROM REDUX STATE

                    for(let key in users){

                        if(users[key].conversationID === selectedChatUser.conversationID){

                            users[key].className = " active-chat"

                        }else{
                            users[key].className = " "
                        }   
                    }
                    this.setState(()=>{
                    
                        if(+selectedChatUser.isDeleted === 1){

                            return    { toUser: toUser, users:users, disableSendButton: true,}
                        }
                        else if(+selectedChatUser.isDeleted === 0){

                            return    { toUser: toUser, users:users, disableSendButton: false,}
                        }else{

                           return {toUser: toUser}
                        }
                    })
            // ========================================END=======================================//

           

// ==============================CLEAR PREVIOUS TIME INTERVAL IF THERE IS ANY BECAUSE PREVIOUS TIMER HAS BEEN CALLING WITH OLD ARGUMENTS THAT WE DON'T WANT TO=========//
                        if(this.latestTimeItervalId){

                            clearInterval(this.latestTimeItervalId)
                        }
// =======================================================END========================================================================//

// ================================================CALLING API FOR THE  FIRST TIME============================================//
                this.loadHistory({ form: this.getChatForm({ toUser, isTop:2, messageID:0 }), toUser: toUser, first_time_chat:1, isTop:2 })
// ============================================================END============================================================//
                
// =============================THEN CALL API EVERY 3.5 SECONDS AFTER==========================================//

                this.startTimerOfApi( { form: this.getChatForm({ toUser, isTop:2, messageID:0 }), toUser, isTop:2})
               
// =========================================END=======================================================//
            }

        


    }

    startTimerOfApi=({form, toUser, isTop })=>{

        this.latestTimeItervalId = setInterval(()=>{
     
                this.loadHistory({ form: form, toUser: toUser, isTop:2 })


        },3500)
    }

   
// THIS METHOD WILL BE FOR WHETHER CALL API FOR PREVIOUS CHATS OR NOT 
    handleScroll=(e)=>{

        
        let scrolledTop = this.chatHistoryRef.current.scrollTop 
        let scrollHeight = this.chatHistoryRef.current.scrollHeight 
        let clientHeight = this.chatHistoryRef.current.clientHeight
        let selectedChatUser = localStorage.getItem("selected_chat_user")
        selectedChatUser = JSON.parse(selectedChatUser)
        let etd = Math.round( (scrollHeight - clientHeight)* 0.01)

        
        if( scrolledTop >= etd || scrolledTop <= etd  ){
            
            this.setState(()=>({first_time_chat : false}))
            
            this.first_time_chat = false

        }else{
            this.setState(()=>({first_time_chat : true}))   

            this.first_time_chat =true

        }

// =========================THIS IS FOR IF USER IS NOT SCROLLING FIRST TIME AND IT IS VIEWING PREVIOUS CHAT HISTORY ===============//
// console.log("Scolled ", scrolledTop, etd, this.state.first_time_chat);
            
        if(scrolledTop   ===0 && this.first_time_chat === false ){
                
            let id_before_100xN =null

            this.loading = true;

            if(this.state.last_message_id){

                  id_before_100xN  =    this.state.last_message_id.messageID
            }

            if(this.latestTimeItervalId){
                clearInterval(this.latestTimeItervalId)
            }
        
            if (selectedChatUser) {
    
                // let { toUser} = selectedChatUser
                let { toUser = null, receiver=null } = selectedChatUser

                let messageID = id_before_100xN

                // console.log("Scrolled Top", scrolledTop);


                    if(receiver){

                        toUser = receiver

                        // console.log(selectedChatUser)
                    }
                
                this.loadHistory({ form: this.getChatForm({ toUser, messageID, isTop:1 }), toUser: toUser, isTop: 1 }) // WE ARE SENDING 1 for first_time_chat FOR SHOWING SELECTED USER
                                                                                                            // WE ARE SENDING 1 FOR isTop scroll event so that we can fetch previous chats
                }

            }
// ==========================================END===================================================================================//
             if(scrollHeight - scrolledTop === clientHeight){ // THIS IS FOR IF ELEMENT HAS SCROLLED DOWN COMPLETELY TO ITS MAX HEIGHT 

                // console.log(scrollHeight-scrolledTop ,clientHeight);
            
                let { toUser = null, receiver=null } = selectedChatUser

                        if(receiver){

                            toUser = receiver

                            // console.log(selectedChatUser)
                        }
              

                if(this.latestTimeItervalId){
                            clearInterval(this.latestTimeItervalId)
                }
 
                    this.startTimerOfApi( { form: this.getChatForm({ toUser, isTop:2, messageID:0  }), toUser, isTop:2})
           
                
            }
// ====================IF SCROLLER SCROLLED DOWN COMPLETELY THEN STOP SCROLLER TO BE SCROLLED AUTOMATICALLY ====================//


           
// ==================================================END=======================================================================//
    }

    scrollToMyRef = () => {   // run this method to execute scrolling.
        
// ===============THIS CONDITION IS TO STOP SCROLLER===========//
        if(this.state.first_time_chat === true){

            if(this.chatHistoryRef.current){

                this.chatHistoryRef.current.scrollTo({
                    top:this.chatHistoryRef.current.scrollHeight, 
                    // behavior: "smooth"
                })
            }
        }
// =============================END============================//
    }

    scrollToMyLastMessage = () => {   // run this method to execute scrolling.
        

        // console.log( this.chatHistoryRef.current.children)
        // ===============THIS CONDITION IS TO STOP SCROLLER===========//
                // if(this.state.first_time_chat === true){
        
                //     if(this.chatHistoryRef.current){
        
                //         this.chatHistoryRef.current.scrollTo({
                //             top:this.chatHistoryRef.current.scrollHeight, 
                //             // behavior: "smooth"
                //         })
                //     }
                // }
        // =============================END============================//
            }


    scrollToLatestMsg = () => {   // run this method to execute scrolling.
        
        // console.log("scrolled down")
        // ===============THIS CONDITION IS TO STOP SCROLLER===========//
                if(this.chatHistoryRef.current ){
        
                    this.chatHistoryRef.current.scrollTo({
                        top:this.chatHistoryRef.current.scrollHeight, 
                        behavior: "smooth"}
                        )
                }
        // =============================END============================//
            }

    loadHistory = ({ form, toUser, first_time_chat, isTop  }) => {

        if(isTop === 2){

            this.props.getChatList(form)

      
// // =========================THIS STATE WILL BE PASSED TO CHAT HISTORY COMPONENT====================//
                        this.setState(() => {
                            
                            return {
                                // currentUserMessages: reversedChat,
                                toUser: toUser,
                               
                            }
                        })

        }else if (isTop === 1){
            this.props.getChatList(form)
            

        }
    }

    getChatForm = ({ toUser, messageID, isTop }) => {

        let form = new FormData()

        form.append("params[authToken]", this.getToken())
        if(messageID){

            form.append("params[messageID]", messageID)
        }else{
            form.append("params[messageID]", 0)

        }
        form.append("params[toId]", toUser)
        form.append("params[isTop]", isTop)

        return form
    }

    // ===============================TO GET CHAT USERS LIST FORM DATA=========================//
    getChatUsersForm = () => {

        let form = new FormData()

        form.append("params[authToken]", this.getToken())

        return form
    }
    // ===============================================END========================================//

    
    // =================================FOR SEND INPUT BOX======================================//
    handleSubmit(values) {

        // console.log("MESSAGE IS SENT")
        let { message, messageType, toId } = values
        
        this.props.getChatList(this.getChatForm({ toUser:toId, isTop:2, messageID:0 }))

        
        


        if(message.trim()){

        let form = new FormData()

        form.append("params[message]", message.trim())
        form.append("params[messageType]", messageType)
        form.append("params[toId]",toId)
        form.append("params[authToken]", this.getToken())


            _callApi(form, 
                END_POINT.SEND_CHAT.END_POINT,
                END_POINT.SEND_CHAT.POST).then(res=>{

                    let { result, error } = res.data.payload

                    if(error){

                    }else{

                        let messages = this.state.messages
                        let currentUserMessages = this.state.currentUserMessages

                        currentUserMessages.push(result)

                        messages[result.messageID] = result

                        this.setState((preState)=>{

                           return { messages, currentUserMessages}
                        })

                       setTimeout(()=>{
                        this.scrollToLatestMsg()
                       })  
                    }
                })
        }

    }

    _handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.handleSubmit();
        }
    }

    handleChange(e) {
        this.setState({
            message: e.target.value
        })
    }
    // ============================================END=================================================//


    getToken() {

        return localStorage.getItem("jwtToken");
    }



    render() {
        return (
            <div className="row">
                <div className="messaging">
                    <div className="sidebar">
                        <ChatPersons users={this.state.users} handleScroll={this.handleScroll} handleSelectedChat={this.handleSelectedChat} />
                    </div>

                    {/* IF this.state.currentUserMessges are available then show chathistory otherwise welcome message */}

                    {this.state.currentUserMessages ?
                        <ChatHistory
                            refProp={this.chatHistoryRef}
                            currentUserMessages={this.state.currentUserMessages}
                            toUser={this.state.toUser}
                            handleScroll={this.handleScroll}
                            messageForEndList={this.state.messageForEndList}
                            first_time_chat = {this.state.first_time_chat}
                            loading = {this.loading}
                            messages={this.state.messages}
                        >

                            <Formik
                                key="newPermit"
                                initialValues={{

                                    message: this.state.message,
                                    messageType: 0,
                                    toId: this.state.toUser
                                }}
                                enableReinitialize={true}

                                // onSubmit={this.handleSubmit}
                                render={({ values, handleChange, handleBlur, errors, setFieldValue, touched, setSubmitting, validateField }) =>

                                       <>
                                        <div className="row pt-3">
                                            <div className="col-md-12">
                                                <div className="pb-5">
                                                    <InputGroup className="pl-3">
                                                        <input 
                                                        onKeyPress={(e)=>{

                                                            if(e.key === "Enter" && values.message && !this.state.disableSendButton ){

                                                                setFieldValue("message",""); this.handleSubmit(values )

                                                            }
                                                        }} type="text" name="message" value={values.message} onClick={()=>{this.scrollToLatestMsg()}} onBlur={handleBlur} onChange={handleChange}
                                                            className="form-control border-radus" />
                                                        <button disabled={this.state.disableSendButton} onClick={ ()=>{
                                                            if(this.state.disableSendButton) {

                                                            } else{

                                                            setFieldValue("message",""); this.handleSubmit(values )
                                                        } }}

                                                        type="button" className="btn   save-btnn">Send</button>
                                                    </InputGroup>
                                                </div>
                                            </div>

                                        </div>

                                        <input name="messageType" defaultValue={values.messageType} type="hidden" />
                                        <input name="toId" defaultValue={values.toId} type="hidden" />

                                    </>}
                            />
                        </ChatHistory>
                        :
                        <div className="before-chat">
                            <div className="chat-icon-wrapper">
                                <div className="chat-icon">
                                    <img src={chat_before} />
                                </div>
                                <p>Welcome to iPermit Messaging!</p>
                            </div>

                        </div>
                    }
                    <div className="clearfix">
                    </div>
                </div>
            </div> // row close 
        );
    }
}

export default Messaging = connect(mapStateToProps, mapDispatchToProps)(Messaging);