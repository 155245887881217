

export const APP_CONST={
   HOURS: [
        {value:'1', label:"1"},
        {value:'2', label:"2"},
        {value:'3', label:"3"},
        {value:'4', label:"4"},
        {value:'5', label:"5"},
        {value:'6', label:"6"},
        {value:'7', label:"7"},
        {value:'8', label:"8"},
        {value:'9', label:"9"},
        {value:'10', label:"10"},
        {value:'11', label:"11"},
        {value:'12', label:"12"},
        {value:'13', label:"13"},
        {value:'14', label:"14"},
        {value:'15', label:"15"},
        {value:'16', label:"16"},
        {value:'17', label:"17"},
        {value:'18', label:"18"},
        {value:'19', label:"19"},
        {value:'20', label:"20"},
        {value:'21', label:"21"},
        {value:'22', label:"22"},
        {value:'23', label:"23"},
        {value:'24', label:"24"},
    ],
   IMG_LOC:window.location.origin ,
   months : [
        { value:"1", label:'1 month' },
        { value:"12", label:'1 year' },
        { value:"24", label:'2 years' },
        { value:"36", label:'3 years' },
        { value:"2", label:'2 months' },
        { value:"3", label:'3 months' },
        { value:"4", label:'4 months' },
        { value:"5", label:'5 months' },
        { value:"6", label:'6 months' },
        { value:"7", label:'7 months' },
        { value:"8", label:'8 months' },
        { value:"9", label:'9 months' },
        { value:"10", label:'10 months' },
        { value:"11", label:'11 months' },
        { value:"13", label:'13 months' },
        { value:"14", label:'14 months' },
        { value:"15", label:'15 months' },
        { value:"16", label:'16 months' },
        { value:"17", label:'17 months' },
        { value:"18", label:'18 months' },
        { value:"19", label:'19 months' },
        { value:"20", label:'20 months' },
        { value:"21", label:'21 months' },
        { value:"22", label:'22 months' },
        { value:"23", label:'23 months' },
        { value:"25", label:'25 months' },
        { value:"26", label:'26 months' },
        { value:"27", label:'27 months' },
        { value:"28", label:'28 months' },
        { value:"29", label:'29 months' },
        { value:"30", label:'30 months' },
        { value:"31", label:'31 months' },
        { value:"32", label:'32 months' },
        { value:"33", label:'33 months' },
        { value:"34", label:'34 months' },
        { value:"35", label:'35 months' },
        
    ],
    days: [
        {value:'1', label:"1"},
        {value:'2', label:"2"},
        {value:'3', label:"3"},
        {value:'4', label:"4"},
        {value:'5', label:"5"},
        {value:'6', label:"6"},
        {value:'7', label:"7"},
        {value:'8', label:"8"},
        {value:'9', label:"9"},
        {value:'10', label:"10"},
        {value:'11', label:"11"},
        {value:'12', label:"12"},
        {value:'13', label:"13"},
        {value:'14', label:"14"},
        {value:'15', label:"15"},
        {value:'16', label:"16"},
        {value:'17', label:"17"},
        {value:'18', label:"18"},
        {value:'19', label:"19"},
        {value:'20', label:"20"},
        {value:'21', label:"21"},
        {value:'22', label:"22"},
        {value:'23', label:"23"},
        {value:'24', label:"24"},
        {value:'25', label:"25"},
        {value:'26', label:"26"},
        {value:'27', label:"27"},
        {value:'28', label:"28"},
        {value:'29', label:"29"},
        {value:'30', label:"30"},
    ],
}