export const ACTIONS = {

    PARKLIST : { 
        RECEIVE_PARKLIST : "RECEIVE[PARKLIST]",
        FETCHING_LIST:"FETCHING[PARKLIST]",
        RECEIVED_LIST:"RECEIVED[PARKLIST]",
        GET_PARK_BY_ID:"GET_PARK_BY_ID[PARKLIST]",
        FAILED_PARKLIST:"FAILED_FETCHING[PARKLIST]"
    },
    ADMIN_ACTION : {

        // ADMIN_LOGIN: "LOGIN[ADMIN]",
        ADMIN_LOGGEDIN: "LOGGEDIN[ADMIN]",
        ADMIN_LOGGING: "LOGGING[ADMIN]",
        LOGIN_FAILURE: "FAILURE[LOGIN]",
        FORGOT_PASSWORD : "FORGOT-PASSWORD[ADMIN]"
    },

    ADMIN_INVITE: {

        INVITE_FAILED: "INVITE_FAILURE[ADMIN]",
        INVITE_SUCCESS: "INVITE_SUCCESS[ADMIN]"
    },
    DASHBOARD_REPORT:
    {
        DASHBOARD_REPORT_SUCCESS: "SUCCESS[DREPORT]",
        DASHBOARD_REPORT_FAILED: "FAILED[DREPORT]",
        DASHBOARD_REPORT_FETCHING: "FETCHING[DREPORT]"
    },
    LOT_ADMIN_LIST:{

        ADMIN_LIST_SUCCESS: "SUCCESS[ALIST]",
        ADMIN_LIST_FAILED: "FAILED[ALIST]",
        ADMIN_LIST_FETCHING: "FETCHING[ALIST]"
    },
    LATEST_PERMIT_LIST:{  
        LATEST_PERMIT_LIST_SUCCESS: "SUCCESS[LPLIST]",
        LATEST_PERMIT_LIST_FAILED: "FAILED[LPLIST]",
        LATEST_PERMIT_LIST_FETCHING: "FETCHING[LPLIST]",
    },
    PERMIT_LIST:{
        PERMIT_LIST_SUCCESS: "SUCCESS[PLIST]",
        PERMIT_LIST_FAILED: "FAILED[PLIST]",
        PERMIT_LIST_FETCHING: "FETCHING[PLIST]",
        GETS_PERMIT: "GETS[PERMIT]"
    },
    DRIVER_LIST:{
        DRIVER_LIST_SUCCESS:"SUCCESS[DRIVER_LIST]",
        DRIVER_LIST_FAILED:"FAILED[DRIVER_LIST]",
        DRIVER_LIST_FETCHING:"FETCHING[DRIVER_LIST]"
    },
    CHAT:{
        USERS_LIST:{
            USERS_LIST_FAILED:"FAILED[USERSLIST]",
            USERS_LIST_SUCCESS:"SUCCESS[USERSLIST]",
            USERS_LIST_FETCHING:"FETCHING[USERSLIST]"
        },
        GET_CHAT_LIST:{
            CHAT_LIST_FAILED:"FAILED[CHAT_LIST]",
            CHAT_LIST_SUCCESS:"SUCCESS[CHAT_LIST]",
            CHAT_LIST_FETCHING:"FETCHING[CHAT_LIST]",
        }
    },

        REFUND_LIST:{
            REFUND_LIST_FAILED:"FAILED[REFUND_LIST]",
            REFUND_LIST_SUCCESS:"SUCCESS[REFUND_LIST]",
            REFUND_LIST_FETCHING:"FETCHING[REFUND_LIST]",
        },
        AUTH_DETAILS:{
            UPDATE_AUTH:"UPDATE[AUTH]"
        }
}