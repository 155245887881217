import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import  { Provider } from 'react-redux'
import {store} from "./store";
// import { compose } from 'redux';
import { Provider as AlertProvider } from 'react-alert';
import AlertTemplate from "react-alert-template-basic";
import { CookiesProvider } from 'react-cookie';

ReactDOM.render(

<Provider store= {store} >
        <CookiesProvider  >
                <AlertProvider 
                template={AlertTemplate} 
                position="bottom center" transition="scale" >
                
                                <App />
                </AlertProvider>
        </CookiesProvider>

</Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
