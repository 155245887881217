import { ACTIONS } from "../../Constants/Actions";



function LogInFailure( error ){

    return {

        type:ACTIONS.ADMIN_ACTION.LOGIN_FAILURE,
        payload : {error, logging: false}
    }
}

export default LogInFailure;