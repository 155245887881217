import React, { Component } from 'react'
import { Redirect } from 'react-router-dom';
import DefaultHeader from './DefaultHeader';
import MainContainer from './MainContainer';
import queryString from 'query-string'

class Layout extends Component {
    constructor(props) {
        super(props)
    }

    componentWillUnmount(){}

    render() {

        let token = localStorage.getItem("jwtToken") 

        let userType = localStorage.getItem("userType");


       if( +userType === 3 && !token){

            return <Redirect to="/adminLogin" from="/main" />
        }
        else if( +userType === 2 && !token ){

            return <Redirect to="/adminLogin" from="/main" />
        }
        else if (!userType || !token){

            return <Redirect to="/adminLogin" from="/main" />

        }


        // console.log(this.props)

        return (
            <React.Fragment>
                <div className={(this.props.location.pathname === "/main/messaging")? "header fixed" : "header" }>
                    <div className="header-wrapper">
                        <div>
                            <DefaultHeader {...this.props} />
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <MainContainer {...this.props}  />
                </div>
            </React.Fragment>
        )
    }
}

export default Layout;