import { END_POINT } from "../../Constants/ApiEndPoints";
import { ACTIONS } from "../../Constants/Actions";


export const DriverListAction=({result, error})=>{

    return {
        type:(!error)? ACTIONS.DRIVER_LIST.DRIVER_LIST_SUCCESS : ACTIONS.DRIVER_LIST.DRIVER_LIST_FAILED,
        payload:{result, error} 
    }
}