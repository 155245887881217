import React, { Component } from 'react';
import {  Modal, ModalHeader, ModalBody,  } from 'reactstrap';
import { Formik, Form,  } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select'
import { VALIDATE } from '../../Constants/ValidationConst';

import { connect } from 'react-redux';
import AdminTable from './admin-views/AdminTable';
import AdminFilter from './admin-views/AdminFilter';
import { END_POINT } from '../../Constants/ApiEndPoints';
import _callApi from '../../Services/baseService';
import { lotAdminListApi } from '../../store/api-actions/lotAdminListApi';
import ReactPaginate from 'react-paginate';
import { DeleteAdminConfirmModal } from './admin-views/DeleteAdminConfirmModal';
// import NewAdminModal from './admin-views/NewAdminModal';
import { EditAdminModal } from './admin-views/EditAdminModal';
import { SetAuthTokenApi } from '../../store/api-actions/SetAuthTokenApi';
import { lotAdminInvite } from '../../store/api-actions/lotAdminInvite';
import AdminSuspendModal from './admin-views/AdminSuspendModal';


const mapDispatchToProps=(dispatch)=>{

    return {
        adminList:(form)=>dispatch(lotAdminListApi({form})),
        setAuthToken:()=>dispatch(SetAuthTokenApi()),
        invite: (form) => dispatch(lotAdminInvite({ form })),
    }
}

const mapStateToProps=(state)=>{

    return {

        
        inviteError: state.adminInvitation.invitation.failed,
        inviteSuccess: state.adminInvitation.invitation.success,
        admin: {
                adminArray: state.adminList.adminArray,
                adminTotal: state.adminList.adminTotal,
                adminError: state.adminList.adminError,
            },
    }
}

class Admins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            filter:{
                searchText:"",
                locationId:"",
            },
// =================PAGINATION=========//
            pagination:{
                totalPage:0,
                selectedPage:1,
                pageSize:10
            },
// =====================END============//


            currentAdmin:{
                email: "",
                name: ""
            },

//=============== LISTS================//
            admins:[],
            
            parkingList: {
                options: []
            },

            permitsDropdown: [],
// =================END==============//

// ====================================ERROR MESSAGES======================================//
            addAdminMessage:{
                error:null,
                success:null
            },
            editAdminMessage:{
                error:null,
                success:null
            },
            listAdminMessage:{
                error:null
            },

            inviteError: {},
            inviteSuccess: {},
//==========================================END============================================//

// =============POP UPS===================//
            deleteConfirmModal:false,
            newAdminModal:false,
            editAdminPopup:false,

            suspendAdminModal:{
                adminId:"",
                isOpen:false,
                status:null,
            },
//==================END===================//

// ============ADMIN TO DELETE=============//
            adminToDelete:{
                name:""
            },
// ============END=========================//

// ====================================//
            loading:false
// ===============END==================//
        };
        
    }

    

    toggleDropdown() {
        this.setState(prevState => ({
            dropdownOpen1: !prevState.dropdownOpen1
        }));
    }

    applyFilter=({values, formikBag,form})=>{


        this.setState((preState)=>{
            let pagination = preState.pagination

            pagination.selectedPage = 1;
          return  {loading:true, pagination}
        })

        if(values){

            let { locationId, searchText } = values

            this.setState((preState)=>{

                let filter = preState.filter

                filter.locationId = locationId.value
                filter.searchText = searchText
                return {
                    filter : filter
                }
            })
            // let pageNo = this.state.filter.selectedPage+1
        }

        if(form){

            this.props.adminList(form)
        }

        else {


            let form = new FormData()

            form.append("params[authToken]", this.getToken());
            if(values.searchText && values.searchText.trim()){
                
                form.append("params[searchText]", values.searchText.trim())
            }else{
                form.append("params[searchText]", "")

            }
            form.append("params[locationId]", values.locationId.value)
            form.append("params[page]", 1)


            this.props.adminList(form)

        }


    }

    getUParkingLots = () => {

        let token = localStorage.getItem("jwtToken");

        let form = new FormData()

        form.append("params[authToken]", token)

        _callApi(form,
            END_POINT.UNASSIGNED_PARKING_LOTS.END_POINT,
            END_POINT.UNASSIGNED_PARKING_LOTS.POST, {}
        ).then(res => {

            let { result, error } = res.data.payload

            if (!error) {


                let arr = this.state.permitsDropdown;

                let obj = {}

                result.map((item, index) => {



                    obj.value = item.locationId;
                    obj.label = item.company
                    
                    arr.push(obj)

                    obj={}

                })

                this.setState(()=>({ permitsDropdown: arr }))

            }

        })
    }


    handleSubmit = (values, formikBeg) => {

        let { email, name, uALots, phone, ccEmail } = values;

        let locationIds = []

        let form = new FormData();

        // console.log(values)

        if (uALots.length) {

            uALots.map((item, index) => {

                locationIds.push(item.value)
            })
        }

        locationIds = JSON.stringify(locationIds)

        form.append("params[email]", email)
        form.append("params[name]", name)
        form.append("params[phone]", phone)
        form.append("params[locationId]", locationIds)
        form.append('params[ccEmail]', ccEmail)


        this.props.invite(form).then((res) => {

            let { error, result } = res.data.payload
            
            // console.log(error)

            if (!error) {
                this.getUParkingLots()

                let token = localStorage.getItem("jwtToken");

                let form = new FormData()

                form.append("params[authToken]", token);
                form.append("params[page]", 0);

                this.props.adminList(form).then(res => {

                    this.getUParkingLots()
                    this.toggleNewAdminModal()

                 })

            }
        })

    }

    handlePermitSelect = (method, value) => {

        method("uALots", value)
    }

    handleNewAdmin=(values, formikBag)=>{

        // console.log(values)

        this.setState({loading:true})
        let form = new FormData()

        Object.keys(values).forEach((key)=>{
           if(key !== "confirmPass"){

               form.append(`params[${key}]`,values[key])
           } 
        })

        form.append("params[authToken]", this.getToken());
        

        _callApi(form,END_POINT.ADD_Admin.END_POINT,END_POINT.ADD_Admin.POST,{}).then(res=>{

            let { result= null, error= null } = res.data.payload

            let addAdminMessage = this.state.addAdminMessage

            if(error == 401){
                this.props.setAuthToken()
            }else if(!error){


                addAdminMessage.success = "Successfully Added!"
                addAdminMessage.error = null
                this.setState((preState)=>{

                    return {
                        addAdminMessage: addAdminMessage
                    }
                })

                let form = new FormData()

                // form.append()
                form.append("params[page]", 1)
                if(this.state.filter.searchText.trim()){

                    form.append("params[searchText]",  this.state.filter.searchText.trim())
                }else{
                    form.append("params[searchText]","")

                }
                form.append("params[authToken]", this.getToken());

                this.applyFilter({ form })

            }
            else{
                
                addAdminMessage.error = error.message
                addAdminMessage.success = null


                this.setState((preState)=>{

                    return {
                        addAdminMessage: addAdminMessage,
                        loading: false
                    }
                })

                return   
            }
            setTimeout(()=>{
                addAdminMessage.error =null  
                addAdminMessage.success =null
                this.toggleNewAdminPopup()
                
                this.setState((preState)=>{

                    return {
                        addAdminMessage: addAdminMessage
                    }
                })
            }, 2000)


        })

    }

    handlePageNo=()=>{


        this.setState((preState)=>{
        
            

            // pagination.selectedPage = 1
          return  {loading:true}
        
        })

        let form = new FormData()

        // form.append()
        // this.setState({loading:true})

        form.append("params[page]", this.state.pagination.selectedPage)
        form.append("params[locationId]", this.state.filter.locationId)
        if(this.state.filter.searchText.trim()){

            form.append("params[searchText]", this.state.filter.searchText.trim())
        }else{
            form.append("params[searchText]", "")

        }
        form.append("params[authToken]", this.getToken());

        this.props.adminList(form)
    }


    componentWillMount(){


        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)

        this.getUParkingLots()
        this.getParkingListOptions();

        this.setState({loading:true})


        let form = new FormData()

        form.append("params[authToken]", this.getToken());
        
        this.applyFilter({form})


    }

    componentWillReceiveProps(props){

        let { adminArray, adminTotal, adminError } = props.admin;


        let { inviteError, inviteSuccess } = props

        if (inviteError) {

            
            this.setState({ inviteError: inviteError, inviteSuccess: {} })
        }
        else if (inviteSuccess) {

            this.setState({ inviteSuccess: inviteSuccess, inviteError: {} });

            setTimeout(() => { this.toggleNewAdminModal() }, 3000)
        }

        let totalPage = Math.ceil(adminTotal / 10);


        this.setState({loading:false})
       

        this.setState((preState)=>{

            let admins = preState.admins;
            let listAdminMessage = preState.listAdminMessage
            let pagination = preState.pagination
            
            // let pagination = preState.pagination
            
            admins = adminArray
            listAdminMessage.error = adminError
            pagination.totalPage = totalPage 

            return {
                admins:  admins,
                pagination : pagination,
                listAdminMessage : listAdminMessage
            }
        })



    }
//==================================================GETTING PARKING LIST OPTIONS===========================================================================================//

     getParkingListOptions() {

        this.setState({loading:true})

        let parkingListForm = new FormData()

        // parkingListForm.append("params[page]", 0)
        parkingListForm.append("params[authToken]", this.getToken())

        _callApi(parkingListForm,
            END_POINT.ALL_PARKING_LIST.END_POINT,
            END_POINT.ALL_PARKING_LIST.POST,
            {}).then(res => {

                let { result = [], error } = res.data.payload


                let parkingList = this.state.parkingList

                if(error == 401){

                    this.props.setAuthToken()
                }
                if (!error) {

                    this.setState({loading:false})


                    parkingList.options = result.map((item, index) => {

                        return { value: item.locationId, label: item.company }
                    })

                    parkingList.options.splice(0, 0, { value: "", label: "Show All" })



                    this.setState((preState)=>{

                       return { ...preState, ...parkingList }
                    })

                    // console.log("PARKING LIST OPTIONS RESPONSE ", this.state.parkingList.options)
                }
                else {
                    // console.log("PARKING LIST OPTIONS RESPONSE ", res)
                    this.setState({loading:false})

                }
            })
    }


// ====================DELETE ADMIN========================//
    deleteAdmin=()=>{

        // console.log("ACTION ==========",this.state.driverToDelete)

        let form = new FormData()

        let adminToDelete = this.state.adminToDelete

        this.setState({loading:true})

        Object.keys(adminToDelete).forEach((key)=>{


            form.append(`params[${key}]`, adminToDelete[key]+"")
        })

        form.append("params[authToken]", this.getToken())

        _callApi(form, 
            END_POINT.DELETE_ADMIN.END_POINT,
            END_POINT.DELETE_ADMIN.POST,{}).then(res=>{

                let { result= null, error= null } = res.data.payload
 
                if(error == 401){
                    this.props.setAuthToken()

                }else if(!error){


                    this.setState({loading:false})

                    this.toggleDeleteConfirmModal(adminToDelete);

                    let form = new FormData()
    
                    // form.append()
                    form.append("params[page]", 1)
                    // form.append("params[searchText]", this.state.filter.searchText)
                    form.append("params[authToken]", this.getToken());
    
                    this.applyFilter({ form })
                }else{

                    this.setState({loading:false})

                }
            })

                    
    }
//============================================END===========================//

// ======================EDIT ADMIN==========================//
handleEditAdmin=(values, formikBag)=>{


    formikBag.setSubmitting(true)

    // console.log(values)
    this.setState({loading:true})

    let form = new FormData()

    Object.keys(values).forEach((key)=>{


        form.append(`params[${key}]`, values[key]+"")
    })

    form.append('params[authToken]',this.getToken())

    _callApi(form,
        END_POINT.UPDATE_LOT_ADMIN_DETAILS.END_POINT,
        END_POINT.UPDATE_LOT_ADMIN_DETAILS.POST,{} ).then(res=>{

            let { result= null, error= null } = res.data.payload

            let editAdminMessage = this.state.editAdminMessage
            if(error == 401){

                 this.props.setAuthToken()
            }else if(!error){

                // console.log(result)

                // this.setState({loading:false})

                editAdminMessage.success = "Successfully Updated!"
                editAdminMessage.error = null
                this.setState((preState)=>{

                    return {
                        editAdminMessage: editAdminMessage
                    }
                })

                formikBag.setSubmitting(false)


                let form = new FormData()

                // form.append()
                form.append("params[page]", 1)
                // form.append("params[searchText]", this.state.filter.searchText)
                form.append("params[authToken]", this.getToken());

                this.applyFilter({ form })

            }
            else{
                
                editAdminMessage.error = error.fileName
                editAdminMessage.success = null

                formikBag.setSubmitting(false)

                this.setState({loading:false})

                this.setState((preState)=>{

                    return {
                        editAdminMessage: editAdminMessage
                    }
                })

                return   
            }
            setTimeout(()=>{
                editAdminMessage.error =null  
                editAdminMessage.success =null

                this.toggleEditAdminPopup(this.state.currentAdmin)
                
                this.setState((preState)=>{

                    return {
                        editAdminMessage: editAdminMessage
                    }
                })
            }, 1000)

        })

}

// FOR SUSPENDING ADMIN //

suspendAdmin=()=>{

    let { suspendAdminModal } = this.state

        // console.log("isSuspended===========",isSuspended)
        let form = new FormData()

        if(+suspendAdminModal.status === 0){

            form.append("params[status]", 1)
        
        }else{

            form.append("params[status]", 0)
        }
    form.append("params[userID]", suspendAdminModal.adminId)

    form.append("params[authToken]", this.getToken())
    
    _callApi(form,
        END_POINT.SUSPEND_ADMIN.END_POINT,
        END_POINT.SUSPEND_ADMIN.POST,
        {}).then(res => {

            let { result = null, error = null } = res.data.payload

            if (error == 401) {

                this.props.setAuthToken()
                
            } else if (!error) {

                suspendAdminModal.isOpen = false

                this.setState({ suspendAdminModal })

                let form1 = new FormData()

                form1.append("params[page]", 1)
                form1.append("params[authToken]", this.getToken());

                this.applyFilter({ form: form1 })

            }
        }).catch(error=>{

            // console.log(error)
            let { message } = error.response.data

            alert(message)
        })
}

// =================================END===============================//


// =======================TOGGLE DELETE CONFIRMATION POP UP================//
toggleDeleteConfirmModal = (admin) => {


    // console.log("CONFIRM ==========",this.state.adminToDelete)

    this.setState((preState)=>{

      return  {deleteConfirmModal: !preState.deleteConfirmModal, adminToDelete: admin }
    })
}

// =============================END========================================//


// ========================TOGGLE EDIT ADMIN POP UP===============//

toggleEditAdminPopup = (admin) => {

        this.setState((preState)=>{

            return {
                currentAdmin: admin,
                editAdminPopup: !preState.editAdminPopup
            }
        })
}

// ==================TOGGLE NEW ADMIN MADAL=================//
toggleNewAdminModal=()=> {

    let {inviteSuccess, inviteError } = this.state


    inviteError = null
    inviteSuccess = null

    this.setState({
        newAdminModal: !this.state.newAdminModal,
        inviteError,
        inviteSuccess
    });
}
// ==========================END============================//

toggleSuspendConfirmModal = (status, adminId)=>{


    // console.log(adminId);
    let { suspendAdminModal } = this.state

    if(adminId){

        suspendAdminModal.adminId = adminId;
        suspendAdminModal['status'] = status;
        suspendAdminModal.isOpen = true  ;
    }else{
        suspendAdminModal.adminId = '';
        suspendAdminModal.isOpen = false  ;
        suspendAdminModal['status'] = null;
    }

    this.setState({ suspendAdminModal });

}

// ===============================END==============================//

    getToken() {

        return localStorage.getItem("jwtToken");
    }

    loadAdminTable=()=>{
        
    }

    render() {
        
        return (
            <div className="row">
                <AdminFilter  toggleNewAdminModal={this.toggleNewAdminModal} applyFilter={this.applyFilter} parkingOptions={this.state.parkingList.options} />

                { 
                    <AdminTable loading ={ this.state.loading} toggleSuspendConfirmModal={this.toggleSuspendConfirmModal}  toggleEditAdminPopup={this.toggleEditAdminPopup} admins={this.state.admins} error = { this.state.listAdminMessage.error}  toggleDeleteConfirmModal={this.toggleDeleteConfirmModal} />
                }
                
                <div style={{margin:"0 auto"}} >
                <ReactPaginate
                    pageCount={this.state.pagination.totalPage}
                    pageRangeDisplayed={this.state.pagination.pageSize}
                    onPageChange={(page) => {
                        let pagination = this.state.pagination

                        pagination.selectedPage = page.selected +1

                        this.setState({ pagination: pagination })

                        this.handlePageNo()
                    }}
                    forcePage={this.state.pagination.selectedPage - 1}   // USED TO FORCEFULLY SET CURRENT PAGE NO.,
                                                                            //I USED IT IN CASE OF FILTERS WHERE WE WANT TO SYNC PAGES OF AVAILABLE PAGES FOR SELECTED FILTERS
                    containerClassName="pagination-custom" />
                </div>

                {/* <NewAdminModal
                     handleNewAdmin={this.handleNewAdmin}
                     addAdminMessage={this.state.addAdminMessage}
                     newAdminPopup={this.state.newAdminPopup} 
                     toggleNewAdminPopup={this.toggleNewAdminPopup}
                     /> */}


                 <EditAdminModal
                    handleEditAdmin={this.handleEditAdmin}
                    editAdminPopup={this.state.editAdminPopup}
                    toggleEditAdminPopup={this.toggleEditAdminPopup}
                    admin={this.state.currentAdmin}
                    editAdminMessage={this.state.editAdminMessage}
                />

                <DeleteAdminConfirmModal 
                 toggleDeleteConfirmModal={this.toggleDeleteConfirmModal} // PASSING DELETE MODAL METHOD TO TOGGLE IT
                 deleteConfirmModal={this.state.deleteConfirmModal} // PASSING BOOLEAN VALUE TO POP UP AND POP IN MODAL
                 deleteAdmin={this.deleteAdmin} // PASSING DELETE METHOD
                 adminToDelete = { this.state.adminToDelete} // PASSING CURRENTLY SELECTED ADMIN FOR DELETE
                />

                 <div className="add-new-admin-modal">
                    <Modal centered={true} isOpen={this.state.newAdminModal} style={{ maxWidth: "400px" }} toggle={this.toggleNewAdminModal} className={this.props.className} >
                        <ModalHeader >Invite New Admin</ModalHeader>
                        <ModalBody>
                            <Formik

                                initialValues={
                                    { email: "", name: "", phone:"", uALots: [], ccEmail:"" }
                                }
                                validationSchema={Yup.object().shape({

                                    email: Yup.string()
                                        .email(VALIDATE.EMAIL_INVALID)
                                        .required(VALIDATE.EMAIL_REQ),

                                    name: Yup.string()
                                        .required(VALIDATE.NAME_REQ)
                                        .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),

                                    phone: Yup.string()
                                            .required(VALIDATE.PHONE_REQ)
                                            .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                            .min(10, VALIDATE.PHONE_MIN)
                                            .max(15, VALIDATE.PHONE_MAX),
                                    uALots: Yup.array().required("Please select a unassigned location ")
                                        // .min(3, 'Pick at least 3 tags')
                                        .of(
                                            Yup.object().shape({
                                                label: Yup.string().required(),
                                                value: Yup.string().required(),
                                            })
                                        ),
                                    ccEmail:Yup.string()
                                        .email(VALIDATE.EMAIL_INVALID)

                                })


                                }
                                onSubmit={this.handleSubmit}
                                render={
                                    ({ values, handleBlur, handleChange, handleSubmit, errors, setFieldValue, touched }) => (

                                        <Form>
                                            <div className="admin-modal">

                                                <div className="admin-add-popup">
                                                    <div className="admin-add-popup">
                                                        <span style={{ fontSize: "12px", color: "red" }} >
                                                            {this.state.inviteError && this.state.inviteError.fileName}
                                                        </span>
                                                        <span style={{ fontSize: "12px", color: "green" }} >
                                                            {this.state.inviteSuccess && this.state.inviteSuccess.message}
                                                        </span>
                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Name
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="name"
                                                                    value={values.name}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Name"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.name && errors.name && errors.name}
                                                                </span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Email
                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="email"
                                                                    value={values.email}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Email"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.email && errors.email && errors.email}
                                                                </span>
                                                            </div>

                                                            <div className="clearfix"></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                  CC  Email
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="ccEmail"
                                                                    value={values.ccEmail}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="CC  Email"
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.ccEmail && errors.ccEmail && errors.ccEmail}
                                                                </span>
                                                            </div>

                                                            <div className="clearfix"></div>
                                                        </div>

                                                        <div className="form-group">
                                                            <div className="label-class">
                                                                <label>
                                                                    Phone No.
                                                                </label>
                                                            </div>
                                                            <div className="input-class">
                                                                <input
                                                                    type="text"
                                                                    name="phone"
                                                                    value={values.phone}
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    className="form-control"
                                                                    placeholder="Enter mobile no."
                                                                />
                                                                <span style={{ fontSize: "12px", color: "red" }} >
                                                                    {touched.phone && errors.phone && errors.phone}
                                                                </span>
                                                            </div>
                                                            <div className="clearfix"></div>
                                                        </div>
                                                       
                                                        <div style={{ margin: '1rem 0' }} className="admin-select-lot" >
                                                            <label htmlFor="color">Select Unassigned Parking Lots </label>
                                                            <Select
                                                                id="topics"
                                                                // multi={true}
                                                                options={this.state.permitsDropdown}
                                                                // options={options}
                                                                onChange={(value) => this.handlePermitSelect(setFieldValue, value)}
                                                                onBlur={handleBlur}
                                                                value={values.uALots}
                                                                isMulti={true}
                                                            />
                                                        </div>
                                                        <span style={{ fontSize: "12px", color: "red" }} >
                                                            { touched.uALots && errors.uALots && errors.uALots}
                                                        </span>

                                                    </div>
                                                    <div className="btns-admin-add">
                                                        <button type="submit" className="btn btn-admin">Send Invite</button>
                                                        <button type="button" onClick={this.toggleNewAdminModal} className="btn btn-admin">Cancel</button>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* {JSON.stringify(values)} */}
                                        </Form>)}
                            />
                        </ModalBody>
                    </Modal>
                </div>

                <AdminSuspendModal
                 {...this.state.suspendAdminModal }
                 toggleSuspendConfirmModal={this.toggleSuspendConfirmModal}
                 suspendAdmin={this.suspendAdmin}
                />
            </div>
        )
    }

}

export default Admins = connect(mapStateToProps, mapDispatchToProps)(Admins);