import React from 'react';
import minus  from '../../../assets/images/minus.png'


export const ListRow = ({toggleEditAdminPopup,toggleSuspendConfirmModal, row, toggleDeleteConfirmModal})=>{

        // {name: "Lot Admin", email: "lotadmin@yopmail.com"}
    return (
            <tr className='solid'>
                    <td><i className="fa fa-edit" onClick={()=>toggleEditAdminPopup(row)}></i></td>
                    <td>{row.name}</td>
                    {/* <td>Empire State Apartments</td> */}
                    <td>{(row.phone)? row.phone : "N/A"  }</td>
                    <td>{row.email}</td>
                    <td>{row.parkingLot}</td>
                    <td style={{cursor:'pointer', color:"blue"}} onClick={()=>toggleSuspendConfirmModal(row['isSuspended'], row.userID)} > {  +row.isSuspended===0 ?  "Suspend" : "Unsuspend"  } </td>
                    <td> <img   onClick={()=>toggleDeleteConfirmModal(row)} src={ minus} className="cursor"  /> </td>

            </tr>
    )
}