import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { PermitListAction } from "../actions/PermitListAction";
import { ACTIONS } from "../../Constants/Actions";


export const PermitListApi = ( { form} )=> {

    return ( dispatch, getState )=>{


        dispatch({type:ACTIONS.PERMIT_LIST.PERMIT_LIST_FETCHING, payload: {}})
        
        return _callApi(
            form,
            END_POINT.PERMIT_LIST.END_POINT,
            END_POINT.PERMIT_LIST.POST,
            {}
            ).then(res=>{

                let {  result= null, error=null } = res.data.payload

                if(!error){

                    dispatch(PermitListAction({result, error}))

                }
                else {

                    dispatch(PermitListAction({result, error}))
                }
            })
    }
}