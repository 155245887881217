import React, { Component } from 'react';
import { connect } from 'react-redux';
import { END_POINT } from '../../Constants/ApiEndPoints';
import _callApi from '../../Services/baseService';
import { RefundListAPI } from '../../store/api-actions/RefundListAPI';
import _ from 'lodash'
import RefundTable from './RefundViews/RefundTable';
import RefundFilter from './RefundViews/RefundFilter';
import ReactPaginate from 'react-paginate';
import { RefundConfirmModal } from './RefundViews/RefundConfirmModal';
import { SetAuthTokenApi } from '../../store/api-actions/SetAuthTokenApi';
import { PassRefundConfirmModal } from './RefundViews/PassRefundConfirmModal';

const mapDispatchToProps=(dispatch)=>{

    return {
            getRefundList:(form)=>dispatch(RefundListAPI({form})),
            setAuthToken:()=>dispatch(SetAuthTokenApi())
        }
}

const mapStateToProps=(state)=>{

    return {
        
            refundList: state.refund.listByIds,
            refundTotal: state.refund.total,
            refundError: state.refund.error,
        }
}

class RefundComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            filter:{
                searchText:"",
                locationId:"",
            },
// =================PAGINATION=========//
            pagination:{
                totalPage:0,
                selectedPage:1,
                pageSize:10
            },
// =====================END============//
            

            currentRefund:{
                email: "",
                name: ""
            },

//=============== LISTS================//
            refundList:[],
            
            parkingList: {
                options: []
            },
// =================END==============//

// ====================================ERROR MESSAGES======================================//
            addRefundMessage:{
                error:null,
                success:null
            },
            editRefundMessage:{
                error:null,
                success:null
            },
            listRefundMessage:{
                error:null
            },

            passRefundResponse:{
                isSuccess:false,
                msg:''
            },
//==========================================END============================================//

// =============POP UPS===================//
            requestConfirmModal:false,
            newRefundPopup:false,
            editRefundPopup:false,
            requestType:null,
            passRefundModal: false,
//==================END===================//

// ============ADMIN TO DELETE=============//
            requestToRefund:{
                name:""
            },
// ============END=========================//

// ====================================//
            loading:false
// ===============END==================//
        };
        
    }

    // toggleDropdown() {
    //     this.setState(prevState => ({
    //         dropdownOpen1: !prevState.dropdownOpen1
    //     }));
    // }

    // toggleEditPermit=()=>{

    // }

    applyFilter=({values, formikBag,form})=>{


        this.setState((preState)=>{
        
            let pagination = preState.pagination

            pagination.selectedPage = 1
        
           return  {loading:true, pagination}
        })

        if(values){

            let { locationId, searchText } = values

            this.setState((preState)=>{

                let filter = preState.filter

                filter.locationId = locationId.value
                filter.searchText = searchText
                return {
                    filter : filter
                }
            })
            // let pageNo = this.state.filter.selectedPage+1
        }

        if(form){

            this.props.getRefundList(form)
        }

        else {


            let form = new FormData()

            form.append("params[authToken]", this.getToken());
            form.append("params[locationId]", values.locationId.value)
            form.append("params[searchText]", values.searchText.trim())
            form.append("params[page]", 1)


            this.props.getRefundList(form)

        }


    }



    handlePageNo=()=>{


        this.setState({loading:true})

        let form = new FormData()

        form.append("params[page]", this.state.pagination.selectedPage)
        form.append("params[locationId]", this.state.filter.locationId)
        form.append("params[searchText]", this.state.filter.searchText)
        form.append("params[authToken]", this.getToken());

        this.props.getRefundList(form)
    }


    componentWillMount(){

        // this.getParkingListOptions();

        // this.setState({loading:true})
        let { pathname } = this.props.location;

        localStorage.setItem("currentLocation", pathname)

       
    }

    componentDidMount(){

        let form = new FormData()

        form.append("params[authToken]", this.getToken());
        
        this.applyFilter({form})


        this.getParkingListOptions();

        // this.setState({loading:true})
    }

    componentWillReceiveProps(props){

        let { refundList, refundTotal, refundError } = props;
        // console.log("=====================admin list ",adminArray, adminTotal)

        let totalPage = Math.ceil(refundTotal / 10);

        this.setState((preState)=>{

            let list = preState.refundList;
            let listRefundMessage = preState.listRefundMessage
            let pagination = preState.pagination
            
            // let pagination = preState.pagination
            
            list = _.values( refundList)

            listRefundMessage.error = refundError
            pagination.totalPage = totalPage 

            return {
                refundList:  list,
                pagination : pagination,
                listRefundMessage : listRefundMessage,
                loading:false
            }
        })

        // console.log("refund list ", this.state.refundList)


    }
//==================================================GETTING PARKING LIST OPTIONS===========================================================================================//

     getParkingListOptions() {

        this.setState({loading:true})

        let parkingListForm = new FormData()

        // parkingListForm.append("params[page]", 0)
        parkingListForm.append("params[authToken]", this.getToken())

        _callApi(parkingListForm,
            END_POINT.ALL_PARKING_LIST.END_POINT,
            END_POINT.ALL_PARKING_LIST.POST,
            {}).then(res => {

                let { result = [], error } = res.data.payload


                let parkingList = this.state.parkingList

                if(error == 401){

                     this.props.setAuthToken()
                }else if (!error) {

                    this.setState({loading:false})


                    parkingList.options = result.map((item, index) => {

                        return { value: item.locationId, label: item.company }
                    })

                    parkingList.options.splice(0, 0, { value: "", label: "Show All" })



                    this.setState((preState)=>{

                       return { ...preState, ...parkingList }
                    })

                    // console.log("PARKING LIST OPTIONS RESPONSE ", this.state.parkingList.options)
                }
                else {
                    // console.log("PARKING LIST OPTIONS RESPONSE ", res)
                    this.setState({loading:false})

                }
            })
    }

// ================== PASSWORD PROTECTED REFUND ============//

sendPassRefundRequest=({password})=>{

        let form = new FormData()

        let {requestToRefund, passRefundModal, passRefundResponse } = this.state

        // this.setState({loading:true})

        form.append("params[refundRequestID]", requestToRefund.refundRequestID)
        form.append("params[password]", password)
        form.append("params[status]", 1)
        form.append("params[authToken]", this.getToken())

        _callApi(form, 
            END_POINT.UPDATE_APPROVE_REJECTSTATUS.END_POINT,
            END_POINT.UPDATE_APPROVE_REJECTSTATUS.POST,{}).then(res=>{

                let { result= null, error= null } = res.data.payload

                if(error == 401){

                    this.props.setAuthToken()
                }else if(!error){

                    // console.log(result)
                    

                    passRefundResponse.isSuccess = true;
                    passRefundResponse.msg = "Successfully refunded";


                    this.setState({loading:false, passRefundResponse})


                    setTimeout(()=>{

                        if(passRefundModal){
    
                            this.togglePassReqModal(requestToRefund);
                        }
                        let form = new FormData()
                        form.append("params[authToken]", this.getToken());
                        this.applyFilter({ form })
                    },2000)


                    // form.append()
                    // form.append("params[page]", 1)
                    // form.append("params[searchText]", this.state.filter.searchText)
                  
    
                }else{

                    passRefundResponse.isSuccess = false;
                    passRefundResponse.msg = error.message;

                    this.setState({loading:false, passRefundResponse})

                }
            })

                    
    }

// ============================END==========================//

// ===================== PASSWORD PROTECTED REFUND MODAL ==================//

togglePassReqModal = (requestToRefund, requestType) => {

    let { passRefundModal, passRefundResponse } = this.state

    passRefundResponse.isSuccess = false;
    passRefundResponse.msg = ''

    this.setState({ passRefundModal : !passRefundModal, requestToRefund, passRefundResponse });
}

//=================================END=====================================//



// ====================DELETE ADMIN========================//
sendRequest=({requestType})=>{


        let form = new FormData()

        let {requestToRefund, requestConfirmModal } = this.state

        this.setState({loading:true})

        form.append("params[refundRequestID]", requestToRefund.refundRequestID)
        form.append("params[status]", requestType)

        form.append("params[authToken]", this.getToken())

        _callApi(form, 
            END_POINT.UPDATE_APPROVE_REJECTSTATUS.END_POINT,
            END_POINT.UPDATE_APPROVE_REJECTSTATUS.POST,{}).then(res=>{

                let { result= null, error= null } = res.data.payload

                if(error == 401){

                    this.props.setAuthToken()
                }else if(!error){


                    this.setState({loading:false, requestConfirmModal:false})

                    let form = new FormData()
                    form.append("params[authToken]", this.getToken());
                  
    
                    this.applyFilter({ form })
                }else{

                    this.setState({loading:false})

                }
            })

                    
    }
//============================================END===========================//


// =======================TOGGLE DELETE CONFIRMATION POP UP================//
toggleRequestConfirmModal = (requestToRefund, type) => {

    // console.log(requestToRefund, type)
    this.setState((preState)=>{

      return  {requestConfirmModal: !preState.requestConfirmModal, requestToRefund: requestToRefund, requestType: type }
    })
}

// =============================END========================================//

    getToken() {

        return localStorage.getItem("jwtToken");
    }

    loadRefundTable=()=>{
        
    }

    render() {
        
        return (
            <div className="row">

                <RefundFilter  
                toggleNewRefundPopup={this.toggleNewRefundPopup} 
                applyFilter={this.applyFilter} 
                parkingOptions={this.state.parkingList.options} />
                
            <RefundTable 
            loading ={ this.state.loading}   
            toggleEditRefundPopup={this.toggleEditRefundPopup} 
            refundList={this.state.refundList} 
            error = { this.state.listRefundMessage.error}  
            toggleRequestConfirmModal={this.toggleRequestConfirmModal}
            togglePassReqModal={this.togglePassReqModal} />
                
                
                <div style={{margin:"0 auto"}} >
                <ReactPaginate
                    pageCount={this.state.pagination.totalPage}
                    pageRangeDisplayed={this.state.pagination.pageSize}
                    onPageChange={(page) => {
                        let pagination = this.state.pagination

                        pagination.selectedPage = page.selected +1

                        this.setState({ pagination: pagination })

                        this.handlePageNo()
                    }}
                    forcePage={this.state.pagination.selectedPage - 1}   // USED TO FORCEFULLY SET CURRENT PAGE NO.,
                                                                            //I USED IT IN CASE OF FILTERS WHERE WE WANT TO SYNC PAGES OF AVAILABLE PAGES FOR SELECTED FILTERS
                    containerClassName="pagination-custom" />
                </div>

            

                <RefundConfirmModal 
                 toggleRequestConfirmModal ={this.toggleRequestConfirmModal} // PASSING DELETE MODAL METHOD TO TOGGLE IT
                 requestConfirmModal       ={this.state.requestConfirmModal} // PASSING BOOLEAN VALUE TO POP UP AND POP IN MODAL
                 sendRequest               ={this.sendRequest} // PASSING DELETE METHOD
                 requestToRefund           = { this.state.requestToRefund} // PASSING CURRENTLY SELECTED ADMIN FOR DELETE
                 requestType               = { this.state.requestType }
                /> 
                <PassRefundConfirmModal
                togglePassReqModal = {this.togglePassReqModal }
                sendPassRefundRequest={this.sendPassRefundRequest}
                className={''}
                requestToRefund= { this.state.requestToRefund}
                passRefundResponse = {this.state.passRefundResponse}
                passRefundModal = {this.state.passRefundModal} 
                />


            </div>
        )
    }

}

export default RefundComponent = connect(mapStateToProps, mapDispatchToProps)(RefundComponent);