export const END_POINT = {

    ALL_PARKING_LIST: {
        END_POINT: "allParkingList.json",
        POST: 1
    },
    //live
    KUMOLOUS: {
        SECRET_KEY: "9IwP7OJZ8yRbMq4PYfI3hk+4EUp7BEbG5whr",
        API_KEY: "770d6615-87aa-4195-b330-b3cb892a93ef"
    },
    // KUMOLOUS: {
    //     SECRET_KEY: "+B9/lUY878UVrEijloICAoTAVvMD4cU28H+i",
    //     API_KEY: "ced2af57-8136-4547-9174-6373746f5b30"
    // },
    PARK_LIST: {
        END_POINT: "parkingList.json",
        POST: 1
    },
    LOTADMIN_INVITE: {
        END_POINT: "lotAdminInvitation.json",
        POST: 1
    },
    DASHBOARD_REPORT: {
        END_POINT: "dashboardReport.json",
        POST: 1
    },
    LOT_ADMIN_LIST: {
        END_POINT: "lotAdminList.json",
        POST: 1
    },
    LATEST_RESERVED_PERMITS: {
        END_POINT: "latestReservedPermitList.json",
        POST: 1
    },
    PERMIT_LIST: {
        END_POINT: "permitList.json",
        POST: 1
    },
    UNASSIGNED_PARKING_LOTS: {
        END_POINT: "unAssignedParkingLots.json",
        POST: 1
    },
    ADD_PARKING_LOT: {
        END_POINT: "addParkingLot.json",
        POST: 1
    },
    CLOUDNARY: {
        API_KEY: "147992964421448",
        API_SECRET: "k1HeNfcB27DGoAMOBt216scCRmU",
        CLOUD_NAME: "sylab"
    },
    RESERVE_PERMIT: {
        END_POINT: "reserveParkingByAdmin.json",
        POST: 1
    },
    PROMOTION_LIST: {
        END_POINT: "promotionList.json",
        POST: 1
    },
    SURGE_LIST: {
        END_POINT: "surgePriceList.json",
        POST: 1
    },
    RESET_PASSWORD: {
        END_POINT: "resetPassword.json",
        POST: 1
    },
    GOOGLE_MAP: {
        API_KEY: "AIzaSyBOSCPWOVFUT54os_i8xT_ELtk9Z5rZ7eI"
    },
    UPDATE_PARKING_LOT: {
        END_POINT: "updateParkingLot.json",
        POST: 1
    },
    SUSPEND_USER: {
        END_POINT: "suspendUserForPermit.json",
        POST: 1
    },
    REMOVE_SURGE_EVENT: {
        END_POINT: "removeSurgeEvent.json",
        POST: 1
    },
    REMOVE_PROMOTION: {
        END_POINT: "removePromotion.json",
        POST: 1
    },
    DRIVER_LIST: {
        END_POINT: "driverList.json",
        POST: 1
    },
    ADD_DRIVER: {
        END_POINT: "addDriver.json",
        POST: 1
    },
    UPDATE_DRIVER_DETAILS: {
        END_POINT: "updateDriverDetails.json",
        POST: 1
    },
    DELETE_DRIVER: {
        END_POINT: "deleteDriver.json",
        POST: 1
    },
    UPDATE_PERMIT_DETAILS: {
        END_POINT: "updatePermitDetails.json",
        POST: 1
    },
    DELETE_ADMIN: {
        END_POINT: "deleteLotAdmin.json",
        POST: 1
    },
    ADD_ADMIN: {
        END_POINT: "",
        POST: 1
    },
    UPDATE_LOT_ADMIN_DETAILS: {
        END_POINT: "updateLotAdminDetails.json",
        POST: 1
    },

    CONVERSATIONS: {
        END_POINT: "conversation.json",
        POST: 1
    },

    SEND_CHAT: {
        END_POINT: "sendChat.json",
        POST: 1
    },
    GET_CHAT_HISTORY: {
        END_POINT: "getChatHistory.json",
        POST: 1
    },
    REFUND_LIST: {
        END_POINT: "refundRequestList.json",
        POST: 1
    },
    UPDATE_APPROVE_REJECTSTATUS: {
        END_POINT: "updateApproveRejectStatus.json",
        POST: 1
    },
    IMPORT_PARKING_CSV: {
        END_POINT: "importParkingLot.json",
        POST: 1
    },
    IMPORT_PERMIT_CSV: {
        END_POINT: "importPermits.json",
        POST: 1
    },

    SUSPEND_PARKING_LOT: {
        END_POINT: "updateLocationSuspendStatus.json",
        POST: 1
    },
    SUSPEND_ADMIN: {
        END_POINT: "updateAdminSuspendStatus.json",
        POST: 1
    },

    ADD_EXISTING_DRIVER: {
        END_POINT: "assignDriverTolotAdmin.json",
        POST: 1
    },

    ADD_DEVICE_TOKEN: {
        END_POINT: "addDeviceToken.json",
        POST: 1
    },

    ADD_STRIPE: {
        END_POINT: "addStripe.json",
        POST: 1
    }
}