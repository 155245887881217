import React, { Component } from 'react';
import { NavLink, Switch, Route, Redirect } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
// import EditLotPopup from './parkingLotsComponents/EditLotPopup';
// import ReservedPopup from './parkingLotsComponents/ReservedPopup';
import  Guests from './components/Guests';
import  Residents from './components/Residents';

class Permits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalEdit: false,
            modalReserved: false,
            isChecked: props.isChecked || false,
            collapse: false,
            dropdownOpen1: false,
            dropdownOpen2: false
        };


        this.toggleDropdown1 = this.toggleDropdown1.bind(this);
        this.toggleDropdown2 = this.toggleDropdown2.bind(this);
        this.toggleEvent = this.toggleEvent.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleEditPermit = this.toggleEditPermit.bind(this);
        this.toggleEditLots = this.toggleEditLots.bind(this);
        this.toggleReservedPopup = this.toggleReservedPopup.bind(this);
    }

    toggleEditPermit() {
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleEditLots() {
        this.setState({
            modalEdit: !this.state.modalEdit
        });
    }

    toggleReservedPopup() {
        this.setState({
            modalReserved: !this.state.modalReserved
        });
    }


    // dropdown 

    toggleDropdown1() {
        this.setState(prevState => ({
            dropdownOpen1: !prevState.dropdownOpen1
        }));
    }

    toggleDropdown2() {
        this.setState(prevState => ({
            dropdownOpen2: !prevState.dropdownOpen2
        }));
    }

    // check toggle btn
    handleChange() {
        this.setState({ isChecked: !this.state.isChecked })
    }

    // collapse events
    toggleEvent() {
        this.setState({ collapse: !this.state.collapse });
    }

    componentDidMount(){
    }

    render() {
        return (
            <React.Fragment>
                

                <div>
                    {/* {this.props.match.path} */}
                <Switch>
                    <Route path={`${this.props.match.path}/guests`} name="guests" component={Guests} />
                    <Route path={`${this.props.match.path}/residents`} name="residents" component={Residents} />
                    <Redirect from={this.props.match.path} to= {`${this.props.match.path}/guests`}  />
                </Switch>
                </div>

            </React.Fragment >
        );
    }
}

export default Permits;