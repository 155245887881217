import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { LatestReservedPermit } from "../actions/LatestReservedPermit";


export const LatestPermitListApi = ({ form})=>{

    return ( dispatch, getState)=>{

        return _callApi(
            form,
            END_POINT.LATEST_RESERVED_PERMITS.END_POINT,
            END_POINT.LATEST_RESERVED_PERMITS.POST,
            {} ).then(res=>{

                let { result=null , error=null } = res.data.payload;
                // console.log(res)

                if(!error){

                    dispatch(LatestReservedPermit({result, error}))
                }
                else{

                    dispatch(LatestReservedPermit({result,error}))

                }

                return res
            })

    }
}