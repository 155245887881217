import React, { Component } from 'react';
import { APP_CONST } from '../../../Constants/AppConstants';
import  profile1 from '../../../assets/images/profile1.jpg'
import  profile2 from '../../../assets/images/profile2.jpg'
import  profile3 from '../../../assets/images/profile3.jpg'
import  msg from '../../../assets/images/msg.png'
import  profile4 from '../../../assets/images/profile4.jpg'
// import  profile1 from '../../../assets/images/profile1.jpg'

class PersonCard extends Component {
    constructor(props) {
        super(props);
    }

    

    render() {
        return (
            <React.Fragment>

                <li className="online">
                    <div className="person-card">
                        <div className="person-image">
                            <img src={ profile1} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Online Now</p>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="person-card">
                        <div className="person-image">
                            <img src={ profile2} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Active 1w ago</p>
                        </div>
                    </div>
                </li>


                <li className="online chat-message">
                    <div className="person-card">
                        <div className="person-image">
                            <img src={profile3} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Online Now</p>
                        </div>
                    </div>
                    <div className="msg">
                        <img src={ msg} />
                    </div>
                </li>


                <li>
                    <div className="person-card">
                        <div className="person-image">
                            <img src={profile4} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Active 6h ago</p>
                        </div>
                    </div>
                </li>

                <li>
                    <div className="person-card">
                        <div className="person-image">
                            <img src={ profile4} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Active 6h ago</p>
                        </div>
                    </div>
                </li>

                <li className="online">
                    <div className="person-card">
                        <div className="person-image">
                            <img src={profile4} />
                        </div>

                        <div className="person-status">
                            <h5>Jennifer Smith</h5>
                            <p>Online Now</p>
                        </div>
                    </div>
                </li>

            </React.Fragment>
        )
    }
}

export default PersonCard;