import { ACTIONS } from "../../Constants/Actions";

const  FetchingParkList=({loading, action}) =>{


    return {
        type: action,
        payload:loading
    }
}

export default FetchingParkList