import { ACTIONS } from "../../Constants/Actions";


export const latestResPermitReducer = ( state = { }, action = {} )=>{


    /**
     *  {
            permitsList:{},
            permitsListError : {}
        }
     * 
     */
    switch(action.type){

        case ACTIONS.LATEST_PERMIT_LIST.LATEST_PERMIT_LIST_SUCCESS: {

            let newState = { ...{}, ...state }

            let { result, error } = action.payload;


            result.map((item,index)=>{

                newState.permitsList[item.reserveLocationID] = item
            })
            // newState.permitsList = result;
            newState.permitsListError = error;

            return newState
        }
        case ACTIONS.LATEST_PERMIT_LIST.LATEST_PERMIT_LIST_FAILED: {

            let newState = { ...{}, ...state }

            let { result, error } = action.payload;

            newState.permitsList = {};
            newState.permitsListError = error;

            return newState
        }
        default : {

            return state;
        }
    }

}