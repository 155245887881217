import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { DashboardReportAction } from "../actions/DashboardReportAction";


export const  DashboardReportApi= ({form})=>{


    return (dispatch, getState)=>{

       return _callApi(
            form,
            END_POINT.DASHBOARD_REPORT.END_POINT,
            END_POINT.DASHBOARD_REPORT.POST,
            {}).then(res=>{

                let { result, error } = res.data.payload

                if(!error){
                    
                    dispatch(DashboardReportAction({result, error}))
                }


                return res;
            })
    }

}