import React, { Component } from 'react';
import { Formik, Form } from 'formik'
import * as Yup from 'yup';
import ReactLoading from 'react-loading';
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import logo from '../../assets/images/logo.png'
import { VALIDATE } from '../../Constants/ValidationConst';

class RegisterAdmin extends Component {
    constructor(props) {
        super(props);

        this.handleReset = this.handleReset.bind(this)
        this.state = {
            email: "",
            activationKey: "",
            error: {}
        }

    }

    componentWillMount() {

        let { params } = this.props.match

        let { history } = this.props
        if (params.email && params.activationKey) {

            this.setState({ email: params.email, activationKey: params.activationKey })
        }
        else {

            history.push("/adminLogin")
        }

    }

    handleReset(values, formikBeg) {

        formikBeg.setSubmitting(true)
        this.setState({ error: {} })

        let { password } = values

        let form = new FormData()

        form.append("params[email]", this.state.email)
        form.append("params[activationKey]", this.state.activationKey)
        form.append("params[password]", password)
        _callApi(form, END_POINT.RESET_PASSWORD.END_POINT, END_POINT.RESET_PASSWORD.POST, {}).then(res => {

            let { result=null, error=null } = res.data.payload

            let { history } = this.props

            if (!error) {
                formikBeg.setSubmitting(false)
                this.setState({ error: error })

                history.push("/adminLogin")
            }
            else {
                formikBeg.setSubmitting(false)
                this.setState({ error: error })
            }
        })

    }

    render() {


        return (
            <React.Fragment>
                <div className="login">
                    <Formik
                        initialValues={{
                            password: "",
                            confirmPass: ""
                        }}
                        validationSchema={Yup.object().shape({
                            password: Yup.string().required(VALIDATE.PASS_REQ).min(6, VALIDATE.PASS_MIN).max(255,VALIDATE.PASS_MAX),
                            confirmPass: Yup.string().required(VALIDATE.PASS_CONFIRM)
                        })}
                        validate={({ password, confirmPass }) => {

                            let errors = {}

                            if (password !== confirmPass) {
                                errors.confirmPass = VALIDATE.PASS_MATCH
                            }
                            return errors
                        }}
                        validateOnBlur={true}
                        onSubmit={this.handleReset}
                        render={({ values, handleSubmit, handleBlur, handleChange, errors, touched, isSubmitting }) => (
                            <Form>
                                <div className="login-wrapper">
                                    <div className="image">
                                        <img src={logo} />
                                        <span style={{ fontSize: "12px", color: "red" }} >{this.state.error && this.state.error.fileName}</span>
                                    </div>
                                    <div className="login-form">
                                        <div className="form-group">
                                            <label>New Password</label>
                                            <input type="password" name="password" onBlur={handleBlur} onChange={handleChange} value={values.password} placeholder="New Password" className="form-control" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{errors.password && touched.password && errors.password}</span>
                                        </div>
                                        <div className="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" name="confirmPass" onBlur={handleBlur} onChange={handleChange} value={values.confirmPass} placeholder="Confirm Password" className="form-control" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{errors.confirmPass && touched.confirmPass && errors.confirmPass}</span>
                                        </div>
                                    </div>
                                    <div className="common-save-btn mt-4">

                                        <button disabled={isSubmitting} type="submit" className="btn credential-btn">
                                            {(isSubmitting) ?
                                                <ReactLoading
                                                className="btn-loader"
                                                type="bars"
                                                color="white"
                                                height={"auto"}
                                                width={'10%'} />:
                                                "Register"}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    />
                </div>
            </React.Fragment>
        )
    }
}

export default RegisterAdmin;