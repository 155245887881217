import React, { Component } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, Row, Col, InputGroup, Input } from 'reactstrap';
import Select from 'react-select';
import { APP_CONST } from '../../../../Constants/AppConstants';
import remove from "../../../../assets/images/remove.png";
import { VALIDATE } from '../../../../Constants/ValidationConst';
import DatePicker from "react-datepicker";
export default class EditResidentModal extends Component {

    constructor(props) {
        super(props)

        this.state = {
            selectedPermit: {},
            parkingList: [],
            editPermit: {
                locationId: { value: "", label: "" },
                permitType: { value: "", label: "" }
            },
        }
    }

    onChange = expDate => this.value=expDate

    componentWillReceiveProps(nextProps) {

        let { modal, parkingList, selectedPermit, editPermit } = nextProps

        this.setState((prevState) => {


            return {
                selectedPermit: selectedPermit,
                modal: modal,
                parkingList: parkingList,
                editPermit: editPermit
            }
        })

    }


    handleOptionChange = (setFieldValue, item, name) => {

        setFieldValue(name, item)

    }


    render() {

        const permitTypeOptions = [
            { value: "3", label: "Monthly" },
            // { value: "4", label: "Yearly" },
        ]
// ==================THIS VALUE WE ARE GETTING IN PROPS AND SET IN componentWillReceiveProps==========//
        let parkingList = this.state.parkingList;
        let selectedPermit = this.state.selectedPermit;
        let editPermit = this.state.editPermit
// =============================================END==================================================//
        
        //========THIS IS FOR DROPDOWN VALUES======//
        let year = null
        let locationId = null
        let reservedType = null
        let reservedVal = null
        let expDate = null;
        // =================END====================//

        if (editPermit["locationId"]) {

            this.props.parkingList.options.forEach((item, index) => {

                if (item.value === editPermit.locationId.value) {

                    locationId = item
                }
            })
        }
        if (editPermit["permitType"]) {


            permitTypeOptions.forEach((item, index) => {

                // console.log(item, editPermit.permitType);

                if (item.value === editPermit.permitType.value) {

                    reservedType = item
                }
            })
        }
        if (selectedPermit["reservedVal"]) {

//============================ REASON FOR THIS LOGIC=========================//

// IF RESERVE TYPE VALUE IS "2" OR "3" IT MEANS reservedVal will have "1" from backend because this is the value that we sent during adding new permit by default
// so in that case reservedVal should have nothing at all i.e. null
//  otherwise it would have selected hours value


//=======================================END==================================//
            if  (selectedPermit["reservedType"] === "2"  )  {

                reservedVal = null

            }else if(selectedPermit["reservedType"] === "3"){


                APP_CONST.months.forEach(item => {
                    
                    if(item.value === selectedPermit.reservedVal) {
        
                        reservedVal = item
                    }
                })

            }
            else{
                
                
                APP_CONST.HOURS.forEach(item => {
                    
                    if(item.value === selectedPermit.reservedVal) {
                        
                        reservedVal = item
                    }
                })
                
            }  
            // console.log(reservedVal)
                

            
        }

// =======================FOR YEAR DROPDOWN OPTIONS=====================// 
        // Dates from 100 years behind by now
        let startYear = new Date().getFullYear() + 1
        // console.log(startYear);
        let yearBehind100 = startYear - 99

        let years = []

        for (let i = yearBehind100; i <= startYear; i++) {
            // console.log(i);
            years.push(i)
        }


        let options = years.reverse().map(item => {
            return { value: item + "", label: item + "" }
        })

//=======================================END===============================//

        if (selectedPermit["year"]) {

            year = { value: selectedPermit.year, label: selectedPermit.year }
        }
        expDate = new Date(selectedPermit["expiredAt"]*1000);
        
        return <>
            <div>

                <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={this.props.modal} toggle={this.props.toggleEditPermit} className={this.props.className}>
                    <ModalHeader >Edit Permit</ModalHeader>
                    <ModalBody>
                        <Formik
                            key="edit record"
                            initialValues={
                                {
                                    // company:"" ,
                                    user: (selectedPermit) ? selectedPermit.user : "",
                                    reserveLocationID: (selectedPermit) ? selectedPermit.reserveLocationID : "",
                                    phone: (selectedPermit) ? selectedPermit.phone : "",
                                    email: (selectedPermit) ? selectedPermit.email : "",

                                    locationId: locationId,
                                    reservedType: { value: "3", label: "Monthly" },
                                    reservedVal: reservedVal,
                                    // (this.state.selectedPermit["reservedType"]),
                                    licenseNumber: (selectedPermit) ? selectedPermit.licenseNumber : '',
                                    // reserved: new Date(),
                                    // expires: new Date(),
                                    make: (selectedPermit) ? selectedPermit.make : "",
                                    model: (selectedPermit) ? selectedPermit.model : "",
                                    year: year,
                                    expDate : expDate,
                                    state: (selectedPermit) ? selectedPermit.state : '' ,
                                    decal: (selectedPermit) ? selectedPermit.decal: "",
                                    name: (selectedPermit) ? selectedPermit.name: '',
                                    permitAddress:(selectedPermit) ? selectedPermit.permitAddress : '',
                                }
                            }
                            enableReinitialize={true}
                            isInitialValid={true}
                            validationSchema={Yup.object().shape({

                                phone: Yup.string()                                    
                                    .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                    .min(10, VALIDATE.PHONE_MIN)
                                    .max(15, VALIDATE.PHONE_MAX),

                                email: Yup.string()
                                    .email(VALIDATE.EMAIL_INVALID)
                                    .required(VALIDATE.EMAIL_REQ),

                                locationId: Yup.object({
                                    value: Yup.string(),
                                    label: Yup.string()
                                }).nullable(true).required("Please select a parking lot"),

                                // reservedType: Yup.object().shape({
                                //     value: Yup.string(),
                                //     label: Yup.string()
                                // }).nullable(true).required("Please select an option"),

                                // reservedVal: Yup.object().shape({
                                //     value: Yup.string(),
                                //     label: Yup.string()
                                // }).when(
                                //     "reservedType",
                                //     {
                                //         is: (reservedType) => (reservedType && ( reservedType.value === "4" || reservedType === null)),
                                //         then: Yup.object().nullable(true),
                                //         otherwise: Yup.object().shape({
                                //             value: Yup.string(),
                                //             label: Yup.string()
                                //         }).nullable(true).required("Please select an options")
                                //     }),

                                licenseNumber: Yup.string().required("Plate no. is required").max(255, "You reached the limit of characters"),

                                make: Yup.string().required("Enter a model no.").max(255, "You reached the limit of characters"),
                                model: Yup.string().required("Enter a model no.").max(255, "You reached the limit of characters"),
                                year: Yup.object().shape({
                                    value: Yup.string(),
                                    label: Yup.string()
                                }).nullable(true).required("Please select a Year"),
                                state: Yup.string().required("Enter state name").max(255, "You reached the limit of characters"),
                                name: Yup.string().required("Enter an Owner's name").max(50,"Maximum characters exceeded")
                                // decal: Yup.string().required("Decal is required")

                            })}
                            onSubmit={this.props.handleEditSubmit}
                            render={({ handleChange, isValid, touched, errors, handleBlur, handleSubmit, values, setFieldValue, isSubmitting }) => (
                                <Form>
                                    <div className="popup-form">

                                        <Row>
                                            <Col md="6">
                                                <InputGroup>
                                                    <label class="mr-2">Name</label>
                                                    <input type="text" name="name" value={values.name} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.phone && errors.phone && errors.phone}</span>
                                                </InputGroup>
                                            </Col>
                                            <Col md="6">
                                                <InputGroup>
                                                    <label class="mr-2">Email</label>
                                                    <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.email && errors.email && errors.email}</span>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2">
                                            <Col md="6">
                                                <InputGroup>
                                                    <label class="mr-2">Phone</label>
                                                    <input type="text" name="phone" value={values.phone} onBlur={handleBlur} onChange={handleChange} className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.phone && errors.phone && errors.phone}</span>
                                                </InputGroup>
                                            </Col>
                                            <Col md="6">
                                                <InputGroup>
                                                    <label class="mr-2">Address</label>
                                                    <textarea className="form-control" name="permitAddress" value={values.permitAddress} onBlur={handleBlur} onChange={handleChange} >{values.permitAddress}</textarea>
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.permitAddress && errors.permitAddress && errors.permitAddress}</span>
                                                </InputGroup>
                                            </Col>

                                            



                                        </Row>
                                        <Row className="pt-2">


                                            {/* <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Permit Type</label>
                                                    <div className="input-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.reservedType}
                                                            // onChange={handleChange}
                                                            isOptionSelected
                                                            className="selectlocation"
                                                            options={permitTypeOptions.filter((item, index) => index !== 0)}
                                                            name="reservedType"
                                                            isSearchable={false}
                                                            // defaultInputValue={}
                                                            onBlur={handleBlur}
                                                            onChange={(item) =>{ 
                                                            
                                                                this.props.handleCurrentOptionChange({ setFieldValue, item, name: "reservedType" })
                                                            
                                                                if(item.value !== "1") {
                                                                    this.props.handleCurrentOptionChange({setFieldValue, item:null, name:"reservedVal"})
                                                                } else {

                                                                    // setFieldError("reservedVal","Please select hours")
                                                                    this.props.handleCurrentOptionChange({setFieldValue, item:null, name:"reservedVal"})
                                                                }
                                                            }}
                                                            placeholder={"Select a permit type"}
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.reservedType && errors.reservedType && errors.reservedType}</span>
                                                    </div>
                                                </InputGroup>
                                            </Col> */}
                                            <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Parking Lot</label>
                                                    <div className="input-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.locationId}
                                                            // onChange={handleChange}
                                                            options={this.props.parkingList.options.filter((item, index) => index !== 0)}
                                                            className="selectlocation"
                                                            name="locationId"
                                                            isSearchable={true}
                                                            onBlur={handleBlur}
                                                            onChange={(item) => this.props.handleCurrentOptionChange({ setFieldValue, item, name: "locationId" })}
                                                            placeholder={"Select a parking lot"}
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.locationId && errors.locationId && errors.locationId}</span>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                            <Col md="6" >
                                                <InputGroup>
                                                    <label class="mr-2">Decal #</label>
                                                    <input type="text" name="decal" value={values.decal} onChange={handleChange} onBlur={handleBlur} className="form-control" autoComplete="false" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.decal && errors.decal && errors.decal}</span>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                        <Row className="pt-2 pb-2">
                                        <Col md="6">
                                            <InputGroup class="pt-2">
                                                <label class="mr-2">Reserved To</label>
                                                {/* <DateTimePicker
                                                    className="mr-2"
                                                    name="expDate"
                                                    onChange={(item) => this.props.handleCurrentOptionChange({ setFieldValue, item, name: "expDate" })}
                                                    value={values.expDate}
                                                /> */}
                                                <DatePicker
                                                        selected={values.expDate}
                                                        // value= { values.expDate }
                                                        onChange={(item) => this.props.handleCurrentOptionChange({ setFieldValue, item, name: "expDate" })}
                                                        name="expDate"
                                                        autoComplete="off"
                                                        showTimeSelect
                                                        className="form-control"
                                                        //   onBlur={handleBlur}
                                                        minTime={
                                                            this.value && this.value.getDate() === this.getDateUnit("date")
                                                            ? new Date(
                                                                this.getDateUnit("year"),
                                                                this.getDateUnit("month"),
                                                                this.getDateUnit("date"),
                                                                this.getDateUnit("hours"),
                                                                this.getDateUnit("minutes"),
                                                                this.getDateUnit("seconds")
                                                                )
                                                            : null
                                                        }
                                                        maxTime={
                                                            this.value && this.value.getDate() === this.getDateUnit("date")
                                                            ? new Date(
                                                                this.getDateUnit("year"),
                                                                this.getDateUnit("month"),
                                                                this.getDateUnit("date"),
                                                                23,
                                                                59,
                                                                59
                                                                )
                                                            : null
                                                        }
                                                        dateFormat="MM / dd / yyyy h:mm aa"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={5}
                                                        minDate={new Date()}
                                                        disabledKeyboardNavigation
                                                        onKeyDown={e => {
                                                            e.nativeEvent.preventDefault();
                                                        }}
                                                        />
                                            </InputGroup>
                                        </Col>
                                            
                                            <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Plate #</label>
                                                    <input
                                                        type="text"
                                                        name="licenseNumber"
                                                        value={values.licenseNumber}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="form-control" />

                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.licenseNumber && errors.licenseNumber && errors.licenseNumber}</span>

                                                </InputGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                        {/* <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Reserved </label>
                                                    <div className="input-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.reservedVal}
                                                            onChange={handleChange}
                                                            options={ values.reservedType && values.reservedType.value === '1' ? APP_CONST.HOURS : values.reservedType && values.reservedType.value === '3' ?  APP_CONST.months : APP_CONST.days }
                                                            className="selectlocation"
                                                            name="reservedVal"
                                                            isSearchable={false}
                                                            isDisabled={(values.reservedType && values.reservedType.value === '4')}
                                                            onBlur={handleBlur}
                                                            onChange={(item) => this.props.handleCurrentOptionChange({ setFieldValue, item, name: "reservedVal" })}
                                                            placeholder={ values.reservedType && values.reservedType.value === '1' ? "Select hours" : values.reservedType && values.reservedType.value === '3' ? "Select month" : 'daily'  }
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{errors.reservedVal && errors.reservedVal}</span>

                                                    </div>
                                                </InputGroup>
                                            </Col> */}


                                        </Row>

                                        <Row>
                                            <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Make</label>
                                                    <input
                                                        type="text"
                                                        name="make"
                                                        value={values.make}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.make && errors.make && errors.make}</span>

                                                </InputGroup>
                                            </Col>
                                            <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Model</label>
                                                    <input
                                                        type="text"
                                                        name="model"
                                                        value={values.model}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        className="form-control" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.model && errors.model && errors.model}</span>

                                                </InputGroup>
                                            </Col>
                                            <Col md="6">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">State</label>
                                                    <input name="state" value={values.state} onBlur={handleBlur} onChange={handleChange} type="text" className="form-control" autoComplete="false" />
                                                    <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.state && errors.state}</span>
                                                </InputGroup>
                                            </Col>
                                            <Col md="6 pt-2">
                                                <InputGroup class="pt-2">
                                                    <label class="mr-2">Vehicle Year</label>
                                                    <div className="input-box-inputcls filters-dropdown">
                                                        <Select
                                                            value={values.year}
                                                            onChange={(item) => this.props.handleCurrentOptionChange({ setFieldValue, item, name: "year" })}
                                                            options={options}
                                                            className="selectlocation"
                                                            name="year"
                                                            onBlur={handleBlur}
                                                            minMenuHeight={10}
                                                            placeholder={"Select a Year"}
                                                        />
                                                        <span className="permiterror" style={{ fontSize: "12px", color: "red" }} >{touched.year && errors.year && errors.year}</span>
                                                    </div>
                                                </InputGroup>
                                            </Col>
                                        </Row>

                                        <input name="reserveLocationID" type="hidden" defaultValue={values.reserveLocationID} />
                                        <input name="user" type="hidden" defaultValue={values.user} />
                                        <div className="row mt-3 mb-3">
                                            <div className="col-sm-4 col-6">
                                                <div onClick={()=>this.props.toggleSuspendModal() } className="row align-items-center">
                                                    <span    className="pointer-cursor"><img src={remove} width="20" /></span>
                                                    <span className="pointer-cursor">&nbsp; {(+this.state.selectedPermit.isSuspended) === 0 ?  "SUSPEND USER" : "UNSUSPEND USER"}</span>
                                                </div>
                                            </div>
                                            <div className="col-sm-4 offset-sm-4 col-6">
                                                <div className="row align-items-center">
                                                    <button 
                                                    disabled={isSubmitting}
                                                     type="submit" class="btn btn-common padding-top-bottom"> {(isSubmitting)? "Submitting" : "Save"}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Form>)}
                        />
                    </ModalBody>

                </Modal>
            </div>

        </>
    }
}