import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Formik, getIn, FieldArray, Form, Field, } from 'formik'
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import _callApi from '../../../Services/baseService';
import { END_POINT } from '../../../Constants/ApiEndPoints';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';
import axios from 'axios'
import * as moment from 'moment';
import * as SHA1 from 'sha1';
import GoogleMap from './GoogleMap';
import images from "../../../assets/images/images.png";
import black_minus from "../../../assets/images/minus.png";
import black_plus from "../../../assets/images/black-plus.png";
import LocationSearchInput from './google';
import { VALIDATE } from '../../../Constants/ValidationConst';
import { connect } from 'react-redux';
import { SetAuthTokenApi } from '../../../store/api-actions/SetAuthTokenApi';



function mapDispatchToProps(dispatch) {

    return {
        setAuthToken: () => dispatch(SetAuthTokenApi())
    }

}

class AddnewLot extends Component {
    constructor(props) {
        super(props);
        this.file = React.createRef()
        this.progressBar = React.createRef()
        this.state = {
            uploadedImage: {
                selectedImage: null,
                progress: null,
                error: ""
            },
            position: {
                lat: null,
                lng: null,
                address: ""
            },
            posError: "",
            update: false,
            disableSave: false,

            clickedInAddressField: false,

            addNewError: ""
        }
    }


    handleSubmit = (values, formikBeg) => {

        // console.log(values)
        this.setState({ addNewError: "" })

        let { company, address, dailyRate, hourlyRate, hasMonthly, monthlyRate, contactName, phone, lotCapacity, contactEmail, surgeEvents, promotions } = values

        surgeEvents = JSON.stringify(surgeEvents)
        promotions = JSON.stringify(promotions)
        let form = new FormData()

        let uploadedImage = this.state.uploadedImage

        if (this.state.uploadedImage.selectedImage === null) {


            uploadedImage.error = "Please select an image"

            this.setState({ uploadedImage: uploadedImage })


        }
        else {

            uploadedImage.error = ""

            this.setState({ uploadedImage: uploadedImage })
        }

        if (this.state.position.lat === null) {


            this.setState({ posError: "Please select a location by searching in address field " })

        } else {

            this.setState({ posError: "" })

            this.setState((preState) => {

                return {
                    disableSave: false
                }
            })



        }



        if (this.state.position.lat !== null && this.state.uploadedImage.selectedImage !== null) {


            this.setState({ posError: "" })

            form.append("params[company]", company)
            form.append("params[address]", address)
            form.append("params[dailyRate]", dailyRate)
            form.append("params[hourlyRate]", hourlyRate)
            if (hasMonthly) {

                form.append("params[hasMonthly]", 1)
                form.append("params[monthlyRate]", monthlyRate)
            }
            else {

                form.append("params[hasMonthly]", 0)
                form.append("params[monthlyRate]", "")
            }
            form.append("params[contactName]", contactName)
            form.append("params[phone]", phone)
            form.append("params[imgUrl]", this.state.uploadedImage.selectedImage)
            form.append("params[lotCapacity]", lotCapacity)
            form.append("params[contactEmail]", contactEmail)
            form.append("params[surgeEvents]", surgeEvents)
            form.append("params[promotions]", promotions)
            form.append("params[latitude]", this.state.position.lat)
            form.append("params[longitude]", this.state.position.lng)
            form.append("params[authToken]", localStorage.getItem("jwtToken"))

            _callApi(form,
                END_POINT.ADD_PARKING_LOT.END_POINT,
                END_POINT.ADD_PARKING_LOT.POST,
                {}).then(res => {

                    let { result = null, error = null } = res.data.payload

                    if (error === 401) {
                        this.props.setAuthToken()
                    }
                    else if (!error) {

                        this.props.history.push("/main/parking-lot-list")
                    } else {

                        this.setState({ addNewError: error.message })
                    }

                })
        }
        else {




        }
    }

    componentDidCatch(error, info) { }

    static getDerivedStateFromError() {
        return { hasError: true };
    }

    handleFile = (e) => {



        if (e.target.files.length) {


            this.setState((preState) => {

                return {
                    disableSave: true
                }
            })
            let form = new FormData()

            let time = new moment().utc(new Date()).valueOf()

            let params = `timestamp=${time}` + `${END_POINT.CLOUDNARY.API_SECRET}`

            let auth = SHA1(params)

            let uploadedImage = this.state.uploadedImage

            // uploadedImage.progress = progress
            uploadedImage.error = ""
            this.setState({ uploadedImage: uploadedImage })

            //  console.log(params)

            form.append("file", e.target.files[0])
            form.append("timestamp", time)
            //  form.append("file",this.state.selectedFile)
            form.append("api_key", END_POINT.CLOUDNARY.API_KEY)
            form.append("signature", auth)

            axios.post(
                "https://api.cloudinary.com/v1_1/sylab/image/upload",
                form,
                {
                    onUploadProgress: (ProgressEvent => {

                        let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)
                        this.progressBar.current.style.width = `${progress}%`
                        this.progressBar.current.style.backgroundColor = "#588cdd";
                        this.progressBar.current.style.color = "white";
                        this.progressBar.current.style.paddingLeft = "20px";
                        this.progressBar.current.style.paddingRight = "20px";
                        this.progressBar.current.innerText = progress + "%..."

                        if (progress === 100) { }

                        this.setState((preState) => {

                            let uploadedImage = preState.uploadedImage

                            if (progress === 100) {

                                uploadedImage.progress = null
                                this.progressBar.current.innerText = "...loading"

                                return { uploadedImage }
                            }
                            else {

                                uploadedImage.progress = progress

                                return { uploadedImage }
                            }
                        })
                    })
                }
            ).then(res => {

                let { secure_url } = res.data

                this.progressBar.current.style = {}
                this.progressBar.current.innerText = ""

                let uploadedImage = this.state.uploadedImage

                uploadedImage.selectedImage = secure_url
                uploadedImage.error = ""

                this.setState((preState) => {

                    return {
                        disableSave: false,
                        uploadedImage: uploadedImage
                    }
                })
            }, error => {
                this.progressBar.current.style = {}
                this.progressBar.current.innerText = ""

            })
        }
    }

    handleDateChange = (setFieldValue, value, fieldName) => {

        setFieldValue(fieldName, value)

    }

    openCloudnaryWidget = () => {

        window.cloudinary.openUploadWidget({
            cloudName: "sylab", api_key: END_POINT.CLOUDNARY.API_KEY, resourceType: "image", context: { alt: "image is not available", caption: "image" }
        }, (error, result) => {

        })
    }

    getLatLng = ({ latLng, address }) => {

        let pos = this.state.position

        pos.lat = latLng.lat
        pos.lng = latLng.lng
        pos.address = address
        this.setState(() => ({ position: pos }))

    }

    showErrorOnClick = (isShow) => {

        this.setState((preState) => {

            let posError = preState.posError
            let position = preState.position

            if (!position.lat) {

                posError = ""
            }

            return { posError, clickedInAddressField: isShow }
        })
        // this.setState({clickedInAddressField:isShow})

    }
    render() {
        return (
            <React.Fragment>
                <div className="row ">
                    <div className="header-panel common-padding-left-right">
                        <div className="add-edit-header">
                            <h1>Add New Parking Lot</h1>
                            <span style={{ fontSize: "12px", color: "red" }} >{this.state.addNewError && this.state.addNewError}</span>

                        </div>
                    </div>
                </div>

                <Formik
                    initialValues={{
                        // imgUrl:this.state.uploadedImage.selectedImage ,
                        company: "",
                        address: "",
                        dailyRate: "",
                        hourlyRate: "",
                        hasMonthly: false,
                        monthlyRate: "",
                        contactName: "",
                        phone: "",
                        lotCapacity: "",
                        contactEmail: "",
                        surgeEvents: [],
                        promotions: []
                    }}
                    enableReinitialize={true}

                    validateOnBlur={true}
                    validationSchema={Yup.object().shape({

                        company: Yup.string()
                            .required("Company name is required")
                            .max(255, VALIDATE.NAME_MAX)
                            .matches(/^[a-zA-Z0-9_ ]*$/, "Enter a valid name"),

                        address: Yup.string()
                            .required("Address is required"),

                        dailyRate: Yup.string().required("Daily rate is required")
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),

                        hourlyRate: Yup.string().required("Hourly rate is required")
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),

                        hasMonthly: Yup.boolean(),

                        monthlyRate: Yup.string()
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                        //   .positive("Monthly rate must be a positive value"),

                        contactName: Yup.string().max(255, VALIDATE.NAME_MAX)
                            .required(VALIDATE.NAME_REQ)
                            .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),

                        phone: Yup.string().required(VALIDATE.PHONE_REQ)
                            .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                            .min(10, VALIDATE.PHONE_MIN)
                            .max(15, VALIDATE.PHONE_MAX),

                        lotCapacity: Yup.string()
                            .required("Lot Capacity is required")
                            .matches(/^[1-9]\d*(\d+)?$/, "Lot capacity should be more than one"),

                        contactEmail: Yup.string()
                            .email(VALIDATE.EMAIL_INVALID)
                            .max(50, VALIDATE.EMAIL_MAX)
                            .required(VALIDATE.EMAIL_REQ),

                        surgeEvents: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({
                                    hourlyPrice: Yup.string().required("Hourly price is required")
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Hourly price must be a positive value"),

                                    dailyPrice: Yup.string().required("Daily price is required")
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Daily price must be a positive value"),

                                    fromDate: Yup.date().required("From date is required"),

                                    toDate: Yup.date().required("To date is required")
                                })
                            ),
                        promotions: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({

                                    title: Yup.string()
                                        .max(255, VALIDATE.STRING_MAX)
                                        .required("title is required"),

                                    promoCode: Yup.string()
                                        .max(255, VALIDATE.STRING_MAX)
                                        .required("promoCode is required"),

                                    promoValue: Yup.string().required("Promo value is required")
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),

                                    expiredAt: Yup.date().required("From date is required"),
                                })
                            )
                    })}
                    onSubmit={this.handleSubmit}
                    render={({ handleBlur, handleChange, isValid, validateField, errors, values, setFieldValue, touched }) => (
                        <Form>
                            <div className="common-container dollor-sign">
                                {/* <button onClick={()=>{this.props.history.push("/main/parking-lot-list")}} >go</button> */}
                                <div className="rows">
                                    <div className="rows-border-bottom pb-2 ">
                                        <div className="col-img col-img-wrapper">
                                            {/* <div ref={this.progressBar} ></div> */}
                                            <img onClick={(this.state.disableSave === false) ? () => this.file.click() : null} src={(this.state.uploadedImage.selectedImage) ? this.state.uploadedImage.selectedImage : images} className="w-100" />
                                            <div ref={this.progressBar} >
                                                {/* <h4>Nature</h4> */}
                                                {/* <p>uploading...</p> */}
                                            </div>
                                            {/* <span style={{color:"green"}} >{this.state.uploadedImage.progress }</span> */}
                                            <input style={{ display: "none" }} type="file" onChange={(e) => { this.handleFile(e) }} ref={node => this.file = node} />

                                            {/* <span className="msg-error">{errors.imgUrl && errors.imgUrl}</span> */}
                                            <span className="msg-error-img">{this.state.uploadedImage.error && this.state.uploadedImage.error}</span>

                                            <div onClick={(this.state.disableSave === false) ? () => this.file.click() : null} id="cloudnary-upload" className="new-photo over-new-photo">
                                                {/* <Image cloudName={END_POINT.CLOUDNARY.CLOUD_NAME}  /> */}
                                                <img src={black_plus} />
                                                {/* <p> NEW PHOTO</p> */}

                                            </div>
                                            <div style={{ maxWidth: "600px", width: "100%", margin: "0 auto", position: "relative", maxHeight: "100px" }} className="maps">
                                                <GoogleMap type={1} position={this.state.position} getLatLng={this.getLatLng} />
                                            </div>
                                        </div>

                                        <div className="col-form">
                                            <div className="row">
                                                <div className="col-4 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Company Name
                                    </label>
                                                        <input
                                                            value={values.company}
                                                            type="text"
                                                            className="form-control"
                                                            name="company"
                                                            onChange={handleChange}
                                                            placeholder="Enter company name"
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.company && touched.company && errors.company}</span>
                                                    </div>

                                                </div>
                                                <div className="col-8  mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Address
                                        </label>

                                                    </div>
                                                    <LocationSearchInput
                                                        name="address"
                                                        handleChange={setFieldValue}
                                                        setAddress={this.setAddress}
                                                        getLatLng={this.getLatLng}
                                                        showErrorOnClick={this.showErrorOnClick}
                                                    />

                                                    {/* <span className="msg-error">{this.state.posError && this.state.posError}</span> */}
                                                    <span className="msg-error">{(this.state.posError) ? this.state.posError : this.state.clickedInAddressField && !values.address && "please enter correct address"}</span>

                                                </div>
                                            </div>
                                            {/* <div className="row">
                                                <div class="col-md-12 pt-1 pb-4">
                                                    <label class="input-add-new">Stripe Account Email</label>
                                                    <input defaultValue="" className="location-search-input form-control" type="text" />
                                                </div>
                                                <div class="col-md-4"></div>


                                            </div> */}
                                            <div className="row pb-4">
                                                <div className="col-4 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Contact Name
                                    </label>
                                                        <input
                                                            value={values.contactName}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Enter contact name"
                                                            name="contactName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.contactName && touched.contactName && errors.contactName}</span>
                                                    </div>

                                                </div>
                                                <div className="col-3 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Contact Phone
                                    </label>
                                                        <input
                                                            value={values.phone}
                                                            type="text"
                                                            className="form-control"
                                                            name="phone"
                                                            placeholder="Enter phone no."
                                                            onChange={handleChange}
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.phone && touched.phone && errors.phone}</span>
                                                    </div>

                                                </div>
                                                <div className="col-5 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Contact Email
                                    </label>
                                                        <input
                                                            value={values.contactEmail}
                                                            type="text"
                                                            className="form-control"
                                                            name="contactEmail"
                                                            placeholder="Enter contact email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.contactEmail && touched.contactEmail && errors.contactEmail}</span>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-3 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Lot Capacity
                                    </label>
                                                        <input
                                                            value={values.lotCapacity}
                                                            type="text"
                                                            className="form-control"
                                                            name="lotCapacity"
                                                            placeholder="Enter lot capacity"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            autoComplete="false" />
                                                        <span className="msg-error">{errors.lotCapacity && touched.lotCapacity && errors.lotCapacity}</span>
                                                    </div>

                                                </div>
                                                <div className="col-3 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Hourly
                                    </label>
                                                        <span className="dollor">$</span>
                                                        <input
                                                            value={values.hourlyRate}
                                                            type="text"
                                                            className="form-control dollor-form"
                                                            name="hourlyRate"
                                                            onChange={handleChange}
                                                            placeholder="Enter hourly price"
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.hourlyRate && touched.hourlyRate && errors.hourlyRate}</span>
                                                    </div>

                                                </div>
                                                <div className="col-3 mb-3">
                                                    <div className="form-group">
                                                        <label>
                                                            Daily
                                    </label>
                                                        <span className="dollor">$</span>
                                                        <input
                                                            value={values.dailyRate}
                                                            type="text" className="form-control dollor-form"
                                                            name="dailyRate"
                                                            onChange={handleChange}
                                                            placeholder="Enter daily price"
                                                            onBlur={handleBlur} />
                                                        <span className="msg-error">{errors.dailyRate && touched.dailyRate && errors.dailyRate}</span>
                                                    </div>

                                                </div>
                                                <div className="col-3 mb-3">
                                                    <div className="form-group col-monthly-checktoggle">
                                                        <label>
                                                            <input name="hasMonthly" className="check-in" type="checkbox" value={values.hasMonthly} onChange={(e) => {
                                                                if (!values.hasMonthly) this.handleDateChange(setFieldValue, values.monthlyRate, "monthlyRate"); else this.handleDateChange(setFieldValue, "", "monthlyRate"); handleChange(e)
                                                            }} />
                                                            {/* setFieldValue("monthlyRate","") */}
                                                            <div className="slider"> </div>&nbsp; Monthly
                                    </label>

                                                        <span className="dollor">$</span>
                                                        <input
                                                            value={values.monthlyRate}
                                                            type="text"
                                                            className="form-control dollor-form"
                                                            name="monthlyRate"
                                                            placeholder="Enter monthly rate"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            disabled={!values.hasMonthly} />
                                                        <span className="msg-error">{errors.monthlyRate && touched.monthlyRate && errors.monthlyRate}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="clearfix"></div>
                                    </div>
                                    <FieldArray

                                        name="surgeEvents"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.surgeEvents && values.surgeEvents.length > 0 ?
                                                    values.surgeEvents.map((event, index) => (
                                                        <div className="col-img-wrapper d-flex align-items-center" key={index} >

                                                            <div className="col-img mt-auto">
                                                                <div className="new-photo"  >
                                                                    <img onClick={() => arrayHelpers.remove(index)} src={black_minus} />
                                                                    <p>ADD SURGE EVENT</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-form">
                                                                <div className="row">
                                                                    <div className="col-3">
                                                                        <div className="form-group">
                                                                            <label>Hourly</label>
                                                                            <span className="dollor">$</span>
                                                                            <input
                                                                                value={event.hourlyPrice}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                name={`surgeEvents[${index}].hourlyPrice`}
                                                                                type="text"
                                                                                placeholder="Enter hourly price"
                                                                                className="form-control dollor-form" />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    // isArray(touched.surgeEvents)
                                                                                    getIn(touched, `surgeEvents[${index}].hourlyPrice`)
                                                                                    // && getIn(touched, `surgeEvents${[index]}.hourlyPrice`)  
                                                                                    && getIn(errors, `surgeEvents[${index}].hourlyPrice`)
                                                                                    && getIn(errors, `surgeEvents[${index}].hourlyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Daily
                                            </label><span className="dollor">$</span>
                                                                            <input
                                                                                value={event.dailyPrice}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                name={`surgeEvents.[${index}].dailyPrice`}
                                                                                type="text" className="form-control dollor-form"
                                                                                placeholder="Enter daily price"

                                                                            />
                                                                            {/* { JSON.stringify(touched.surgeEvents[index]) } */}
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    // && touched.surgeEvents[index].dailyPrice 
                                                                                    getIn(touched, `surgeEvents[${index}].dailyPrice`)
                                                                                    && getIn(errors, `surgeEvents[${index}].dailyPrice`)
                                                                                    && getIn(errors, `surgeEvents[${index}].dailyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>


                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                From
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={event.fromDate}
                                                                                // value= { event.fromDate }
                                                                                onChange={(value) => this.handleDateChange(setFieldValue, value, `surgeEvents[${index}].fromDate`)}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`surgeEvents[${index}].fromDate`}
                                                                                className="form-control form-width w-100"
                                                                                minDate={new Date()}
                                                                                maxDate={values.toDate}
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                onKeyDown={(e) => { e.nativeEvent.preventDefault() }}

                                                                            // minTime={new Date().getHours()}
                                                                            // maxTime={}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index}.fromDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                To
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={+event.fromDate > +event.toDate ? event.fromDate : event.toDate}
                                                                                // value= { event.toDate }
                                                                                onChange={(value) => this.handleDateChange(setFieldValue, value, `surgeEvents[${index}].toDate`)}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`surgeEvents[${index}].toDate`}
                                                                                className="form-control form-width w-100"
                                                                                minDate={(+new Date() < +event.fromDate) ? event.fromDate : new Date()}
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                // minTime={(+new Date() < +event.fromDate) ? event.fromDate.getHours() : new Date()}
                                                                                // maxTime={ new Date().getHours() }
                                                                                onKeyDown={(e) => { e.nativeEvent.preventDefault() }}
                                                                            // minTime={new Date().getHours()}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index}.toDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>

                                                        </div>
                                                    )) : null
                                                }
                                                <div className="rows rows-border-bottom">
                                                    <div className="col-img-wrapper rows-space">
                                                        <div className="new-photo"  >
                                                            <img onClick={
                                                                (errors.surgeEvents) ?
                                                                    null :
                                                                    () => arrayHelpers.push({ hourlyPrice: "", dailyPrice: "", fromDate: new Date(), toDate: new Date() })}
                                                                src={black_plus} />
                                                            <p>ADD SURGE EVENT</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />


                                    <FieldArray

                                        name="promotions"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.promotions && values.promotions.length > 0 ?
                                                    values.promotions.map((promo, index2) => (
                                                        <div className="col-img-wrapper d-flex align-items-center" key={index2} >

                                                            <div className="col-img mt-auto">
                                                                <div className="new-photo ">
                                                                    <img onClick={() => arrayHelpers.remove(index2)} src={black_minus} />
                                                                    <p>ADD PROMOTION</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-form">
                                                                <div className="row">

                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Promo Value<span className="dollor">$</span>
                                                                            </label>
                                                                            <input
                                                                                value={promo.promoValue}
                                                                                onChange={handleChange}
                                                                                name={`promotions.[${index2}].promoValue`}
                                                                                type="text" className="form-control field-height"
                                                                                // placeholder="Enter promo value"
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `promotions[${index2}].promoValue`)
                                                                                    && getIn(errors, `promotions[${index2}].promoValue`) && getIn(errors, `promotions[${index2}].promoValue`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Title
                                                                            </label>
                                                                            <input
                                                                                value={promo.title}
                                                                                onChange={handleChange}
                                                                                onBlur={handleBlur}
                                                                                name={`promotions.[${index2}].title`}
                                                                                placeholder="Enter promotion title"
                                                                                type="text"
                                                                                className="form-control field-height" />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `promotions[${index2}].title`)
                                                                                    && getIn(errors, `promotions[${index2}].title`) && getIn(errors, `promotions[${index2}].title`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Code
                                                                            </label>
                                                                            <input
                                                                                value={promo.promoCode}
                                                                                onChange={handleChange}
                                                                                name={`promotions.[${index2}].promoCode`}
                                                                                type="text" className="form-control field-height"
                                                                                placeholder="Enter promo code"
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `promotions[${index2}].promoCode`)
                                                                                    && getIn(errors, `promotions[${index2}].promoCode`) && getIn(errors, `promotions[${index2}].promoCode`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Expiration
                                                                            </label>
                                                                            <DatePicker
                                                                                selected={promo.expiredAt}
                                                                                // value= {  }\
                                                                                onChange={(value) => this.handleDateChange(setFieldValue, value, `promotions.[${index2}].expiredAt`)}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`promotions.[${index2}].expiredAt`}
                                                                                className="form-control form-width field-height w-100"
                                                                                minDate={new Date()}
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                onKeyDown={(e) => { e.nativeEvent.preventDefault() }}
                                                                            // minTime={new Date().getHours()}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index2}.fromDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>

                                                        </div>
                                                    )) : null
                                                }

                                                <div className="rows">
                                                    <div className="col-img-wrapper rows-space">
                                                        <div className="new-photo">
                                                            <img onClick={(errors.promotions) ? null : () => arrayHelpers.push({ title: "", promoCode: "", promoValue: "", expiredAt: new Date() })} src={black_plus} />
                                                            <p>ADD PROMOTION</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>



                                <div className="common-save-btn">
                                    {/* <Link to="/parking-lot-list"> */}

                                    <button disabled={this.state.disableSave} onClick={() => this.showErrorOnClick(true)} type="submit" class="btn btn-common ">Save</button>
                                    {/* </Link> */}
                                </div>

                            </div>
                        </Form>
                    )}
                />
                {/*  type=1 is adding new lot and type =2 editing existing lot  */}

                {/* <GoogleMap   position = {this.state.position} getLatLng={this.getLatLng} />   */}
            </React.Fragment>
        )
    }

}

export default AddnewLot = withRouter(connect(null, mapDispatchToProps)(AddnewLot));