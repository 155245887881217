import { ACTIONS } from "../../Constants/Actions";

export const parkingLotsReducer=(state={}, action)=>{


    // console.log(state)
    switch(action.type){

        case ACTIONS.PARKLIST.RECEIVE_PARKLIST :{

            // console.log("===============inside reducer", action.payload)
            let newState = {...{},...state }
            let result = action.payload.result.data
            let error = action.payload.result.error
            let totalParks = action.payload.result.total
          
            newState.total = totalParks
            newState.parkByIDs = result
            newState.error = error
            newState.received = !newState.received;
          
            
            return newState;
        }
        case  ACTIONS.PARKLIST.FETCHING_LIST: {

            let copy = {...{}, ...state }

            copy.isFetching = action.payload;
            // copy.total = 0

            return copy;
        }

        case ACTIONS.PARKLIST.RECEIVED_LIST : {

            let copy = {...{}, ...state}

            copy.isFetching = action.payload;

            return copy
        }

        case ACTIONS.PARKLIST.FAILED_PARKLIST:{

            let copy = { ...{}, ...state }

            copy.isFetching = false;
            
            copy.parkByIDs = []

            copy.error = action.payload.error

// =============SET TOTAL RECORDS TO 0 IF FOUND AN ERROR=================//
            copy.total = 0 ;
            // console.log(copy)

            return copy
            
        }

        case ACTIONS.PARKLIST.GET_PARK_BY_ID : {

            let copy = {...{}, ...state}


            let { locationId} = action.payload;

            // console.log("location id = ",locationId)

             copy.currentParkLot =  copy.parkByIDs[locationId]

            return copy
        }

        default:{

            return state;
        } 
    }


}