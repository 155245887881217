import { ACTIONS } from "../../Constants/Actions";


export const inviteAdminReducer =(state= { }, action)=>{


    /**
     * admins:{
                adminList:{},
                adminByIds:{},
                invitation:{
                                failed:{},
                                success: {}
                            }
            }
     */
    switch(action.type){

        case ACTIONS.ADMIN_INVITE.INVITE_FAILED: {

            let newState = { ...{}, ...state }

            let { result, error } = action.payload;

            newState.invitation.success = result; 
            newState.invitation.failed = error; 

            return newState;
        }
        case ACTIONS.ADMIN_INVITE.INVITE_FAILED: {

            let newState = { ...{}, ...state }
            
            let { result, error } = action.payload;
    

            newState.invitation.success = result; 
            newState.invitation.failed = error; 

            return newState;
        }

        default: {
            
            return state;
        }
    }
}