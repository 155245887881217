import React, { Component } from 'react';
import moment from 'moment'

export const GuestList = ({ toggleEditPermit, item, index }) =>{
    console.log(item.reservedAt);
//    let joining = new Date(item.timeCreated).
//    let joining = new Date(item.timeCreated.replace(/\s/, 'T'))
    let joining = new Date(item.reservedAt*1000);


   let d = joining.getDate();
   let m = joining.getMonth()+1;
   let y = joining.getFullYear();
    //    console.log(joining.toDate())
  
   let expiredAt = formatDate(item.expiredAt)

    return (
        <tr className='solid'>
            <td><i className="fa fa-edit" onClick={()=>toggleEditPermit({item})}></i></td>
            <td>{item.name}</td>
            <td>{item.phone?item.phone:'N/A'}</td>
            <td>{item.email}</td>
            <td>{item.company}</td>
            <td>{ (+item.reservedType === 1)? "Hourly" : (+item.reservedType === 2)? "Daily" : (+item.reservedType === 3) ? "Monthly" : null }</td>
            <td>{ m+"/"+d+"/"+y }</td>
            <td> { expiredAt }</td>
            <td>{ item.licenseNumber }</td>
            <td>{ item.make }</td>
            <td>{ item.model }</td>
            <td>{ item.reservedVal }</td>
            <td style={{color: +item.isSuspended === 1 ? 'red' : 'green'}} > {(+item.isSuspended === 1)? "Suspended" : 'Active'} </td>
        </tr>
    )
}

export function formatDate(item){

    let date =  new Date(+item*1000)
     let  day = date.getDate()
     let month = date.getMonth()+1
     let year = date.getFullYear()
      date = month+"/"+day+"/"+year
  
      return date;
  }