import React, { Component } from 'react';



export const ResidentList = ({ toggleEditPermit, item, index })=>{
    
    let expiredAt = null
    if(+item.expiredAt){
 
      expiredAt = formatDate(item.expiredAt)
 
    }
 
     return (
         <tr className='solid'>
             <td><i className="fa fa-edit" onClick={()=>toggleEditPermit({item})}></i></td>
             <td>{item.name}</td>
             <td>{item.phone?item.phone:'N/A'}</td>
             <td>{item.email}</td>
            {/* { (+item.reservedType === 3) ? <td> {item.} </td> } */}
            {  <td>{item.company}</td> }
            
            
            <td> { expiredAt }</td>  
             <td>{ item.licenseNumber }</td>
             <td>{ item.make }</td>
             <td>{ item.model }</td>
             <td style={{color: +item.isSuspended === 1 ? 'red' : 'green'}} > {(+item.isSuspended === 1)? "Suspended" : 'Active'} </td>
         </tr>
     )
 }
 
 export function formatDate(item){
 
     let date =  new Date(+item*1000)
      let  day = date.getDate()
      let month = date.getMonth()+1
      let year = date.getFullYear()
       date = month+"/"+day+"/"+year
   
       return date;
   }