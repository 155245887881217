import _ from 'lodash';
import React, { Component } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { Formik, getIn, FieldArray, Form, Field } from 'formik'
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import _callApi from '../../../Services/baseService';
import { END_POINT } from '../../../Constants/ApiEndPoints';
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';
import axios from 'axios'
import * as moment from 'moment';
import * as SHA1 from 'sha1';
import GoogleMap from './GoogleMap';
// import { connect } from 'react-redux';
// import { ACTIONS } from '../../../Constants/Actions';
import { isArray } from 'util';
import { withAlert } from 'react-alert';
import LocationSearchInput from './google';
// import { APP_CONST } from '../../../Constants/AppConstants';
import images from "../../../assets/images/images.png";
import black_minus from "../../../assets/images/minus.png";
import black_plus from "../../../assets/images/black-plus.png";
import { VALIDATE } from '../../../Constants/ValidationConst';



class EditLot extends Component {
    constructor(props) {
        super(props);
        this.file = React.createRef()
        this.progressBar = React.createRef()

        this.state = {
            uploadedImage: {
                selectedImage: "",
                progress: null,
            },
            position: {
                lat: null,
                lng: null,

            },
            posError: "",
            currentParkLot: {},
            update: 0,
            count: 0,

            deleteSurgeEvent: {
                success: "",
                error: ""
            },
            deletePromo: {
                success: "",
                error: ""
            },
            date: {
                now: new moment(),
                expiredAt: new moment(),
                surgeFrom: new moment(),
                surgeTo: new moment(),
            },
            editError:"",
            

            disableSave:false,

// ================FOR INPUT ADDRESS FIELD=========//
            clickedInAddressField: false
// ======================END=======================//

        }
    }

    componentWillReceiveProps(props) {}


    componentDidMount() {

        let parkLot = localStorage.getItem("currentParkLot")

        parkLot = JSON.parse(parkLot)

        let currentParkLot = this.state.currentParkLot
        let uploadedImage = this.state.uploadedImage
        let position = this.state.position

        // ====================SETTING DATE IF IT HAS=======================//


        
            if(_.has(parkLot, "dailyRate")){
                parkLot.dailyRate  =  (+parkLot.dailyRate).toFixed(2)
            } 
            if( _.has(parkLot, "monthlyRate")){
                parkLot.monthlyRate = (+parkLot.monthlyRate).toFixed(2)

            }     
            if(_.has(parkLot, "hourlyRate") ){
                parkLot.hourlyRate = (+parkLot.hourlyRate).toFixed(2)

            }

            if( isArray(parkLot.surgeEvents)){

                parkLot.surgeEvents =  parkLot.surgeEvents.map((item, index)=>{

                        item.fromDate = new Date(+item.fromDate*1000)
                        item.toDate = new Date(+item.toDate*1000)

                        item.hourlyPrice = (+item.hourlyPrice).toFixed(2)
                        item.dailyPrice = (+item.dailyPrice).toFixed(2)

                        return item;
                })

            }

                            // promotions: populate.promotions,
            if(isArray(parkLot.promotions)){

                parkLot.promotions =  parkLot.promotions.map((item, index)=>{

                    item.expiredAt = new Date(+item.expiredAt*1000)
                    // item.toDate = new Date(+item.toDate*1000)

                    return item;
                })
            }
    
        this.setState((preState, props) => {
            currentParkLot = parkLot
            uploadedImage.selectedImage = parkLot.imgUrl
            // because both are in string form
            position.lat = +parkLot.latitude; 
            position.lng = +parkLot.longitude;

            return {
                currentParkLot,
                uploadedImage,
                position
            }
        })

    }


    deleteSurgeEvent = ({ id, name }) => {

        let form = new FormData()

        form.append("params[surgeEventID]", id)
        form.append("params[authToken]", this.getToken())
        _callApi(form, END_POINT.REMOVE_SURGE_EVENT.END_POINT, END_POINT.REMOVE_SURGE_EVENT.POST, {}).then(res => {

            let { result = null, error = null } = res.data.payload

            let deleteSurge = this.state.deleteSurgeEvent
            let currentParkLot = this.state.currentParkLot

            if (!error) {


                let parkLot = localStorage.getItem("currentParkLot")
                // this.props.getParkLotById(id)

                parkLot = JSON.parse(parkLot)

                if (parkLot["surgeEvents"]) {

                    parkLot.surgeEvents = parkLot.surgeEvents.filter((item, index) => item.surgeEventID !== id)

                }


                // re-stringify currentParkLot
                parkLot = JSON.stringify(parkLot)

                localStorage.setItem("currentParkLot", parkLot)


                deleteSurge.success = `Deleted ${+name + 1} surge event`
                this.setState((prevState) => ({ deleteSurgeEvent: deleteSurge }))


            }
            else {
                deleteSurge.error = `Surge event ${+name + 1} does not exists`


                this.setState({ deleteSurgeEvent: deleteSurge })

                // console.log("an error was occured")
            }
        })

    }

    deletePromo = ({ id, name }) => {

        let form = new FormData()

        form.append("params[promotionID]", id)
        form.append("params[authToken]", this.getToken())


        _callApi(form, END_POINT.REMOVE_PROMOTION.END_POINT, END_POINT.REMOVE_PROMOTION.POST, {}).then(res => {

            let { result = null, error = null } = res.data.payload

            let deletePromo = this.state.deletePromo
            let currentParkLot = this.state.currentParkLot //  GETTING deletePromo state from current state whether to update it or not

            if (!error) {


                let parkLot = localStorage.getItem("currentParkLot")

                parkLot = JSON.parse(parkLot)

                if (parkLot["promotions"]) {// checking if promotions key is preset or not 

                    parkLot.promotions = parkLot.promotions.filter((item, index) => item.promotionID !== id)

                }

                deletePromo.success = `Deleted ${+name + 1} promotion`

                parkLot = JSON.stringify(parkLot)

                localStorage.setItem("currentParkLot", parkLot)
                this.setState({ deletePromo: deletePromo })


                // console.log("deleted")

            }
            else {
                deletePromo.error = `Promotion ${+name + 1} does not exists`

                this.setState({ deletePromo: deletePromo })

                // console.log("an error was occured")
               
            }
        })

    }


    handleSubmit = (values, formikBeg) => {


        
        let { company,
            address,
            dailyRate,
            hourlyRate,
            locationId,
            hasMonthly,
            monthlyRate,
            contactName,
            phone,
            lotCapacity,
            contactEmail,
            surgeEvents,
            promotions,
            newSurgeEvents,
            newPromotions } = values


        if (isArray(surgeEvents)) {



        }

        this.setState({editError:""})


        surgeEvents = this.formatEvents(surgeEvents)
        newSurgeEvents = this.formatEvents(newSurgeEvents)
        promotions = this.formatPromo(promotions)
        newPromotions = this.formatPromo(newPromotions)


        let currentPageNo = localStorage.getItem("page_no")

        surgeEvents = JSON.stringify(surgeEvents)
        promotions = JSON.stringify(promotions)
        newSurgeEvents = JSON.stringify(newSurgeEvents)
        newPromotions = JSON.stringify(newPromotions)
        
        let form = new FormData()


        let uploadedImage = this.state.uploadedImage

        if (uploadedImage.selectedImage === null || !uploadedImage.selectedImage) {


            // console.log(this.state.uploadedImage.selectedImage)
            uploadedImage.error = "Please select an image"

            this.setState({ uploadedImage: uploadedImage })


        }
        else {
            // console.log(this.state.uploadedImage)


            uploadedImage.error = ""

            this.setState({ uploadedImage: uploadedImage })
        }

        if(this.state.position.lat === null){


            this.setState({ posError: "Please select a location by searching in address field " })

        }else{

            this.setState({ posError: "" })

            this.setState((preState)=>{

                return {
                    disableSave: false
                }
            })

            
        
        }


        if (this.state.position.lat !== null && this.state.uploadedImage.selectedImage  ) {


            this.setState({ posError: "" })

            form.append("params[newSurgeEvents]", newSurgeEvents)
            form.append("params[newPromotions]", newPromotions)
            form.append("params[company]", company)
            form.append("params[address]", address)
            form.append("params[dailyRate]", dailyRate)
            form.append("params[hourlyRate]", hourlyRate)
            form.append("params[ipermitLocationID]", locationId)
            //  form.append("params[hasMonthly]",hasMonthly)
            if (hasMonthly) {
                
                form.append("params[hasMonthly]", 1)
                form.append("params[monthlyRate]", monthlyRate)
            }
            else {

                form.append("params[hasMonthly]", 0)
                form.append("params[monthlyRate]", "")
            }
            // form.append("params[monthlyRate]","")
            form.append("params[contactName]", contactName)
            form.append("params[phone]", phone)
            form.append("params[imgUrl]", this.state.uploadedImage.selectedImage)
            form.append("params[lotCapacity]", lotCapacity)
            form.append("params[contactEmail]", contactEmail)
            form.append("params[surgeEvents]", surgeEvents)
            form.append("params[promotions]", promotions)

            
            form.append("params[latitude]", this.state.position.lat)
            form.append("params[longitude]", this.state.position.lng)
            form.append("params[authToken]", localStorage.getItem("jwtToken"))

            _callApi(form,
                END_POINT.UPDATE_PARKING_LOT.END_POINT,
                END_POINT.UPDATE_PARKING_LOT.POST,
                {}).then(res => {
                     
                    


                    let { result= null, error=null } = res.data.payload

                    if (!error) {

                        this.setState({editError:""})

                        
                        this.props.history.push(`/main/parking-lot-list`)
                    }else{

                        this.setState({editError: error.message })
                        // console.log(res)

                    }
                })
        }
        else {

            // this.setState({ posError: "Please select a location in below map" })

        }
    }

    formatEvents=(events)=>{


        let arr = undefined
        if(events){

          arr =   events.map((item, index)=>{

                 

                        item.fromDate = +item.fromDate

                        item.toDate = +item.toDate

                        return item
                  })
        }

        return arr
    }

    showErrorOnClick=(isShow)=>{

        this.setState({clickedInAddressField:isShow})

    }

    formatPromo=(promo)=>{

        
        let arr = null
        if(promo){

          arr =   promo.map((item, index)=>{

                 

                        item.expiredAt = +item.expiredAt

                        return item

                  })
        }

        return arr
    }

    handleFile = (e) => {

        // console.log(e.target.files[0])

        if(e.target.files.length){
            
            this.setState((preState)=>{
    
                return {
                    disableSave: true
                }
            })
        let form = new FormData()

        let time = new moment().utc(new Date()).valueOf()

        let params = `timestamp=${time}` + `${END_POINT.CLOUDNARY.API_SECRET}`

        let auth = SHA1(params)


        //  console.log(params)

        form.append("file", e.target.files[0])
        form.append("timestamp", time)
        //  form.append("file",this.state.selectedFile)
        form.append("api_key", END_POINT.CLOUDNARY.API_KEY)
        form.append("signature", auth)

        axios.post(
            "https://api.cloudinary.com/v1_1/sylab/image/upload",
            form,
            {
                onUploadProgress: (ProgressEvent => {

                    let progress = Math.round(ProgressEvent.loaded / ProgressEvent.total * 100)

                        this.progressBar.current.style.width = `${progress}%`
                       this.progressBar.current.style.backgroundColor = "#588cdd";
                       this.progressBar.current.style.color = "white";
                       this.progressBar.current.style.paddingLeft = "20px";
                       this.progressBar.current.style.paddingRight = "20px";
                       // this.progressBar.current.style.height = "calc(100% - 1.5em)"
                       this.progressBar.current.innerText = progress+"%..."


                       this.setState((preState)=>{
                            
                        let uploadedImage = preState.uploadedImage

                        if(progress === 100){

                            uploadedImage.progress = null
                            this.progressBar.current.innerText = "...loading"

                            return { uploadedImage }
                        }
                        else {

                            uploadedImage.progress = progress

                            return { uploadedImage }
                        }
                    })
                  
                })
            }
        ).then(res => {
            //   console.log(res)
            this.progressBar.current.style = {}
            this.progressBar.current.innerText = ""
            let { secure_url } = res.data

            let uploadedImage = this.state.uploadedImage

            uploadedImage.selectedImage = secure_url
            uploadedImage.error = "" 

            this.setState(()=>({ uploadedImage,  disableSave: false, }))

        }, error => {
            this.progressBar.current.style = {}
            this.progressBar.current.innerText = ""
            })

        }
    }

    handleDateChange = (setFieldValue, value, fieldName) => {


        setFieldValue(fieldName, value)

        //  this.setState({startDate: e})
    }

    dateFor = (val) => {
        var dateVal = "/Date(" + val + ")/";
        var date = new Date(parseFloat(dateVal.substr(6)));
        const YYYY = date.getFullYear();
        let DD = date.getMonth() + 1;
        let MM = date.getDate();
        let HH = date.getHours();
        let mm = date.getMinutes()
        let ss = date.getSeconds();


        if (DD < 10) {
            DD = `0${DD}`;
        }
        if (MM < 10) {
            MM = `0${MM}`;
        }

        if (HH < 10) {
            HH = `0${HH}`;
        }
        if (mm < 10) {
            mm = `0${mm}`;
        }
        if (ss < 10) {
            ss = `0${ss}`;
        }

        // console.log((YYYY + '-' + DD + '-' + MM))
        return (YYYY + '-' + DD + '-' + MM);
    }

    openCloudnaryWidget = () => {

        window.cloudinary.openUploadWidget({
            cloudName: "sylab", api_key: END_POINT.CLOUDNARY.API_KEY, resourceType: "image", context: { alt: "image is not available", caption: "image" }
        }, (error, result) => {

            //   console.log(result)
        })
    }
    // position:{lat, lng}
    getLatLng = ({ latLng, address }) => {

        let pos = this.state.position

        pos.lat = latLng.lat
        pos.lng = latLng.lng
        pos.address = address
        this.setState({ position: pos })
        // console.log(" Got lat lng ",lat, lng)

    }

    getToken() {

        return localStorage.getItem("jwtToken")
    }
    render() {

        let populate = this.state.currentParkLot


        // console.log(populate)
        return (
            <React.Fragment>
                <div className="row ">
                    <div className="header-panel common-padding-left-right">
                        <div className="add-edit-header">
                            <h1>Edit Parking Lot</h1>
                        </div>
                    </div>
                </div>
               

                <Formik
                    initialValues={

                        {
                            locationId: populate.locationId,
                            imgUrl: (this.state.uploadedImage.selectedImage) ? this.state.uploadedImage.selectedImage : populate.imgUrl,
                            company: populate.company,
                            address: populate.address,
                            dailyRate: populate.dailyRate,
                            hourlyRate: populate.hourlyRate,
                            hasMonthly: (+populate.hasMonthly === 0) ? false : true,
                            monthlyRate: (+populate.hasMonthly === 0) ? 0 : populate.monthlyRate,
                            contactName: populate.contactName,
                            phone: populate.phone,
                            lotCapacity: populate.lotCapacity,
                            contactEmail: populate.contactEmail,
                            surgeEvents: (populate.surgeEvents)? populate.surgeEvents : [] ,
                            promotions: (populate.promotions)? populate.promotions : [],
                            newSurgeEvents: [],
                            newPromotions: []
                        }
                        // this.state.currentParkLot 

                    }
                    // isInitialValid ={true}
                    validateOnBlur={true}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                        //   imgUrl: Yup.string().required("Image is required").url(),

                        company: Yup.string()
                        .required("Company name is required")
                        .max(255, VALIDATE.NAME_MAX)
                        .matches(/^[a-zA-Z0-9_ ]*$/, VALIDATE.NAME_INVALID),

                        address: Yup.string().required("Address is required"),

                        dailyRate: Yup.string().required("Daily rate is required")
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                        //   .positive("Daily rate must be a positive value"),

                        hourlyRate: Yup.string().required("Hourly rate is required")
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                        //   .positive("Hourly rate must be a positive value"),
                        hasMonthly: Yup.boolean(),

                        monthlyRate: Yup.string()
                            .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                        //   .positive("Monthly rate must be a positive value"),

                        contactName:  Yup.string().max(255,VALIDATE.NAME_MAX)
                        .required(VALIDATE.NAME_REQ)
                        .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),

                        phone: Yup.string().required(VALIDATE.PHONE_REQ)
                        .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                        .min(10, VALIDATE.PHONE_MIN)
                        .max(15, VALIDATE.PHONE_MAX),

                        lotCapacity: Yup.string().required("Lot Capacity is required")
                            .matches(/^[1-9]\d*(\d+)?$/, "Lot capacity should be more than one"),

                        contactEmail:  Yup.string()
                        .email(VALIDATE.EMAIL_INVALID)
                        .max(50, VALIDATE.EMAIL_MAX)
                        .required(VALIDATE.EMAIL_REQ),

                        surgeEvents: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({
                                    hourlyPrice: Yup.string().required(VALIDATE.HOURLY_PRICE)
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Hourly price must be a positive value"),

                                    dailyPrice: Yup.string().required(VALIDATE.DAILY_PRICE)
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Daily price must be a positive value"),

                                    fromDate: Yup.date().required("From date is required"),

                                    toDate: Yup.date().required("To date is required")
                                    
                                    
                                })
                            ),
                        promotions: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({

                                    title: Yup.string().required(VALIDATE.TITLE_REQ),

                                    promoCode: Yup.string().required(VALIDATE.PROMO_CODE_REQ),

                                    expiredAt: Yup.date().required(VALIDATE.EXPIRY_DATE_REQ),

                                    promoValue: Yup.string().required("Promo value is required")
                                    .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                })
                            ),
                        newSurgeEvents: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({
                                    hourlyPrice: Yup.string().required(VALIDATE.HOURLY_PRICE)
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Hourly price must be a positive value"),

                                    dailyPrice: Yup.string().required(VALIDATE.DAILY_PRICE)
                                        .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                    // .positive("Daily price must be a positive value"),

                                    fromDate: Yup.date().required("From date is required"),

                                    toDate: Yup.date().required("To date is required")
                                    
                                    
                                })
                            ),
                        newPromotions: Yup.array().notRequired()
                            .of(
                                Yup.object().shape({

                                    title:  Yup.string()
                                    .max(255, VALIDATE.STRING_MAX)
                                    .required(VALIDATE.TITLE_REQ),

                                    promoCode:  Yup.string()
                                    .max(255, VALIDATE.STRING_MAX)
                                    .required(VALIDATE.PROMO_CODE_REQ),

                                    expiredAt: Yup.date().required(VALIDATE.EXPIRY_DATE_REQ),

                                    promoValue: Yup.string().required("Promo value is required")
                                    .matches(/^-?\d*(\.\d+)?$/, "Only numbers are allowed"),
                                })
                            ),
                    })}
                    onSubmit={this.handleSubmit}
                    render={({ handleBlur, handleChange, isValid, validateField, errors, values, setFieldValue, touched }) => (
                        <Form>
                            {/* {JSON.stringify(isValid)} */}
                            <div className="common-container dollor-sign">
                                <div className="rows rows-border-bottom">
                                    <div className="col-img col-img-wrapper">
                                        <img onClick={ (this.state.disableSave === false )? () => this.file.click() : null} src={(this.state.uploadedImage.selectedImage) ? this.state.uploadedImage.selectedImage : images} className="w-100" />
                                        <div ref={this.progressBar} > </div>

                                        {/* <span style={{color:"green"}} >{this.state.uploadedImage.progress }</span> */}
                                        <input style={{ display: "none" }} type="file" onChange={(e) => this.handleFile(e)} ref={node => this.file = node} />

                                        {/* <span className="msg-error">{errors.imgUrl && errors.imgUrl}</span> */}

                                        <span className="msg-error-img">{this.state.uploadedImage.error && this.state.uploadedImage.error}</span>
                                        <div onClick={ (this.state.disableSave === false )? () => this.file.click() : null} id="cloudnary-upload" className="new-photo over-new-photo">
                                            {/* <Image cloudName={END_POINT.CLOUDNARY.CLOUD_NAME}  /> */}
                                            <img src={ black_plus} />
                                        </div>
                                        <div style={{ maxWidth: "600px", width: "100%", margin: "0 auto", position: "relative", maxHeight: "100px" }} className="maps">
                                            <GoogleMap type={2} position={this.state.position} getLatLng={this.getLatLng} />
                                           
                                        </div>
                                    </div>

                                    <div className="col-form">
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Company Name
                                    </label>
                                                    <input
                                                        value={values.company}
                                                        type="text"
                                                        className="form-control"
                                                        name="company"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.company && errors.company && errors.company}</span>
                                                </div>

                                            </div>
                                            <div className="col-8 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Address
                                    </label>
                                                    {/* <input 
                                        value={values.address} 
                                        type="text" 
                                        className="form-control" 
                                        name="address" 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} /> */}
                                                    {/* <span className="msg-error">{errors.address && errors.address}</span> */}
                                                </div>
                                                <LocationSearchInput 
                                                type={2}
                                                location={values.address} 
                                                name="address" 
                                                handleChange={setFieldValue} 
                                                setAddress={this.setAddress} 
                                                getLatLng={this.getLatLng}
                                                showErrorOnClick={this.showErrorOnClick} />

                                                <span className="msg-error">{this.state.posError && this.state.posError}</span>
                                                <span className="msg-error">{ this.state.clickedInAddressField && !values.address  && "please enter correct address" }</span>

                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div class="col-md-12 pt-1 pb-4">
                                                <label class="input-add-new">Stripe Account Email</label>
                                                <input defaultValue="" className="location-search-input form-control" type="text" />
                                            </div>
                                            <div class="col-md-4"></div>


                                        </div> */}
                                        <div className="row">
                                            <div className="col-4 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Contact Name
                                    </label>
                                                    <input
                                                        value={values.contactName}
                                                        type="text"
                                                        className="form-control"
                                                        name="contactName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.contactName && errors.contactName && errors.contactName}</span>
                                                </div>

                                            </div>
                                            <div className="col-3 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Contact Phone
                                    </label>
                                                    <input
                                                        value={values.phone}
                                                        type="text"
                                                        className="form-control"
                                                        name="phone"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.phone && errors.phone && errors.phone}</span>
                                                </div>

                                            </div>
                                            <div className="col-5 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Contact Email
                                    </label>
                                                    <input
                                                        value={values.contactEmail}
                                                        type="text"
                                                        className="form-control"
                                                        name="contactEmail"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.contactEmail && errors.contactEmail && errors.contactEmail}</span>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Lot Capacity
                                    </label>
                                                    <input
                                                        value={values.lotCapacity}
                                                        type="text"
                                                        className="form-control"
                                                        name="lotCapacity"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.lotCapacity && errors.lotCapacity && errors.lotCapacity}</span>
                                                </div>

                                            </div>
                                            <div className="col-3 mb-3">
                                                <div className="form-group">
                                                    <label>Hourly</label>
                                                    <span className="dollor">$</span>
                                                    <input
                                                        value={values.hourlyRate}
                                                        type="text"
                                                        className="form-control dollor-form"
                                                        name="hourlyRate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.hourlyRate && errors.hourlyRate && errors.hourlyRate}</span>
                                                </div>

                                            </div>
                                            <div className="col-3 mb-3">
                                                <div className="form-group">
                                                    <label>
                                                        Daily
                                    </label>
                                    <span className="dollor">$</span>
                                                    <input
                                                        value={values.dailyRate}
                                                        type="text" className="form-control dollor-form"
                                                        name="dailyRate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur} />
                                                    <span className="msg-error">{ touched.dailyRate && errors.dailyRate && errors.dailyRate}</span>
                                                </div>

                                            </div>
                                            <div className="col-3 mb-3">
                                                <div className="form-group col-monthly-checktoggle">
                                                    <label>
                                                        <input name="hasMonthly" className="check-in" type="checkbox" checked={values.hasMonthly} onChange={(e) => { handleChange(e) }} />
                                                        {/* setFieldValue("monthlyRate","") */}
                                                        <div className="slider"> </div>&nbsp; Monthly
                                                    </label>
                                                    <span className="dollor">$</span>
                                                    <input
                                                        value={(!values.hasMonthly) ? "" : values.monthlyRate}
                                                        type="text"
                                                        className="form-control  dollor-form"
                                                        name="monthlyRate"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        disabled={!values.hasMonthly} />
                                                    <span className="msg-error">{ touched.monthlyRate && values.hasMonthly && errors.monthlyRate}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="clearfix"></div>

                                    <FieldArray

                                        name="surgeEvents"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.surgeEvents && values.surgeEvents.length > 0 ?
                                                    values.surgeEvents.map((event, index) => (
                                                        <div className="col-img-wrapper d-flex align-items-center" key={index} >

                                                            <div className="col-img mt-auto">
                                                                <div className="new-photo"  >
                                                                    <img onClick={() => { this.deleteSurgeEvent({ id: values.surgeEvents[index].surgeEventID, name: index }); return arrayHelpers.remove(index); }} src={ black_minus} />
                                                                    <p>ADD SURGE EVENT</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-form">
                                                                <div className="row">
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Hourly
                                                                            </label>  <span className="dollor">$</span>
                                                                            <input
                                                                                value={event.hourlyPrice}
                                                                                onChange={handleChange}
                                                                                name={`surgeEvents.[${index}].hourlyPrice`}
                                                                                type="text"

                                                                                className="form-control dollor-form" />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(errors, `surgeEvents[${index}].hourlyPrice`) && getIn(errors, `surgeEvents[${index}].hourlyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Daily
                                                                            </label>  <span className="dollor">$</span>
                                                                            <input
                                                                                value={event.dailyPrice}
                                                                                onChange={handleChange}
                                                                                name={`surgeEvents.[${index}].dailyPrice`}
                                                                                type="text" className="form-control dollor-form"

                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {getIn(errors, `surgeEvents[${index}].dailyPrice`) && getIn(errors, `surgeEvents[${index}].dailyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                From
                                                                            </label>
                                                                            {/* {JSON.stringify( event.fromDate)} */}
                                                                            <DatePicker
                                                                                //   HERE WE ARE CHECKING `fromDate` WHETHER IT IS INSTANCE OF DATE OR NOT IF NOT THEN WE HAVE TO MULTIPLY UNIX TIMESTAMP SECONDS BY 1000 TO CONVERT IT INTO SECONDS
                                                                                //   BECAUSE BACKEND IS SENDING DATE IN UNIX TIMESTAMP FORMAT FOR FIRST TIME
                                                                                selected={event.fromDate }
                                                                                // value= {  new Date(+event.fromDate * 1000) }
                                                                                onChange={(value) => {

                                                                                        if(+event.fromDate > +event.toDate){
                                                                                        this.handleDateChange(setFieldValue, value, `surgeEvents.[${index}].toDate`)
                                                                                            
                                                                                        }
                                                                                        this.handleDateChange(setFieldValue, value, `surgeEvents.[${index}].fromDate`)
                                                                                    }
                                                                                }
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`surgeEvents.[${index}].fromDate`}
                                                                                className="form-control form-width w-100"
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                                minDate={new Date()}
                                                                                // onChangeRaw={(e)=>{
                                                                                //     console.log(e)
                                                                                // }}
                                                                                // maxDate={values.toDate}
                                                                                // autoComplete={false}
                                                                            // minTime={new Date().getHours()}
                                                                            // maxTime={}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index}.fromDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                To
                                                                            </label>

                                                                            <DatePicker
                                                                                selected={event.toDate}
                                                                                // value= { (event.toDate instanceof Date) ? event.toDate : new Date(+event.toDate * 1000) }
                                                                                onChange={(value) => { this.handleDateChange(setFieldValue, value, `surgeEvents.[${index}].toDate`) }}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`surgeEvents.[${index}].toDate`}
                                                                                className="form-control form-width w-100"
                                                                                minDate={  
                                                                                    +event.fromDate  <= +event.toDate ? new Date( +event.fromDate) : null
                                                                                }
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                                // autoComplete={false}
                                                                                defaultValue={null}
                                                                                // minTime={new Date().getHours()}
                                                                            />

                                                                            {/* {JSON.stringify(+new Date())} */}

                                                                            {/* <input name={`surgeEvents.${index}.toDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>

                                                        </div>
                                                    )) : null
                                                }

                                            </div>
                                        )}
                                    />

                                    <FieldArray

                                        name="newSurgeEvents"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.newSurgeEvents && values.newSurgeEvents.length > 0 ?
                                                    values.newSurgeEvents.map((event, index) => (
                                                        <div className="col-img-wrapper d-flex align-items-center" key={index} >

                                                            <div className="col-img ">
                                                                <div className="new-photo"  >
                                                                    <img onClick={() => {  return arrayHelpers.remove(index); }} src={ black_minus} />
                                                                    <p>ADD SURGE EVENT</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-form">
                                                                <div className="row">
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Hourly
                                                                            </label><span className="dollor">$</span>
                                                                            <input
                                                                                value={event.hourlyPrice}
                                                                                onChange={handleChange}
                                                                                name={`newSurgeEvents.[${index}].hourlyPrice`}
                                                                                type="text"

                                                                                className="form-control  dollor-form" />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                     getIn(touched, `newSurgeEvents[${index}].hourlyPrice`) &&

                                                                                    getIn(errors, `newSurgeEvents[${index}].hourlyPrice`) && getIn(errors, `newSurgeEvents[${index}].hourlyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Daily
                                                                            </label><span className="dollor">$</span>
                                                                            <input
                                                                                value={event.dailyPrice}
                                                                                onChange={handleChange}
                                                                                name={`newSurgeEvents.[${index}].dailyPrice`}
                                                                                type="text" className="form-control  dollor-form"

                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `newSurgeEvents[${index}].dailyPrice`) &&
                                                                                    getIn(errors, `newSurgeEvents[${index}].dailyPrice`) && getIn(errors, `newSurgeEvents[${index}].dailyPrice`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                From
                                                                            </label>
                                                                            {/* {JSON.stringify( event.fromDate instanceof Date)} */}
                                                                            <DatePicker
                                                                                //   HERE WE ARE CHECKING `fromDate` WHETHER IT IS INSTANCE OF DATE OR NOT IF NOT THEN WE HAVE TO MULTIPLY UNIX EPOCH BY 1000 TO CONVERT IT INTO SECONDS
                                                                                //   BECAUSE BACKEND IS SENDING DATE IN UNIX TIMESTAMP FORMAT FOR FIRST TIME
                                                                                selected={event.fromDate }
                                                                                // value= { event.fromDate  }
                                                                                onChange={(value) => {

                                                                                    if(+event.fromDate > +event.toDate){
                                                                                        
                                                                                        this.handleDateChange(setFieldValue, value, `newSurgeEvents.[${index}].toDate`)
                                                                                            
                                                                                    }
                                                                                    this.handleDateChange(setFieldValue, value, `newSurgeEvents.[${index}].fromDate`)
                                                                                }
                                                                                }
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`newSurgeEvents.[${index}].fromDate`}
                                                                                className="form-control form-width w-100"
                                                                                minDate={new Date()}
                                                                                // maxDate={values.toDate}
                                                                                disabledKeyboardNavigation
                                                                                // readOnly={true}
                                                                                onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                                // minTime={new Date().getHours()}
                                                                            // maxTime={}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index}.fromDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                To
                                                                            </label>

                                                                            <DatePicker
                                                                                selected={event.toDate}
                                                                                // value= { event.toDate}
                                                                                onChange={(value) => { this.handleDateChange(setFieldValue, value, `newSurgeEvents.[${index}].toDate`) }}
                                                                                showTimeSelect
                                                                                timeFormat="HH:mm"
                                                                                timeIntervals={1}
                                                                                dateFormat="MM / d / yyyy h:mm aa"
                                                                                timeCaption="time"
                                                                                name={`newSurgeEvents.[${index}].toDate`}
                                                                                className="form-control form-width w-100"
                                                                                minDate={ +event.fromDate  <= +event.toDate ? new Date( +event.fromDate) : new Date( +event.fromDate)  }
                                                                                // readOnly={true}
                                                                                disabledKeyboardNavigation
                                                                                onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                                // minTime={new Date().getHours()}
                                                                            />
                                                                            {/* <input name={`surgeEvents.${index}.toDate`} type="text" className="form-control" /> */}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="clearfix"></div>

                                                        </div>
                                                    )) : null
                                                }
                                                <div className="rows rows-border-bottom">
                                                    <div className="col-img-wrapper rows-space">
                                                        <div className="new-photo" onClick={(errors.newSurgeEvents) ? null : () => arrayHelpers.push({ hourlyPrice: "", dailyPrice: "", fromDate: new Date(), toDate: new Date() })} >
                                                            <img src={ black_plus} />
                                                            <p>ADD SURGE EVENT</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    />
                                </div>

                                <FieldArray

                                    name="promotions"
                                    render={arrayHelpers => (
                                        <div className="rows">
                                            {values.promotions && values.promotions.length > 0 ?
                                                values.promotions.map((promo, index2) => (
                                                    <div className="col-img-wrapper d-flex align-items-center" key={index2} >

                                                        <div className="col-img mt-auto">
                                                            <div className="new-photo" onClick={() => { this.deletePromo({ id: values.promotions[index2].promotionID, name: index2 }); return arrayHelpers.remove(index2) }} >
                                                                <img src={ black_minus} />
                                                                <p>ADD PROMOTION</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-form">
                                                            <div className="row">

                                                            <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Promo Value<span className="dollor">$</span>
                                                                            </label>
                                                                            <input
                                                                                value={promo.promoValue}
                                                                                onChange={handleChange}
                                                                                name={`promotions.[${index2}].promoValue`}
                                                                                type="text" className="form-control field-height"
                                                                                // placeholder="Enter promo code"
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `promotions[${index2}].promoValue`)
                                                                                    && getIn(errors, `promotions[${index2}].promoValue`) && getIn(errors, `promotions[${index2}].promoValue`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                <div className="col-3 mb-3">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Title
                                                                        </label>
                                                                        <input
                                                                            value={promo.title}
                                                                            onChange={handleChange}
                                                                            name={`promotions.[${index2}].title`}
                                                                            type="text"
                                                                            className="form-control field-height" />
                                                                        <span
                                                                            className="msg-error">
                                                                            {getIn(errors, `promotions[${index2}].title`) && getIn(errors, `promotions[${index2}].title`)}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Code
                                                                        </label>
                                                                        <input
                                                                            value={promo.promoCode}
                                                                            onChange={handleChange}
                                                                            name={`promotions.[${index2}].promoCode`}
                                                                            type="text" className="form-control field-height"

                                                                        />
                                                                        <span
                                                                            className="msg-error">
                                                                            {getIn(errors, `promotions[${index2}].promoCode`) && getIn(errors, `promotions[${index2}].promoCode`)}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Expiration
                                                                        </label>

                                                                        <DatePicker
                                                                            
                                                                            selected={promo.expiredAt }
                                                                            // value= { new Date(promo.expiredAt * 1000) }
                                                                            onChange={(value) => { this.handleDateChange(setFieldValue, value, `promotions.[${index2}].expiredAt`) }}
                                                                            showTimeSelect
                                                                            timeFormat="HH:mm"
                                                                            timeIntervals={1}
                                                                            dateFormat="MM / d / yyyy h:mm aa"
                                                                            timeCaption="time"
                                                                            name={`promotions.[${index2}].expiredAt`}
                                                                            className="form-control form-width w-100 field-height"
                                                                            disabledKeyboardNavigation
                                                                            // readOnly={true}
                                                                            minDate = { new Date() }
                                                                            onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                        />
                                                                        
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="clearfix"></div>

                                                    </div>
                                                )) : null
                                            }

                                        </div>
                                    )}
                                />


                                <FieldArray

                                    name="newPromotions"
                                    render={arrayHelpers => (
                                        <div className="rows">
                                            {values.newPromotions && values.newPromotions.length > 0 ?
                                                values.newPromotions.map((promo, index2) => (
                                                    <div className="col-img-wrapper d-flex align-items-center" key={index2} >

                                                        <div className="col-img">
                                                            <div className="new-photo" onClick={() => { return arrayHelpers.remove(index2) }} >
                                                                <img src={black_minus} />
                                                                <p>ADD PROMOTION</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-form">
                                                            <div className="row">
                                                                <div className="col-3 mb-3">
                                                                        <div className="form-group">
                                                                            <label>
                                                                                Promo Value<span className="dollor">$</span>
                                                                            </label>
                                                                            <input
                                                                                value={promo.promoValue}
                                                                                onChange={handleChange}
                                                                                name={`newPromotions.[${index2}].promoValue`}
                                                                                type="text" className="form-control field-height"
                                                                                // placeholder="Enter promo value"
                                                                                onBlur={handleBlur}
                                                                            />
                                                                            <span
                                                                                className="msg-error">
                                                                                {
                                                                                    getIn(touched, `newPromotions[${index2}].promoValue`)
                                                                                    && getIn(errors, `newPromotions[${index2}].promoValue`) && getIn(errors, `newPromotions[${index2}].promoValue`)}
                                                                            </span>
                                                                        </div>

                                                                    </div>
                                                                <div className="col-3 mb-3">

                                                                    <div className="form-group">
                                                                        <label>
                                                                            Title
                                                                        </label>
                                                                        <input
                                                                            value={promo.title}
                                                                            onChange={handleChange}
                                                                            name={`newPromotions.[${index2}].title`}
                                                                            type="text"
                                                                            className="form-control" />
                                                                        <span
                                                                            className="msg-error">
                                                                            {
                                                                                getIn(touched, `newPromotions[${index2}].title`) &&
                                                                                getIn(errors, `newPromotions[${index2}].title`) && getIn(errors, `newPromotions[${index2}].title`)}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Code
                                                                        </label>
                                                                        <input
                                                                            value={promo.promoCode}
                                                                            onChange={handleChange}
                                                                            name={`newPromotions.[${index2}].promoCode`}
                                                                            type="text" className="form-control"
                                                                        />
                                                                        <span
                                                                            className="msg-error">
                                                                            {
                                                                                getIn(touched, `newPromotions[${index2}].promoCode`) &&
                                                                                getIn(errors, `newPromotions[${index2}].promoCode`) && getIn(errors, `newPromotions[${index2}].promoCode`)}
                                                                        </span>
                                                                    </div>

                                                                </div>
                                                                <div className="col-3 mb-3">
                                                                    <div className="form-group">
                                                                        <label>
                                                                            Expiration
                                                                        </label>

                                                                        <DatePicker
                                                                            selected={promo.expiredAt }
                                                                            // value= { (promo.expiredAt instanceof Date) ?  promo.expiredAt : new Date(+promo.expiredAt * 1000) }
                                                                            onChange={(value) => { this.handleDateChange(setFieldValue, value, `newPromotions.[${index2}].expiredAt`) }}
                                                                            showTimeSelect
                                                                            timeFormat="HH:mm"
                                                                            // minTime={(promo.expiredAt instanceof Date )? new Date(+promo.expiredAt) : }
                                                                            timeIntervals={1}
                                                                            dateFormat="MM / d / yyyy h:mm aa"
                                                                            timeCaption="time"
                                                                            name={`newPromotions.[${index2}].expiredAt`}
                                                                            className="form-control form-width w-100"
                                                                            minDate = { new Date() }
                                                                            // readOnly={true}
                                                                            disabledKeyboardNavigation
                                                                            // onClick={(e)=>{console.log(e)}}
                                                                            onKeyDown={(e)=>{e.nativeEvent.preventDefault()}}
                                                                        // minDate={  new Date()  }
                                                                        // minTime={new Date().getHours()}
                                                                        />
                                                                        {/* <input name={`surgeEvents.${index2}.fromDate`} type="text" className="form-control" /> */}
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="clearfix"></div>

                                                    </div>
                                                )) : null
                                            }

                                            <div className="rows">
                                                <div className="col-img-wrapper rows-space">
                                                    <div className="new-photo">
                                                        <img onClick={(errors.newPromotions) ? null : () => arrayHelpers.push({ title: "", promoCode: "", promoValue:"", expiredAt: new Date() })} src={black_plus} />
                                                        <p>ADD PROMOTION</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                />

                                <div className="common-save-btn">
                                    
                                    <button type="submit" class="btn btn-common " onClick={()=>this.showErrorOnClick(true)} >Save</button>

                                </div>

                            </div>
                            {/* {JSON.stringify(values)}{JSON.stringify(isValid)} */}
                        </Form>
                    )}
                />
               
            </React.Fragment>
        )
    }

}

export default EditLot = withAlert(EditLot);