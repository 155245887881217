import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, Redirect,    } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Layout from './View/Layout';
import './assets/css/style.scss';
import AdminLogin from './Component/Login/AdminLogin';
import MasterLogin from './Component/Login/MasterLogin';
import ForgotPassword from './Component/Login/ForgotPassword';
import RegisterAdmin from './Component/Login/RegisterAdmin';
import ResetLotPassword from './Component/Login/ResetLotPassword';
import ResetMasterPassword from './Component/Login/ResetMasterPassword';


class App extends Component {



  componentWillUnmount(){
    localStorage.clear()
  }

  componentWillMount(){
    
  }
  render() {

    const history = createBrowserHistory()
  
  
    return (
      <BrowserRouter basename="/">
        <Switch history={history} >
              
              {/* THIS IS FOR MASTER ADMIN  */}

                  {/* <Route  path="/masterLogin" name="Master Login Page" component={MasterLogin} /> 
                  <Route  path="/admin/recovery/:email/:activationKey"    component={ResetMasterPassword} />  
                   */}
              {/*           END               */}
              
              
                  <Route  path="/forgot-password" name="Forgot password page" component={ForgotPassword} />
              
              {/* ========THIS IS FOR LOT ADMIN========= */}
              
                  <Route  path="/adminLogin" name="admin Login Page" component={AdminLogin} />
                  <Route  path="/lot/recovery/:email/:activationKey"    component={ResetLotPassword} /> 
                  {/* THIS IS FOR LOT ADMIN RESET PASSWORD LINK */}
                  <Route  path="/lot/register/:email/:activationKey"    component={RegisterAdmin} /> 
                  {/*THIS IS FOR NEW LOT ADMIN REGISTRATION LINK*/}

                {/* ===========END============= */}

              <Route  path="/main" component={Layout} />
              <Route  path="/page-not-found" render={()=>(<h1> 404 URL not found</h1>)}  />
              <Route  exact={true} path="/"    render={({location})=>{
             
               return < Redirect to="/adminLogin" from="/"/>
        
          }} /> 
        
          <Route  path="*" render={()=>(<h1>404 URL not found</h1>)}  />
          {/* <Route  render={({location})=>}/> */}
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
