import { ACTIONS } from "../../Constants/Actions";



export const lotAdminListReducer = ( state={}, action ={} )=>{

    /**
     * { 
            adminError:{},
            adminArray:[],
            adminTotal:null
        },
     */
    switch(action.type){

        case ACTIONS.LOT_ADMIN_LIST.ADMIN_LIST_SUCCESS :{

            let { result, error } = action.payload;


            // console.log(list)

            let newState = {...{}, ...state};

            newState.adminArray = result.data;
            newState.adminTotal = result.total;
            newState.adminError = "";

          return newState;
        }

        case ACTIONS.LOT_ADMIN_LIST.ADMIN_LIST_FAILED: {

            let  { result ,error } = action.payload

            let newState = {...{}, ...state};

            newState.adminArray = [];
            newState.adminTotal = 0;
            newState.adminError = error.message;

            return newState
        }
       
        default : {

            return state;
        }
    }

}