import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import React from 'react';
import { VALIDATE } from '../../../Constants/ValidationConst';


export const EditDriverModal = ({ handleEditDriver, editDriverPopup, toggleEditDriverPopup, editDriverMessage, className, driver }) => {

    return (
        <>
            <Modal centered={true} style={{ maxWidth: "600px" }} isOpen={editDriverPopup} toggle={toggleEditDriverPopup} className={className}>
                <ModalHeader >Edit Driver</ModalHeader>
                <ModalBody>

                    <Formik
                        key="newPermit"
                        initialValues={{
                            name: driver.name,
                            email: driver.email,
                            phone: driver.phone,
                            userID: driver.userID
                        }}

                        enableReinitialize={true}

                        validationSchema={Yup.object().shape({


                            phone: Yup.string().required(VALIDATE.PHONE_REQ)
                                .matches(/^\d+$/, VALIDATE.PHONE_INVALID)
                                .min(10, VALIDATE.PHONE_MIN)
                                .max(15, VALIDATE.PHONE_MAX),

                            email: Yup.string().max(50, VALIDATE.EMAIL_MAX)
                            .email(VALIDATE.EMAIL_INVALID)
                            .required(VALIDATE.EMAIL_REQ),

                            name: Yup.string().max(255,VALIDATE.NAME_MAX)
                            .required(VALIDATE.NAME_REQ)
                            .matches(/^[a-zA-Z ]{2,30}$/, VALIDATE.NAME_INVALID),
                        })}

                        validate={({ password, confirmPass }) => {

                            let errors = {}

                            if (password !== confirmPass) {
                                errors.confirmPass = "Password doesn't match"
                            }
                            return errors
                        }}
                        onSubmit={handleEditDriver}
                        render={({ values, handleChange, handleBlur, errors, setFieldValue, touched }) =>
                            (<Form>

                                <div className="popup-form">
                                <div className="row mb-2 ">
                                <div className=" col-md-12 text-center">
                                        <span style={{ fontSize: "12px", color: "red" }} >{editDriverMessage.error && editDriverMessage.error}</span>
                                        <span style={{ fontSize: "12px", color: "green" }} >{editDriverMessage.success && editDriverMessage.success}</span>
                                        </div>
                                </div>


                                    <div className="row mb-2">
                                        <div className="col-md-2 text-right">
                                            <label>
                                                Name
                                                    </label>
                                        </div>
                                        <div className="col-md-10" >
                                            <input type="text" name="name" value={values.name} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.name && errors.name && errors.name}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-2 text-right">
                                            <label>
                                                Phone
                                                </label>
                                        </div>
                                        <div className="col-md-10" >
                                            <input type="text" name="phone" value={values.phone} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.phone && errors.phone && errors.phone}</span>
                                        </div>
                                    </div>

                                    <div className="row mb-2">

                                        <div className="col-md-2 text-right">
                                            <label>
                                                Email
                                                </label>
                                        </div>
                                        <div className="col-md-10" >
                                            <input type="text" name="email" value={values.email} onBlur={handleBlur} onChange={handleChange} className="form-control form-width" />
                                            <span style={{ fontSize: "12px", color: "red" }} >{touched.email && errors.email && errors.email}</span>
                                        </div>
                                    </div>


                                    <input name="userID" defaultValue={values.userID} type="hidden" />

                                    <div className="row mt-3 mb-3">

                                        <div className="col-sm-4 offset-sm-4 col-12">
                                            <div className="row align-items-center justify-content-center">
                                                <button type="submit" className="btn btn-common save-btn">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                    {/* {JSON.stringify(values)} */}

                                </div>
                            </Form>)}
                    />
                </ModalBody>

            </Modal>
        </>
    )
}