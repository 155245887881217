import React from 'react';
// import { APP_CONST } from '../../../Constants/AppConstants';
import minus  from '../../../assets/images/minus.png'
// import { Link } from 'react-router-dom'

export const ListRow = ({toggleEditDriverPopup, row, toggleDeleteConfirmModal, toggleChatModal})=>{

        let user = JSON.stringify(row)
        let userType = localStorage.getItem("userType");

    return (
            <tr className='solid'>
                    {  <td>   <i className="fa fa-edit" onClick={()=>toggleEditDriverPopup(row)}></i> </td>  }
                    <td>{row.name}</td>
                    <td>{row.phone}</td>
                    <td>{row.email}</td>
                    {/* <td>{row.licenceNumber}</td> */}
                    <td onClick={()=>{ localStorage.setItem("selected_chat_user", user); toggleChatModal(user)  }} > <a href="javascript:void(0)" >Send Message </a>    </td>
                    {<td><img onClick={()=>toggleDeleteConfirmModal(row)} src={ minus} className="cursor" /></td>}

            </tr>
    )
}