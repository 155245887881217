import React from 'react';


function Schedule({ head, index, item }) {


//     company: "fdsf"
// dailyPrice: "24242.00000000"
// fromDate: "2018"
// hourlyPrice: "21313131.00000000"
// locationId: "16"
// toDate: "2018"

    let hourly;
    let daily ;
    let monthly ;
    let fromDate = null;
    let toDate =null

     if(item.fromDate){
         
        fromDate = formatDate(item.fromDate)
     }
     else{
        fromDate = null
     }


     if(item.toDate ){
         
        toDate = formatDate(item.toDate)
     }
     else{
        toDate = null
     }



    if(+item.hourlyPrice){}
      hourly = <p>$ {new String(+item.hourlyPrice)}/hour</p>
    
    if(+item.dailyPrice) 
      daily = <p>$ { new String(+item.dailyPrice)}/day</p>

    if(+item.monthlyPrice) 
      monthly = <p>$ { new String(+item.monthlyPrice)}/month</p>

    return (
        <React.Fragment>
            <ul>
                <li>
                    <div className="list-innew">
                        <p><strong> {fromDate}-{toDate} {item.company}</strong></p>
                        {(item.dailyPrice)? daily : null}
                        {(item.hourlyPrice)? hourly : null}
                        {(item.monthlyPrice)? monthly : null}
                    </div>
                </li>
            </ul>
        </React.Fragment>
    )
}


export function formatDate(item){

         let date =  new Date(+item*1000)
          let  day = date.getDate()
          let month = date.getMonth()+1
          let year = date.getFullYear()
           date = month+"/"+day+"/"+year

           return date;
}

export default Schedule;