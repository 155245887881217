import { ACTIONS } from "../../Constants/Actions";


export const dashboardReducer= ( state={}, action={} )=>{

    switch(action.type){

        case ACTIONS.DASHBOARD_REPORT.DASHBOARD_REPORT_SUCCESS:{

            let newState = {...{}, ...state}

            newState.success = action.payload.result;
            newState.error = action.payload.error;

            return newState;
        }
        case ACTIONS.DASHBOARD_REPORT.DASHBOARD_REPORT_FETCHING:{

        }
        case ACTIONS.DASHBOARD_REPORT.DASHBOARD_REPORT_FAILED:{

        }

        default:{
            return state;
        }
    }


}