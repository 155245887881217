import { parkingLotsReducer } from "./parkingLotsReducer";
import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import { forgotPassword } from "./forgotPassword";
import { inviteAdminReducer } from "./inviteAdminReducer";
import { dashboardReducer } from "./dashboardReducer";
import { lotAdminListReducer } from "./lotAdminListReducer";
import { latestResPermitReducer } from "./latestResPermitReducer";
import { permitListReducer } from "./permitListReducer";
import { driverListReducer } from "./driverListReducer";
import { chatReducer } from "./chatReducer";
import refundReducer from "./refundReducer";


export const reducers = combineReducers({
                                            parkList:               parkingLotsReducer,
                                            login :                 LoginReducer,
                                            forgotPassword :        forgotPassword,
                                            adminInvitation  :      inviteAdminReducer,
                                            dashboardReport:        dashboardReducer,
                                            adminList:              lotAdminListReducer,
                                            latestReservedPermits:  latestResPermitReducer,
                                            permitsList:            permitListReducer,
                                            driverList:             driverListReducer,
                                            chat:                   chatReducer,
                                            refund:                 refundReducer
                                        })
