import _callApi from "../../Services/baseService";
import { END_POINT } from "../../Constants/ApiEndPoints";
import { DriverListAction } from "../actions/DriverListAction";


export const DriverListApi = ({form})=>{

    return (dispatch,getState)=>{

          _callApi(form,
            END_POINT.DRIVER_LIST.END_POINT,
            END_POINT.DRIVER_LIST.POST).then(res=>{

                let { result=null, error=null } = res.data.payload

                if(!error){

                    dispatch(DriverListAction({result,error}))
                }
                else{

                    dispatch(DriverListAction({result,error}))

                }
            })
    }
}