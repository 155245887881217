import React from 'react'
import { Modal,ModalBody} from 'reactstrap';

export const StripeModal = ({stripeAccountMessage, toggleStripeModal,stripeModal, accountNumberMethod, emailNumber, handleChange, accountNumber, email, routingNumber, emailError, accountNumberError, routingNumberError, bankNumberError, bankNumber  }) => {
   
//    console.log(emailError)
   
   return (
    <Modal style={{ maxWidth: "395px" }} centered={true} isOpen={stripeModal} toggle={toggleStripeModal} >
<ModalBody>
  
   <div class="">
                {/* <label class="input-add-new">Stripe Account Number</label>
                <input defaultValue="" name="accountNumber" value={accountNumber} onChange={(e) => handleChange(e)} className="location-search-input form-control" type="text" />
                <span  style={{ fontSize: "12px", color: "red" }} >{accountNumberError }</span> 
                <br />
                <button type="submit" class="btn" onClick={() => accountNumberMethod()} >Save</button>
                <br />or<br /> */}
           <h3 class="text-center"> Connect to Stripe </h3>
                <span style={{ fontSize: "12px", color: stripeAccountMessage.status ? "green" : "red"  }} >{stripeAccountMessage.message}</span><br/>
                <label class="input-add-new">Stripe email</label>
                <input defaultValue="" name="email" value={email} minLength={1} maxLength={50} onChange={(e) => handleChange(e)} className="location-search-input form-control" type="email" />
                 <span  style={{ fontSize: "12px", color: "red" }} >{emailError }</span> 
                <br />
                <label class="input-add-new">Routing Number</label>
                <input defaultValue="" name="routingNumber"  min={1} value={routingNumber}minLength={1} maxLength={20} onChange={(e) => {   handleChange(e)}} className="location-search-input form-control" type="text" />
                 <span  style={{ fontSize: "12px", color: "red" }} >{routingNumberError }</span> 
                <br />
                <label class="input-add-new">Bank Account</label>
                <input defaultValue="" name="bankNumber" min={1} value={bankNumber}minLength={1} maxLength={20} onChange={(e) => handleChange(e)} className="location-search-input form-control" type="text" />
                <span  style={{ fontSize: "12px", color: "red" }} >{bankNumberError }</span>

                <br />
               
            </div>
            <div className="row">
            <div className="col-lg-12 text-center">
                <button type="button" class="btn btn-dash-pop" onClick={() => toggleStripeModal()} >Cancel</button>&nbsp;&nbsp;
                <button type="submit" class="btn btn-dash-pop1" onClick={() => emailNumber()} >Save</button>

            </div>
            </div>
</ModalBody>

            </Modal>

    )
}
