import React, { Component } from 'react'
import _callApi from '../../Services/baseService';
import { ACTIONS } from '../../Constants/Actions';
import { END_POINT } from '../../Constants/ApiEndPoints';
import {ChatUsersActions} from '../actions/ChatUsersActions';



export const ChatUsersListApi=({form})=>{

    return (dispatch, getState)=>{


        _callApi(
            form,
            END_POINT.CONVERSATIONS.END_POINT,
            END_POINT.CONVERSATIONS.POST).then(res=>{

                let { result=null, error=null } = res.data.payload

                dispatch(ChatUsersActions({result, error}))
            })
    }
}
