import React from 'react';


function Promo({item, head, commonFun }) {

    let date = null

     if(item.expiredAt && item.expiredAt.length ===10){
         
          date = formatDate(item.expiredAt)
     }
     else{
         date = null
     }

    // console.log(date)
    return (
        <React.Fragment>
            <ul>
                <li>
                    <div className="list-innew">
                       
                        <p><strong>{item.title}</strong></p>
                        <p>Expires {date}</p>
                        <p>Use code <strong>{item.promoCode}</strong></p>
                    </div>
                </li>
            </ul>
        </React.Fragment>
    )

}


export function formatDate(item){

    let date =  new Date(+item*1000)
     let  day = date.getDate()
     let month = date.getMonth()+1
     let year = date.getFullYear()
      date = month+"/"+day+"/"+year

      return date;
}
export default Promo;
