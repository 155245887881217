import React, { Component } from 'react';

class ReservedPermits extends Component {
    constructor(props) {
        super(props)

    }

    render() {
        return (
            <div className="reservedPermit-card">
                <div>
                    <h2>
                        {this.props.permitCount}
                    </h2>
                    <label>
                        {this.props.permitDescription}
                    </label>
                </div>
            </div>
        )
    }
}

export default ReservedPermits;