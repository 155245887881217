import { ADMIN_ACTION } from "../actions/AdminLoggedIn";
import { ACTIONS } from "../../Constants/Actions";



export default function LoginReducer(state = {}, action){


    /**
     * details:{
            name:'',
            email:'',
            password:'',
            adminID:'',
            timeCreated:null,
            timeUpdated:null,
            jwtToken:''
        },
        errors:{},
        logging:false 
     */
    switch(action.type){

        case  ACTIONS.ADMIN_ACTION.ADMIN_LOGGING:{

              let newState = {...{}, ...state}

              newState.logging = action.payload.status

              return newState
        }
        case ACTIONS.ADMIN_ACTION.ADMIN_LOGGEDIN : {

            let newState =  {...{}, ...state }

            newState.logging = action.payload.status

            //====================================GETTING RESULT AND ERRORS FROM ACTION ============================//
            let {result, errors} = action.payload.response

            // ===================================TO REMEMBER LOGIN DETAILS=========================================//
            let isRemember = action.payload.remember
            
            // =========================DEFINING USERTYPE OF EITHER 1 OR 2 i.e. AdminLogin OR MASTER LOGIN==========//
            let userType = action.payload.userType;

            newState = { ...newState, ...{errors:errors},...{ details: result, userType: userType  }  }           
            
            return newState
            
        }
        case ACTIONS.ADMIN_ACTION.LOGIN_FAILURE: {

            let newState = { ...{}, ...state }
            let {error, logging} = action.payload

            newState.errors = error;
            newState.logging = logging;

            return newState;

        }
        case ACTIONS.AUTH_DETAILS.UPDATE_AUTH:{

                let newState = {...{},...state}

                let remember = newState.remember
                let userType = newState.userType

                newState = { ...newState,...action.payload, ...{userType,remember} }

                return newState
        }
        default:{
            return state;
        }
    }

}