import React, {  Component } from 'react';
import { SearchBar } from './SearchBar';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';


export default  class DriversFilter extends Component{

    constructor(props){
        super(props)
        this.btnRef = React.createRef()

    }

    handleChange=(setFieldValue, name, value)=>{

        try{

            setFieldValue(name, value);
        }
        catch(error){

            console.log(error)
        }

        this.btnRef.click()
        // console.log(name, value)
    }

    render(){

        let userType = localStorage.getItem("userType");

        return (<>
            <div className="header-panel1 common-padding-left-right">

               <Formik
                        key="newPermit"
                        initialValues={{
                            searchText:''
                        }}
                       
                     onSubmit ={(values, formikBag)=>this.props.applyFilter({values,formikBag})}
                     render={({handleChange, handleBlur, errors, values, touched, setFieldValue})=>(
                         <Form>
                             <div className="row">
                             <div className="col-md-12">
                                <div className="search-box w-100">
                                   <SearchBar 
                                   handleChange={this.handleChange} 
                                   handleBlur={handleBlur}
                                   setFieldValue = {setFieldValue}
                                   type="text" 
                                   touched={touched} 
                                   errors={errors} 
                                   value={values.searchText} 
                                   className="form-control search-field" 
                                   name="searchText" 
                                   errors={errors} />
                                    <i className="fa fa-search search-icon" />
                                </div>
                                </div>
                                
                              <button type="submit"  style={{display:"none"}} ref={(node)=>{  this.btnRef=node;}}  ></button>
                              </div>
                        </Form>
                     )}
                    />
                    <div className="col-md-6">
                        { 
                                <div className="btn-csv float-right">
                                <button className="btn btn-common" onClick={this.props.toggleNewDriverPopup}>
                                                Add New
                                </button>
                                </div>
                        
                    }
                    </div>

            </div>
        </>)
    }
}