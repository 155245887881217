import React from 'react'
import { ACTIONS } from '../../Constants/Actions';
import _callApi from '../../Services/baseService';
import { END_POINT } from '../../Constants/ApiEndPoints';
import { ChatListActions } from '../actions/ChatListActions';

export const ChatListAPI=({form}) => {
  

  return (dispatch, getState)=>{

      _callApi(form, 
        END_POINT.GET_CHAT_HISTORY.END_POINT,
        END_POINT.GET_CHAT_HISTORY.POST).then(res=>{

            let { result=null, error=null } = res.data.payload
            let { isTop } = res.data.payload

            dispatch(ChatListActions({result,error, isTop}))
        })
  }
}
