import { ACTIONS } from "../../Constants/Actions";


export default function AdminLoggedIn({status, remember, userType}, response){

    
    
    console.log("=================.>",response)
    return {
        type: ACTIONS.ADMIN_ACTION.ADMIN_LOGGEDIN,
        payload: { response ,remember, status, userType}
    };
}