import React, { Component } from 'react'
import { GoogleApiWrapper, Map, Marker, InfoWindow } from 'google-maps-react'
import { connect } from 'react-redux'
import { END_POINT } from '../../../Constants/ApiEndPoints';
import markerIcon from '../../../assets/images/marker_icon.png';

function Loader(){
    
    return (
        <p>Loading...</p>
    )
}
  class GoogleMap extends Component{


    constructor(props){
        super(props)
        
        this.state = {
            selectedPlace:{ 
                name:"Noida"
            },
            zoom:15,
            position: {
                lat:40.854885,
                lng:-88.081807
            }
        }
      
        this.marker = React.createRef()

        this.map = React.createRef()
    }

    componentDidMount(){     
        

       if(this.props.type === 1){
           

        
       } 
       else {

        let position = this.state.position

        position.lat = this.props.position.lat
        position.lng = this.props.position.lng
  
        this.setState({position:position})


        setTimeout(()=>{

            if(this.map && this.marker)
            this.centralizeMarker(this.map.map, this.marker.marker)
        })

        // this.map.map.panTo({lat:position.lat, lng: position.lng})
       }
    }

    centralizeMarker=(map, marker)=>{

        if(map, marker){

            map.panTo( marker.getPosition());
            map.setZoom(15);
        }

    }

    componentWillReceiveProps(nextProps){

        let position = this.state.position
  
        position.lat = nextProps.position.lat
        position.lng = nextProps.position.lng
  
        this.setState({position:position})


        setTimeout(()=>{
  

            if(this.map && this.marker){

                this.centralizeMarker(this.map.map, this.marker.marker)
            }
           
  
        })

        // console.log("will receive props ")
      //  this.map.map.setCenter( this.marker.marker.getPosition());
  
  
      }

    onMapClicked=(mapProps, map, clickEvent)=>{
        // console.log(mapProps,map, clickEvent)

    }

    onMapReady=(mapProps, map)=>{

        let position = this.state.position

        navigator.geolocation.getCurrentPosition((success)=>{
            position.lat = success.coords.latitude;
            position.lng = success.coords.longitude;
           

            this.setState({position:position})
        })
        const { google } = mapProps

        const service = new google.maps.places.PlacesService(map);

      
        console.log(map)
            google.maps.event.addListener(this.marker.marker, 'dragend', (res) =>{
                
                let position = this.state.position
               
                    position.lat =    this.marker.marker.position.lat();
                    position.lng =    this.marker.marker.position.lng()
                    
                    console.log( " on ready ", position)
                    
                    this.props.getLatLng({latLng:position, address:""})
                   
            });


           

//============= THIS IS FOR RECENTER THE MARKER THAT HAVE ALREADY INITIALIZED=================//
            map.addListener('recenter', ()=> {
               
               
                  map.panTo( this.marker.marker.getPosition());
                //   map.setZoom(1);

                  console.log(this.marker.marker.getPosition())
               
              });
// =============================END===========================================================//

        

// ========================IF ZOOM LEVEL REACHED AT LIMIT=====================================//
        map.addListener(map, 'zoom_changed', function () {
                if (map.getZoom() < 6) map.setZoom(6);map.checkResize()
            });
// ======================================END==================================================//
    
            //  this.marker.marker.addListener('click', function() {
            //       map.panTo( this.marker.marker.getPosition());
            //      map.setCenter( this.marker.marker.getPosition());
            //      map.setZoom(20);
            //   });
    }
   

      onMarkerClick=(mapProps, marker)=>{

        let lat = this.marker.marker.position.lat()

        let lng = this.marker.marker.position.lng()
            
        mapProps.map.panTo( marker.getPosition());

        this.props.getLatLng({latLng:{lat, lng}, address:""})

      }
    render(){
        
        

        return <Map 
        
        panControl={true}
        ref={(node)=>this.map = node}
        initialCenter={this.state.position}
        // center={this.state.position}
        centerAroundCurrentLocation={true}
        minZoom={3}
        onReady={this.onMapReady}
        bounds={this.props.google.maps.LatLngBounds()}
        // onBoundsChanged={}
        
        google={this.props.google} 
        // fullscreenControlOptions={{position:{ RIGHT_BOTTOM: this.props.google.maps.ControlPosition.RIGHT_BOTTOM}}}
        style={{width: '100%',margin:"0 auto",overflow:'hidden', height: 'inherit', position: 'relative'}}  lat={this.state.position.lat} lng={this.state.position.lng}>
 
                    <Marker  ref={node=>this.marker=node} 
                            onClick={this.onMarkerClick}
                            name={this.state.selectedPlace.name}
                            initialCenter={{lat:this.state.position.lat, lng : this.state.position.lng}}
                            
                            draggable={true}
                            mapCenter={this.state.position}
                            visible={true}
                            position={{lat:this.state.position.lat, lng : this.state.position.lng}}
                            label={{text:"P", color:"white" }}
                            icon={ 
                                // {url: markerIcon, size:this.props.google.maps.Size(5,5)}
                                {
                                    url: markerIcon
                        //    http://www.ssglobalsupply.com/images/location-contact.png
                           ,
                                    // This marker is 50 pixels wide by 50 pixels high.
                                    size: this.props.google.maps.Size(50, 50),
                                    // The origin for this image is (0, 0).
                                    origin: this.props.google.maps.Point(0, 0),
                                    // The anchor for this image is the base of the flagpole at (0, 32).
                                    anchor: this.props.google.maps.Point(0, 32),
                                    scaledSize: this.props.google.maps.Size(50, 50)
                                  }
                            }
                            
                            // clickable={true}
                            // onMouseover={()=>this.onMarkerClick}
                            
                             />
            
                    <InfoWindow   onClose={this.onInfoWindowClose} visible={true}>
                        <div>
                        <h1>{this.state.selectedPlace.name}</h1>
                        </div>
                    </InfoWindow>
            </Map>
    }
}

export default GoogleMap = GoogleApiWrapper((props)=>({apiKey:END_POINT.GOOGLE_MAP.API_KEY,language:props.language,LoadingContainer:Loader}))(GoogleMap)