import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';


import { Dashboard, Messaging, Permits } from '../Component';
// import AddnewLot from '../Component/ParkingLots/parkingLotsComponents/AddnewLot';
import ParkingLotList from '../Component/ParkingLots/parkingLotsComponents/ParkingLotList';
import AddnewLot from '../Component/ParkingLots/parkingLotsComponents/AddnewLot';
import EditLot from '../Component/ParkingLots/parkingLotsComponents/EditLot';
import Admins from '../Component/Admins/Admins';
import Drivers from '../Component/Drivers/Drivers';
import RefundComponent from '../Component/Refund/RefundComponent';
// import Residents from '../Component/Permits-old/components/Residents';

class MainContainer extends Component {
    constructor(props) {
        super(props)

        // console.log(this.props)
    }

    render() {
        return (
            <React.Fragment>
                <div className="conatiner-layout">
                    <Switch>
                        {/* <Route exact path="/login" name="Login Page" component={Login} /> */}
                        <Route exact path={`${this.props.match.path}`} name="Dashboard" component={Dashboard} />
                        {/* <Route exact path="/dashboard" name="Dashboard" component={Dashboard} /> */}
                        <Route exact path={`${this.props.match.path}/messaging`} name="Messaging" component={Messaging} />
                        <Route exact path={`${this.props.match.path}/parking-lot-list`} name="Parking Lots" component={ParkingLotList} />
                        <Route path={`${this.props.match.path}/parking-lot-list/add-new`} name="Parking Lots" component={AddnewLot} />
                        <Route path={`${this.props.match.path}/parking-lot-list/edit-parking-lot`} name="Parking Lots" component={EditLot} />
                        <Route path={`${this.props.match.path}/permits`} name="Permits" component={Permits} />
                        <Route path={`${this.props.match.path}/admins`} name="Admins" component={Admins} />
                        <Route path={`${this.props.match.path}/drivers`} name="Drivers" component={Drivers} />
                        <Route path={`${this.props.match.path}/refund-list`} name="Refund List" component={RefundComponent} />
                        {/* <Redirect to="/parking-lot" from="/parking-lot/parking-lot-list" /> */}
                    </Switch>
                </div>
            </React.Fragment>
        )
    }
}

export default MainContainer;