import { ACTIONS } from "../../Constants/Actions";


export const LatestReservedPermit = ({ result, error } )=>{

    return {
        type: (!error)? ACTIONS.LATEST_PERMIT_LIST.LATEST_PERMIT_LIST_SUCCESS : ACTIONS.LATEST_PERMIT_LIST.LATEST_PERMIT_LIST_FAILED ,
        payload: { result, error }
    }

}