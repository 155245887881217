import { ACTIONS } from "../../Constants/Actions";

/**
 *  {
            users:{
                listByIds: {},
                error:null,
            },
        }
 */

export const chatReducer = (state = {}, action={}) => {
  switch (action.type) {

  case ACTIONS.CHAT.USERS_LIST.USERS_LIST_SUCCESS:{

    let { result, error } = action.payload
    let newState = { ...{}, ...state }
    let temp = {}
    // console.log(result)


    result.map((item)=>{

         temp[item.conversationID] = item
    })
    
    newState.users.listByIds = temp
    newState.users.error = error
    
    return newState
  }

  case ACTIONS.CHAT.GET_CHAT_LIST.CHAT_LIST_SUCCESS:{
    
          let  { result , error, isTop } = action.payload
          let newState = { ...{}, ...state }
          let temp = {}


        //  console.log(" CHAT REDUCER ",result instanceof   Array)
        if(result){

          result.map((item)=>{

            temp[item.messageID] = item
        })
      } 

        //  console.log("CHAT LIST REDUCER ", isTop)
          newState.chatList.listByIds = temp
          newState.chatList.error = error

          newState.chatList.isTop = isTop

          return {...newState}
  }

  case ACTIONS.CHAT.GET_CHAT_LIST.CHAT_LIST_FAILED:{
    
    let  { result , error, isTop } = action.payload

    // console.log(result, error)



    let newState = { ...{}, ...state }

    newState.chatList.listByIds = result
    newState.chatList.error = error

    newState.chatList.isTop = isTop

    return {...newState}
}

  default:
    return state
  }
}
