import React from 'react';
import minus  from '../../../assets/images/minus.png'
import _moment from 'moment';


export const ListRow = ({toggleEditRefundPopup,togglePassReqModal, row, toggleRequestConfirmModal})=>{


       let user = JSON.parse( localStorage.getItem("adminDetail"));

    //    console.log(user && +user.userType)
        // {name: "Lot Admin", email: "lotadmin@yopmail.com"}
    return (
            <tr className='solid'>
                    {/* <td><i className="fa fa-edit" onClick={()=>toggleEditRefundPopup(row)}></i></td> */}
                    <td>{row.name}</td>
                    {/* <td>Empire State Apartments</td> */}
                    <td>{(row.phone)? row.phone : "N/A"  }</td>
                    <td>{row.email}</td>
                    <td>{row.company}</td>
                    <td>{row.address}</td>
                    <td>{ formatDate(row)}</td>
                        {/* <td>#</td> */}
                    <td>  { (+row.status === 0)? "Pending" : (+row.status === 1) ? "Approved" : (+row.status===2)? "Rejected" :null }</td>
                    <td>
                            <button className="btn btn-common inb-el mr-2" disabled={ (+row.status === 1 || +row.status === 2 ) }  onClick={ user && +user.userType === 3 &&  +row.status === 0 ? ()=> toggleRequestConfirmModal(row,1)  : ()=>togglePassReqModal(row, 1)}    > Approve </button>  
                           <button className="btn btn-common inb-el" disabled={ (+row.status === 1 || +row.status === 2 ) }   onClick={()=>toggleRequestConfirmModal(row , 2)}    > Reject </button>  

                </td>

            </tr>
    )
}

export function formatDate(item){
 
      
          return _moment(item.timeCreated).format('MM/DD/YYYY');
      }