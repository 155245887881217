import { ACTIONS } from "../../Constants/Actions";



export const GetSiglePermit = ( { id } ) =>{


    return {
        type: ACTIONS.PERMIT_LIST.GETS_PERMIT,
        payload: id
    }
}